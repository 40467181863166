.iGor_ProDetHeader {
    position: absolute !important;
    top: 0;
    z-index: 1;
}

.iGor-action_loader {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        color: #ffffff;
        font-size: 1.2rem;
    }
}

.iGor-facilities_default {
    display: flex;
    height: 100%;

    svg {
        font-size: 0.7rem;
        margin-top: 5px;
    }
}

.iGor__ProBackArrow {
    display: none !important;
}

.iGor__ProDetailsCardS {
    margin-top: 80px;
}

.iGor-Cancellation_policies_list {
    border-bottom: 0.0885rem solid #E6E6E6;
    margin: 15px 0 15px 0;
    width: 100%;
    padding: 0 !important;
}

.iGor-Cancellation_policies_room {
    display: flex;
    flex-direction: column !important;
    width: 100%;
}

.iGor-room_policies {
    text-align: justify;

    span {
        font-weight: 600;
    }

    p {
        font-size: 0.65rem;
    }
}


.iGor_Sold {
    font-weight: 600;
    color: #000;
    line-height: 1rem;
    font-size: 0.8rem;
}

.iGor_button_disable_room {
    color: #fff !important;
    background: #979797 !important;
}

.iGor_Recommendations_sold {
    display: flex;
    font-size: 1rem;
    font-weight: 600;
    margin-top: 5px;
    justify-content: center;
    align-items: center;
    letter-spacing: 0.05rem;
    color: #ff4242;
}

.iGor__ProRoomBox {
    width: 420px;
    padding: 20px;

    .iGor_d_roomBtnSectiom {
        text-align: center;
        padding-left: 1rem;
        margin-bottom: 1rem;

        .iGor_alignCenter {
            padding-top: 31px;
        }
    }
}

.iGor_proDetWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;

    .rdrMonthsVertical {
        margin-bottom: 1rem;
    }
}

.iGor_PropertyDetails {
    position: relative;
    // padding-bottom: 3.5rem;
    height: 80vh;
    width: 100%;
    border-radius: 0 0 50px 50px;
    margin-bottom: 80px;

    .iGor_topLocTitel {
        display: flex;
        align-items: center;
        align-content: center;
        font-size: 0.625rem;

        img {
            width: 10px;
            margin: 0 4px 0 10px;
        }
    }

    .iGor_ProDetIframe {
        width: 100%;
        height: 100%;
        border: none !important;
    }

    .iGor_ProDetImage {
        position: relative;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    &:before {
        content: "";
        position: absolute;
        bottom: 0;
        height: 12.8125rem;
        background: -webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba(0, 0, 0, .8)));
        background: linear-gradient(180deg, transparent, rgba(0, 0, 0, .8));
        left: 0;
        width: 100%;
        z-index: 1;
        border-radius: 0 0 50px 50px;
    }



    .iGor_iFrameimg {
        border-radius: 0 0 50px 50px;
        overflow: hidden;
        height: 100%;
        margin-bottom: 1.5625rem;
    }

    .iGor__prodetTop {
        position: relative;
    }

    .iGor_ProDetDescription {
        position: absolute;
        z-index: 1;
        bottom: -50px;
        // left: 50%;
        // -webkit-transform: translateX(-50%);
        // transform: translateX(-50%);
        // max-width: 71.25rem;
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;

        .title-wrapper {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            margin-left: 0.8125rem;

            .subtitle {
                display: flex;

                span {
                    color: #fff;
                    font-size: 0.625rem;
                    line-height: 1.125rem;
                    margin-left: 0.25rem;
                }
            }

            .title {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                margin-bottom: 1.25rem;
                color: #ffffff;
                align-items: baseline;
            }
        }

        .btn-wrapper {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            margin-bottom: 0.3125rem;

            .iGor_wishListDetailsScreen {
                display: flex;
                align-items: center;

            }

            .iGor_btnIcon {
                // width: 0.9375rem;
                width: 15px;
                height: 13px;
                margin-right: 0.3125rem;
                color: #ff6b6b;
            }

            img.iGor_btnIcon {
                width: 12px;
                height: 12px;
            }

            .btn-outline {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                background-color: #fff !important;
                -webkit-transition: background .3s ease;
                transition: background .3s ease;
                border: 0.0625rem solid #c4c4c4 !important;
                border-radius: 3.125rem !important;
                text-align: center;
                font-size: 0.625rem;
                color: #000;
                margin-left: 0.625rem;
                text-transform: capitalize;
                height: 40px;

                .img-before {
                    display: flex;
                }
            }
        }
    }

    .box-review,
    .box-review .value {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        flex-direction: column;
    }

    .box-review {
        .text-value {
            border-bottom: 0.0625rem solid #000;

            span {
                font-size: 0.625rem;
                line-height: 1.125rem;
                font-weight: 600;
                color: #000;
            }
        }

        .value {
            background-color: #FF6B6B;
            border-radius: 0.9375rem;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            width: 4.8125rem;
            height: 4.8125rem;
            // margin-bottom: 0.3125rem;

            span {
                font-size: 1.875rem;
                font-weight: 600;
                margin-bottom: 0;
                line-height: 1.875rem;
                color: #fff;
            }
        }
    }

    .description .title-wrapper {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        margin-left: 0.8125rem;
    }
}

.iGor_ProDList {
    padding-top: 0px !important;

    span {
        font-weight: 600;
        line-height: 18px;
    }

    li {
        padding-top: 0px;
    }

    p {
        font-size: 0.625rem;
        line-height: 18px;
    }

    .boxIcon {
        background: #E5E5E5;
        border-radius: 3.125rem;
        position: relative;
        width: 3.125rem;
        height: 3.125rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 13px;

        .iGor_ProDetCheck {
            position: absolute;
            top: 1%;
            right: 1%;
            background: #00CEC9;
            border-radius: 3.125rem;
            color: #fff;
            padding: 0.125rem;
            width: 0.9375rem;
            height: 0.9375rem;
        }
    }


}

.iGor_ProDet-hotel-inf {
    padding-bottom: 30px;
    width: 100%;

    .iGor__accoText {
        margin-left: 16px;
    }

    .iGor_ProDet-hotel-discription {
        margin: 0;
        font-family: Poppins, sans-serif;
        font-weight: 400;
        font-size: 10px;
        line-height: 18px;
        margin-top: 8px;
        margin-bottom: 8px;
        width: 100%;
    }

}


.iGor_ProDet-hotel-info-list {
    .list-icon {
        list-style-type: none;
        padding: 0;
        margin: 0;

        .image.circle-grey {
            background-color: #e5e5e5;
            width: 2.9375rem;
            height: 2.9375rem;
            border-radius: 3.125rem;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .iGor_ProDetCheck {
            position: absolute;
            top: 0;
            right: 0;
            width: 0.9375rem;
            height: 0.9375rem;
            color: #00cec9;
            font-size: 0.5rem;

        }

        li {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            margin-bottom: 1.25rem;
        }
    }


}

// .iGor_OrderSumFooter {
//     position: fixed;
//     width: 100%;
//     bottom: 0;
// }

.iGor_ProDet-footer {
    padding: 1.5rem;
    background: #ffffff;
    margin-top: 1rem;


    ul {
        list-style: none;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        gap: 2.625rem;
        padding: 0;
        margin: 0;
        max-width: 100%;
        width: 100%;

        .iGor-footer-links {
            @include textDecorationforLinks(none, pointer, black)
        }
    }

    .igor_footerleft {
        justify-content: flex-end;
    }

    .igor_Test_credentials {
        margin-top: 20px;
        justify-content: flex-end;
    }

}

.iGor_bar_mb {
    position: relative;
    margin-right: 0.6rem;
}

.iGor_searchbar-innerpage {
    // max-width: 34.375rem;
    box-shadow: 0 1px 1px 1px #e5e5e5;
    font-size: 0.75rem;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -0%);
    background: #ffffff;
    border-radius: 0 0 0.9375rem 0.9375rem;
    padding: 0.9375rem;
    // box-shadow: 0rem 0.0625rem 0.0625rem rgba(299, 299, 299, 50%);
    display: flex;
    align-items: center;

    .iGor_bar {
        position: relative;
        margin-right: 1.5rem;

        &::after {
            content: '';
            content: "";
            width: 0.0625rem;
            height: 1.875rem;
            background: #E5E5E5;
            position: absolute;
            right: -1.5rem;
            top: 0rem;
        }
    }

    .iGor_Ser_Title {
        font-size: 0.625rem;
        font-weight: 600;
    }

    .iGor_ser_SubTitle {
        font-size: 0.75rem;
        color: #C4C4C4;
        white-space: nowrap;
    }

    .iGor_setFlex {
        align-items: center;
    }

    .iGor_SerBtn {
        width: 105px;
        height: 40px;
        margin-left: 1rem;
        color: #ffffff;
    }

    .iGoe_ser_calIcon {
        margin-right: 1rem;
    }

    .iGoe_ser_UserIcon {
        margin: 0 1rem;
    }
}

.iGor_noRooms-Available {
    padding-left: 20px;
}

.iGOr_card-listing {
    overflow: hidden;
    background-color: #fff;
    border-radius: 0.9375rem;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-bottom: 1.25rem;
    position: relative;

    .iGor_cardshear {
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
    }


    .top {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        width: 100%;

        .image {
            width: 734px;
            position: relative;
            cursor: pointer;
            margin-bottom: 0;
            height: 350px;

            .img {
                height: 100%;

                img {
                    width: 100%;
                    height: 100%;
                    -o-object-fit: cover;
                    object-fit: cover;
                    border-radius: 0.9375rem 0 0.9375rem 0.9375rem;
                    background-color: #cac8c7;
                }
            }

            .icon-wrapper {
                position: absolute;
                left: 50%;
                top: 50%;
                -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                width: 5.625rem;
                height: 5.625rem;
                // background-color: rgba(0, 0, 0, .5);
                border-radius: 50%;

                img {
                    width: 5.625rem;
                    height: 5.625rem;
                }

            }

            .iGor_iconZoom {
                position: absolute;
                top: 5%;
                right: 2%;
                cursor: pointer;
            }
        }

        .description {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            padding: 1.625rem 3.625rem 0.9375rem 2.25rem;
            width: 40%;
            justify-content: space-between;

            .iGor_RecommSection {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 5px;

                .iGor_Cancellation-Policies {
                    width: 20%;
                    height: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;

                    svg {
                        font-size: 1.2rem;
                        color: black;
                    }
                }

                .iGor_Ractive {
                    font-size: 18px;
                    color: #FF6B6B;
                    margin-right: 5px;
                    width: 5%;
                }

                .iGor_RDactive {
                    font-size: 18px;
                    color: #E5E5E5;
                    margin-right: 5px;
                    width: 5%;
                }

                .iGor_PriceReco {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-end;

                    p {
                        font-size: 0.85rem;
                    }

                    span {
                        padding-left: 2px;
                        padding-top: 2px;
                        font-family: "Poppins", sans-serif;
                        font-weight: 600;
                        font-size: 0.55rem;
                        color: #c4c4c4;
                    }
                }
            }

            .title h4 {
                color: #000;
                margin-bottom: 0;
                font-size: 14px;
                font-weight: 600;
                line-height: 18px;
            }

            .head {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                justify-content: space-between;

                .title {
                    margin-bottom: 1.125rem;

                    p:first-child {
                        margin-bottom: 0.1875rem;
                        font-size: 0.5rem;
                        color: #C4C4C4;

                    }

                    img {
                        margin-right: 0.3125rem;
                    }
                }

                .price {
                    margin-bottom: 1.0625rem;

                    .price-wrapper {
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        margin-bottom: 0;
                        align-items: center;

                        .price-value {
                            font-weight: 600;
                            font-size: 1rem;
                            color: #000;
                            line-height: 1rem;
                            margin-left: 3px;
                        }

                        .currency {
                            font-size: 0.75rem;
                            margin-left: 0.25rem;
                        }

                        .room {
                            font-weight: 400;
                            font-size: 0.5rem;
                            color: #c4c4c4;
                            margin-left: 0.25rem;
                        }
                    }

                    .price-info {
                        font-weight: 400;
                        font-size: 0.5rem;
                        color: #c4c4c4;
                    }
                }
            }

            .body {
                margin-bottom: 0.8125rem;
                border-bottom: 0.03125rem solid #e5e5e5;
                padding-bottom: 0.5rem;
                min-height: 160px;

                .text-wrapper {
                    margin-bottom: 0.625rem;
                    min-height: 55px;
                    text-align: justify;
                }

                .icon-wrapper {
                    .icon-room {
                        font-size: 0.625rem;
                        display: flex;
                        margin-bottom: 11px;

                        .title {
                            font-weight: 600;
                            margin-right: 0.3125rem;
                        }

                        .value {
                            margin-right: 3px;
                        }

                        img {
                            margin-right: 0.3125rem;
                        }
                    }
                }
            }


        }


        .btn-wrapper {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            width: 100%;

            .accordion-head {
                text-align: center;
                color: #c4c4c4;
                font-size: 0.75rem;
                font-weight: 600;
                margin-top: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }
        }
    }
}

.iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    border-radius: 0.9375rem;
    overflow: hidden;
    background-color: #cac8c7;

    iframe {
        width: 100%;
        height: 100%;
        border: none !important;
    }
}


.iGor_accordion-body {
    // padding: 1.0625rem 0 0.9375rem 2.1875rem;
    width: 100%;
    border-radius: 0.9375rem;
    display: flex;
    height: 0px;
    padding: 0;
    overflow: hidden;

    .inclusion {
        ul {
            margin-top: 0.75rem;
            margin-left: 13px;

            li {
                padding-top: 8px;
            }
        }
    }

    ul {
        font-size: 0.625rem;
    }

    &>div {
        display: block;
        width: 100%;
        padding-left: 0;
        padding-right: 0;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }

    .facilities {
        .btn-facilities {
            width: 170px;
            color: #000;
            border: 0.0625rem solid;
            border-radius: 0.6375rem;
            margin: 1rem 0;
            display: flex;
            justify-content: space-around;
            height: 35px;
            padding: 0 33px;

            p {
                color: #000;
                font-size: 0.75rem;
            }
        }
    }

    .iGor_Faci_listMain {
        li {
            width: 100px;
            padding-right: 0 !important;
            align-items: flex-start;
        }
    }

    .iGor_Faci_list {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        height: 12.5rem;

        .MuiListItemIcon-root {
            min-width: 25px;
        }

        li {
            // width: 7.8125rem;
            padding-bottom: 0rem;
            padding-left: 0rem;

            svg {
                margin-right: 0.3125rem;
            }
        }
    }

    .iGor_Recommendations_sold {
        font-size: 10px;
    }
}

.iGor_accordion_slide-left {
    -webkit-animation: slide-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    height: auto;
    padding: 1.0625rem 0 0.9375rem 1.4115rem;
    overflow: hidden;
}

@-webkit-keyframes slide-left {
    0% {
        position: relative;
        right: -70%;
    }

    100% {
        position: relative;
        right: 0%;
    }
}

@keyframes slide-left {
    0% {
        position: relative;
        right: -70%;
    }

    100% {
        position: relative;
        right: 0%;
    }
}

.iGor_accordion_slide-right {
    -webkit-animation: slide-right 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-right 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    position: relative;
    height: 0;
    overflow: hidden;
    animation-fill-mode: forwards;
    // padding: 1.0625rem 0 0.9375rem 2.1875rem;

}

@-webkit-keyframes slide-right {
    0% {
        right: 0%;
        padding: 1.0625rem 0 0.9375rem 2.1875rem;

    }

    50% {
        right: -50%;

    }

    96% {
        padding: 0;
    }

    100% {
        right: -60%;
        visibility: hidden;
        display: none;
    }
}

@keyframes slide-right {
    0% {
        right: 0%;
    }


    96% {
        padding: 0;
    }

    100% {
        right: -60%;
        visibility: hidden;
        display: none;
    }
}

.iGor__Pro_Facility {
    margin-bottom: 60px !important;
}

.iGor_FacilAmen {
    display: flex;
    justify-content: space-between;
    margin: 1rem 0;

    .iGor_FacilAmen_Icon {
        border: 0.0625rem solid #DCDCDC;
        padding: 0.9375rem;
        border-radius: 0.9375rem;
        width: 5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
            width: 2.5rem;
            height: 2.5rem;
        }

        p {
            margin-top: 0.3125rem;
        }
    }
}

.iGor_PropertyDAccordion {
    // margin-top: 15px;

    .MuiPaper-root {
        &:first-child {
            border-radius: 1.25rem 1.25rem 0 0;
        }

        &:last-child {
            border-radius: 0 0 1.25rem 1.25rem;
        }
    }

    .MuiSvgIcon-root {
        font-size: 0.75rem;
    }

    .ArrowRightIcon {
        color: #000000;
        font-size: 1.5rem;
    }

}


.iGor_box-review {
    background-color: #ff6b6b;
    border-radius: 0.9375rem;
    justify-content: center;
    width: 4.8125rem;
    height: 4.8125rem;
    margin-bottom: 0.3125rem;

    h6 {
        font-size: 1.875rem;
        font-weight: 600;
        margin-bottom: 0;
        line-height: 4.375rem;
        color: #fff;
        text-align: center;
    }

}

.iGor_reviewCardSection {
    margin: 24px 0 !important;
}

.iGor_reviewCard {
    display: flex;
    flex-wrap: wrap;

    .iGor_reviewCards {
        width: 50%;
        margin-bottom: 25px;
    }

    .iGor_reviewProfile {
        display: flex;

        img {
            width: 2.25rem;
            height: 2.25rem;
            margin: 0 0.4375rem 0.625rem 0;
        }
    }

    .iGor_reviewContent {
        padding-right: 15%;
        cursor: pointer;
    }
}

.iGor_ReviewBtn {
    width: 347px !important;
    margin: 1rem 0 !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    height: 44px !important;
}

.iGor_ProFacili_scroll {
    overflow-y: auto;
    max-height: 400px;
}

.iGor_ProFaciliList {
    padding: 0 !important;
    margin-bottom: 0.6rem !important;

    li {
        border-bottom: 0.0625rem solid #E6E6E6;
        padding: 0.5rem 0;
    }

    .MuiListItemIcon-root {
        min-width: 1.875rem;
    }

    .MuiTypography-root {
        font-size: 0.75rem;
    }
}

.iGor_proShareModal {
    width: 570px;
    height: 408px;

    .close-button {
        left: -10px;
        top: 10px;

        svg {
            font-size: 28px;
            color: #000000;
        }
    }

}

.iGor_proShareMain {

    margin-top: 3.125rem;
    padding: 0 0.5rem;

    .iGor_sharebtnBox {
        display: flex;
        justify-content: space-between;
    }

    .iGor_imgSection {
        display: flex;
        align-items: center;
        margin: 1rem 0;
        margin-top: 1.563rem;

        .iGor_proimg {
            width: 5rem;
            height: 5rem;
            border-radius: 0.9375rem;
            margin-right: 1.5rem;
        }
    }

    .iGor_sharebtn {
        border: 0.0625rem solid #D9D9D9 !important;
        border-radius: 0.625rem !important;
        padding: 0.625rem 0.9375rem !important;
        // margin:0 0.5rem !important;
        width: 15.688rem;
        height: 4.125rem;
        justify-content: flex-start !important;
        color: #000000 !important;

        svg {
            margin-right: 1rem;
        }

        .iGor_shareFb {
            color: #4285F4;
        }

        .iGor_shareWhat {
            color: #ffffff;
            background: #34A853;
            padding: 0.1875rem;
            border-radius: 0.1875rem;
        }
    }
}

.iGor__ReadMoreBtn {
    font-size: 11px !important;
    color: #000 !important;
    background: #fff !important;
    padding: 0 !important;
    text-transform: capitalize !important;
}

.iGor__moreOption {
    height: 30px;
    overflow: hidden;
    cursor: pointer;
}

.iGOr__ProHotelFacAme {
    margin-top: 80px;
    background: #fff;
    padding: 1rem 0;
}

.iGor__SeeAllBtnPro {
    width: auto !important;
    padding: 0 5.57rem !important;
    font-size: 12px !important;
}

.wishListMod {
    .wishList_header {
        display: flex;
        height: 62px;
        align-items: center;
        padding: 16px;
        border-bottom: 1px solid #E5E5E5;
    }

    .iGor-wishListLoader {
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: gray;

        svg {
            width: 22px;
            height: 22px;
        }
    }

    .wishList_btnActive {
        border-bottom: 1px solid #E5E5E5;
        display: flex;
        align-items: center;
        padding: 1rem 0;
        cursor: pointer;
    }

    .wishList_btnTrip {
        border-bottom: 1px solid #E5E5E5;
        padding: 1rem 0;
        cursor: pointer;
    }
}

@media screen and (max-width:1024px) {
    .iGor__ProRoomBox {
        .iGor_d_roomBtnSectiom {
            margin-bottom: 0;
        }
    }

    .iGor_ProDet-hotel-inf {
        .iGor_ProDet-hotel-discription {
            text-align: justify;
        }

    }

    .iGor_ProFacili_scroll {
        max-height: 92%;
    }

    .iGor_proShareMain {
        max-width: 435px;
        margin: 60px auto;
    }

    .iGor_Faci_list {
        justify-content: space-between;
    }

    .iGOr_card-listing {
        border-radius: 0;

        .top {
            .image {
                height: 365px;
                width: 55% !important;
            }
        }

        .description {
            width: 44% !important;
            padding-right: 20px !important;
        }
    }

    .iGor__ProBackArrow {
        position: absolute !important;
        z-index: 1;
        min-width: 40px !important;
        height: 40px !important;
        top: 70px;
        left: 25px;
        opacity: 0.5px;
        background: #00000080 !important;
        border-radius: 50px !important;
        display: block !important;

        svg {
            width: 25px !important;
            height: 25px !important;
            color: #fff;
        }
    }

    .iGor_reviewCard {
        .iGor_reviewContent {
            padding: 0 !important;
            height: 59% !important;
            overflow: auto;
            padding-right: 18px !important;
            text-align: justify;
        }

        .iGor_reviewCards {
            width: 48%;
            margin-bottom: 25px;
            border: 0.5px solid #C4C4C4;
            border-radius: 10px;
            padding: 11px 0 18px 18px;
            margin-right: 6px;
            max-height: 108px;
            overflow-y: hidden;
        }

        .iGor_reviewCards100 {
            max-height: 100% !important;

            .iGor_reviewContent {
                height: 100% !important;
            }
        }
    }

    .iGor_reviewSection {
        display: flex;

        .d-flex {
            display: flex;
        }

        .iGor_box-review {
            width: 30px;
            height: 16px;
            display: flex;
            align-items: center;
            justify-content: center;

            h6 {
                font-size: 11px;
                line-height: normal;
            }
        }
    }

    .iGor_ProDetHeader {
        img {
            display: none;
        }

        .iGor_m-menuBtn {
            position: absolute !important;
            min-width: 40px !important;
            height: 40px !important;
            top: 70px !important;
            left: 25px;
            right: auto !important;
            opacity: 0.5px;
            background: #00000080 !important;
            border-radius: 50px !important;
        }

    }
}

@media screen and (max-width:600px) {
    .iGor_proShareMain {
        .iGor_sharebtn {
            width: 12.688rem;
        }
    }

    .iGor_reviewCardSection {
        margin-bottom: 0 !important;
    }



    .iGor__ProRoomBox {
        width: 100% !important;
        padding: 20px
    }

    .iGOr__ProHotelFacAme {
        margin-top: -8px;
        position: relative;

        .iGor__SeeAllBtnPro {
            position: absolute;
            top: -7px;
            background: transparent;
            color: #FF6B6B;
            font-size: 8px;
            font-weight: 400;
            right: 16px;
            padding: 0 !important;

            &:active,
            &:hover {
                background: transparent;
                color: #FF6B6B;
            }
        }
    }

    .iGor_ProDet-hotel-inf {
        .iGor__accoText {
            margin-left: 0px;
        }

        padding-bottom: 14px !important;
        padding-top: 0 !important;

        .iGor_ProDet-hotel-info-des {
            padding-top: 0 !important;
        }
    }


    .iGor_headerPro {
        padding: 0 !important;
    }

    .iGor__ProBackArrow {
        position: absolute !important;
        z-index: 1;
        min-width: 40px !important;
        height: 40px !important;
        top: 23px;
        left: 25px;
        opacity: 0.5px;
        background: #00000080 !important;
        border-radius: 50px !important;
        display: block !important;

        svg {
            width: 25px !important;
            height: 25px !important;
            color: #fff;
        }
    }


    .iGor_accordion-body {

        padding: 0 10px 10px 25px;

        .facilities {
            text-align: center;

            .MuiTypography-subtitle1 {
                text-align: left;
            }

            .btn-facilities {
                margin: 0;
                width: 100% !important;
                max-width: 150px !important;
            }
        }

        .inclusion,
        .details,
        .facilities {
            max-width: 100%;
            flex: 100%;
        }

        .inclusion {
            ul {
                display: flex;
                flex-wrap: wrap;
                margin-top: 5px;
                margin-left: 10px;
                margin-bottom: 10px;

                li {
                    width: 150px;
                    padding-right: 20px;
                }
            }
        }

        .iGor_Faci_list {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            height: 100%;

            li {
                width: 108px;
                padding: 0 0 8px 0;
            }
        }

        flex-direction: column;
    }

    .iGor_PropertyDetails {
        height: 75vh;
        border-radius: 0 0 15px 15px !important;

        .subtitle {
            display: flex;
        }

        &:before {
            border-radius: 0 0 15px 15px !important;
        }

        .iGor_iFrameimg {
            border-radius: 0 0 15px 15px;
        }



        .iGor_ProDetDescription {
            // height: 140px;
            bottom: -49px;

            .title {
                h2 {
                    line-height: 1.5;
                }
            }

            .box-review {
                .value {
                    width: 55px;
                    height: 55px;

                    span {
                        font-size: 22px !important;
                    }

                    .text-value {
                        span {
                            font-size: 8px !important;
                        }

                    }
                }
            }

            .title-wrapper {

                .title {
                    flex-direction: column;
                    margin-bottom: 12px;

                    h2 {
                        font-size: 12px !important;
                        font-weight: 600 !important;
                    }
                }
            }

            .btn-wrapper {
                .btn-outline {
                    margin-left: 0 !important;
                }

                .btn-outline:last-child {
                    margin-left: 10px !important;
                }
            }

            .iGor_topLocTitel {
                margin-left: -3px;

                img {
                    margin: 0;
                    display: flex;
                }
            }
        }
    }


    .iGOr_card-listing {
        border-radius: 0;

        .iframe {
            position: relative;
            border-radius: 0;
        }

        .top {
            flex-direction: column;

            .description {
                width: 100% !important;
                padding: 10px 25px !important;

                // .iGor__moreOption {
                //     height: 0;
                // }
                .body {
                    min-height: auto;
                }

                .head {

                    .price,
                    .title {
                        margin-bottom: 0 !important;
                    }
                }

                .title {

                    h4 {
                        font-size: 12px;
                    }
                }

                .text-wrapper {
                    min-height: auto !important;
                }

                .price-value {
                    font-size: 12px;
                }

                .iGor__ReadMoreBtn {
                    font-size: 8px !important;
                }
            }

            .image {
                width: 100% !important;
                height: 164px;

                .img {
                    height: 100%;

                    img {
                        border-radius: 0 !important;
                    }
                }
            }
        }

        .iGor_cardshear,
        .iGor_iconZoom {
            display: none;
        }

        .description {

            .icon-wrapper {
                display: flex;

                .icon-room {
                    margin-right: 1.5rem;
                }
            }
        }

    }

    .iGor__Pro_Facility {
        margin-bottom: 0px !important;
    }

    .iGor_FacilAmen {
        max-width: 95vw;
        overflow-x: auto;
        margin: 0 !important;

        .iGor_FacilAmen_Icon {
            margin: 0 0.3125rem 8px 0.3125rem;
            padding: 8px 16px;
        }
    }


    .iGor_searchbar-innerpage {

        .iGor_bar {
            &::after {
                display: none;
            }
        }
    }

    .iGor__ListSection {
        max-width: 96vw;
        overflow-x: scroll;

        .iGor_ProDList {
            display: flex;
            align-items: flex-start;
            margin-top: 12px;
            padding-top: 0;
            padding-bottom: 5px;

            &:first-child {
                margin-left: 2px;
            }


            .MuiListItem-root {
                box-shadow: 0px 0px 4px 0px #00000040;
                border-radius: 10px;
                margin-right: 10px;
                padding: 7px 9px;

                &:last-child {
                    margin-right: 20px;
                }
            }

            .MuiListItemAvatar-root {
                min-width: auto;

                .boxIcon {
                    margin-right: 9px;
                }
            }

            .MuiListItemText-root {
                width: 90px;
            }

            p {
                display: none;
            }

        }

    }

    .iGor_searchbar-innerpage {
        margin-top: 20px;
        border-radius: 50px !important;
        width: 90% !important;
        height: 45px;
        align-items: center;

        .iGor_Ser_Title {
            display: none;
        }

        .iGor_ser_SubTitle {
            font-size: 10px;
            color: #000000;
        }
    }

    .iGor__HotelFacHead,
    .iGor_reviewSection {
        background: #f1f5f9;
        margin: -16px !important;
        padding: 10px 16px;
        margin-bottom: 20px !important;
        height: 40px;
    }

    .iGor_reviewSection {
        width: 110%;
        padding: 12px 16px !important;
        margin-left: -16px !important;
        margin-top: 10px !important;
        margin-bottom: 0 !important;
    }

    .iGor__Pro_ThtoKnow {
        margin-top: 13px !important;

        >.MuiTypography-root {
            padding-left: 3px;
            background: #f1f5f9;
            margin: -16px;
            padding: 10px 16px;
            margin-bottom: 10px;
            height: 40px;
            margin-top: 10px;
        }

        >.MuiBox-root {
            padding: 0rem 0px 5px 0px !important
        }
    }

    .iGOr__ProDeMap {
        margin-bottom: 100px;
    }

    .iGor__ProDetailsCardS {
        padding: 0 0px 15px 0px !important;
        margin-top: 65px;

        .iGor_procardAvil {
            padding: 13px 0 8px 16px !important;
            // position: relative;
            // left: 3%;
        }
    }



    #iGor_propertyDateP {
        .rdrMonthAndYearWrapper {
            margin: 0 10px !important;
        }
    }
}