// :root {
    $primaryColor: #FF6B6B;
    $btnPrimaryColor: linear-gradient(180deg, '#FF6B6B0', '#EE5253100');
    $secondaryColor: #C4C4C4;
    $bgGrayColor: #F1F5F9;
    $whiteColor: #ffffff;
    $fontSize18: 1.125rem;
    $fontSize14: 0.875rem;
    $fontSize12: 0.75rem;
    $fontSize10: 0.625rem;
    $fwRegular: 400;
    $fwMedium: 500;
    $fwSemibold: 600;
    $blackColor: #000000;
// }