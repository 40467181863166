 .iGor__banner-article-details {
     position: relative;
     padding-bottom: 72px;
     margin-bottom: 33px;

     .iGor__images {
         width: 100%;
         height: 442px;

         .iGor__img {
             max-height: 442px;
             overflow: hidden;
             border-radius: 0 0 50px 0;

             img {
                 width: 100%;
                 height: 100%;
                 -o-object-fit: cover;
                 object-fit: cover;
                 background-color: #83807e;
             }
         }
     }

     .iGor__description {
         background-color: #fff;
         max-width: 434px;
         position: absolute;
         bottom: 0;
         left: 57px;
         padding: 33px 65px 25px 37px;
         box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
         border-radius: 0 50px 0 0;

         .iGor__type {
             line-height: 20px;
         }

         .iGor__title {
             font-size: 32px;
             font-weight: 600;
             line-height: 34px;
         }

         .iGor__info {
             display: flex;
             margin-bottom: 18px;
             margin-top: 8px;

             .iGor__read {
                 margin-right: 22px;
             }

             .iGo__subtitle {
                 font-size: 8px;
             }

             img {
                 width: 14px;
                 margin-right: 5px;
             }
         }

         .iGor__btn-wrapper {
             display: flex;
             gap: 9px;
             margin-top: 20px;

             .iGor__btn-icon {
                 width: 36px;
                 height: 36px;
                 background-color: #fff;
                 display: flex;
                 align-items: center;
                 justify-content: center;
                 border-radius: 50%;
                 filter: drop-shadow(0 4px 4px rgba(0, 0, 0, .25));
                 cursor: pointer;

                 .share {
                     color: #FF6B6B;
                     width: 20px;
                 }
             }
         }
     }
 }

 .iGor-article_body {
     margin: 3% 0 5% 0;

 }

 .iGor__articleCards {

     .iGor__ArtTitle {
         font-size: 20px;
     }

     .iGor__ArtImgCard {
         margin: 20px 0;
         max-height: 375px;

         img {
             width: 100%;
         }
     }

     .iGor__ArtSubTitle {
         font-size: 10px;
     }
 }  