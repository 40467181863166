.iGor_verCodeinput {
    width: 3.25rem;
    height: 2.5rem;
    border-radius: 3.125rem;
    background: #F1F5F9;
    border: none;
    margin: 0.9375rem 0.9375rem 0.9375rem 0;
    text-align: center;
}

.iGor_verCodeinputEmail {
    width: 3.25rem;
    height: 2.5rem;
    border-radius: 3.125rem;
    background: #F1F5F9;
    border: none;
    margin: 0.9375rem 0.9375rem 0.9375rem 0;
    text-align: center;
    &:last-child{
        margin-right: 0;  
    }
}

.iGor_verCodeinput:last-child {
    margin-right: 0;
}

.iGor_verCodeinputEmail :last-child {
    margin-right: 0;
}

.iGor_userProfile {
    width: 9.1875rem;
    background: #F1F5F9;
    height: 9.1875rem;
    margin: 34px auto 23px auto;
    border-radius: 50%;
    padding: 1.875rem;
}

.iGor_userProfileBox {
    padding: 20px;

    .iGor__userMsg {
        font-weight: 500;
        line-height: 18px;
    }

    .iGor__userName {
        font-size: 12px;
        font-weight: 400;
        margin-bottom: 18px;
    }

    .iGor__userInfo {
        margin-bottom: 14px;
        line-height: 18px;
        font-weight: 600;
    }

    .iGor__closeBtn {
        margin-top: 22px;
    }
}

.iGor_verifiImg {
    display: flex;
    flex-wrap: wrap;
    padding: 15px 0px 5px;
    justify-content: center;
    overflow-y: scroll;
    height: 331px;

    .iGor_verifCardImg {
        position: relative;
        margin: 0.3125rem;
        border-radius: 0.9375rem;
        width: 10.375rem;
        height: 9.5rem;
        cursor: pointer;
    }

    .iGor_verifiTextOver,
    .circleCheckIcon {
        position: absolute;
        color: #fff;
        left: 50%;
        top: 80%;
        transform: translate(-50%, -80%);
    }

    .iGor_verifiText {
        font-weight: 600;
    }

    .circleCheckIcon {
        display: none;
        width: 2.5rem;
        height: 2.5rem;
    }

    .iGor_SelImg {
        .circleCheckIcon {
            display: block;
            top: 50%;
            transform: translate(-50%, -50%);
        }

        &::before {
            content: '';
            width: 100%;
            height: 100%;
            background: #FF6B6B;
            position: absolute;
            opacity: 0.7;
            border-radius: 0.9375rem;
        }
    }

    img {
        width: 100%;
        height: 100%;
        border-radius: 0.9375rem;
        object-fit: cover;
    }
}

.iGor_verifCardImgTwo.iGor_SelImg {
    &::before {
        border-radius: 100%;
    }

}

.iGor_verifCardImgTwo {
    width: 10.375rem !important;
    height: 10.375rem !important;

    img {
        border-radius: 100% !important;
        object-fit: cover;
    }
}

.MuiPopover-root {
    .MuiPaper-root {
        border-radius: 0.625rem !important;
    }
}

.iGor__logingUserProfile {
    padding-left: 0 !important;
}

.iGor_userLoginProBtn {
    background: #ffffff !important;
    border-radius: 3.125rem !important;
    margin: 0.5rem !important;
    margin-right: 0 !important;
    padding: 0.3125rem !important;
    color: $blackColor !important;
    height: 40px !important;

    .iGor_userLoginProImg {
        background: #FF6B6B;
        border-radius: 0.9375rem;
        width: 1.8125rem;
        padding: 0.3125rem;
        height: 30px;
    }
}

.increase_height {
    margin-bottom: 10px
}

.iGor-resendOTP_box {
    display: flex;
    justify-content: center;

    p {
        font-size: 8px;
        color: #757575;
        font-weight: 500
    }
}

.iGor-OTP_timer {
    display: flex;
    justify-Content: center;
    
}

.iGor-OTP_timer_hide {
    visibility: hidden;
}
.iGor__VerificationBg {
    .iGor__arrowBackBtn {
        position: absolute;
        top: 22px;
        left: 15px;
        cursor: pointer;
    }
}

.iGor__addPadding {
    padding: 20px;
}

@media screen and (max-width:1024px) {
    .iGor_verifiImg {
        width: 100%;
        margin: 0 auto;
    }
}
@media screen and (max-width:600px) {
    .iGor_verifiImg {
        width: 360px;
        margin: 0 auto;
    }
}