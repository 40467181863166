.iGor-inbox_newsrooms {
    overflow: hidden;

    .iGorNews_ImgCard {
        position: relative;
        z-index: 0;

        .iGorNews_Img {
            border-radius: 15px;
            height: 213px;
            width: 100%;
        }

        .iGorNews_content {
            position: absolute;
            bottom: 30px;
            left: 20px;
            color: #fff;
            width: 50%;

            .iGorNews_Tilte {
                font-size: 20px;
                font-weight: 600;
                line-height: 22px;
                color: #fff;
            }

            .iGorNews_Auther {
                font-size: 10px;
                font-weight: 400;
                line-height: 15px;

            }


        }
    }

    .iGorNews_Topic {
        font-size: 12px !important;
        font-weight: 600 !important;
        margin-bottom: 9px !important;
        margin-top: 10px !important;
    }

    .iGorNews_letest {
        overflow: hidden;
        overflow-x: auto;
    }

        .iGorNews_letestCards {
            display: flex;
            flex-direction: row;
            width: 500px;
    
            a {
                text-decoration: none;
            }
        }

    .iGorNews_letCard {
        background: #ffffff;
        width: 231px;
        height: 87px;
        border-radius: 10px;
        padding: 9px;
        display: flex;
        margin-right: 10px;
        

        .iGorNews_letImgs {
            width: 70px;
            height: 70px;
            border-radius: 10px;

            .iGorNews_letImg {
                width: 100%;
                height: 100%;
                border-radius: 10px;
            }
        }

        .iGorNews_letContent {
            margin-left: 13px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .iGorNews_letTitle {
                font-size: 10px !important;
                font-weight: 600 !important;
                line-height: 15px !important;
                color: black;
            }

            .iGorNews_letDate,
            .iGorNews_letAuther {
                font-size: 8px !important;
                font-weight: 400 !important;
                color: #757575;

            }
        }
    }

    .iGorNews_Travelcard {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .iGorNews_ImgTravelCard {
            width: 49%;
            height: 145px;
            border-radius: 10px;
            position: relative;
            z-index: 0;
            margin-bottom: 10px;

            .iGorNews_ImgTravel {
                width: 100%;
                height: 100%;
            }

            .iGorNews_Traveltitle {
                font-size: 8px;
                font-weight: 600;
                line-height: 12px;
                text-align: center;
                color: #ffffff;
            }

            .iGorNews_TravelDate {
                font-size: 4px;
                font-weight: 300;
                line-height: 6px;
                text-align: center;
                color: #ffffff;
            }

            .iGorNews_TravelCon {
                position: absolute;
                bottom: 0;
                height: 40px;
                background: rgba(0, 0, 0, 0.1);
                width: 100%;

                .iGorNews_Traveltext {
                    width: 70%;
                    margin: 0 auto;
                }
            }
        }
    }
}