.iGo_mb-menuTop {
    padding: 35px 0;
    text-align: center;

    .iGo_m-menuClear {
        width: 29.39px;
        height: 29.39px;
        position: absolute;
        top: 42px;
        right: 5%;
    }
}

.iGor_Verified {
    font-size: 15px !important;
    margin-bottom: -3px;
}

.iGo_mb-UserLoggedIn {
    box-shadow: 0 0 4px 1px rgba(0, 0, 0, 15%)
}

.iGo_mb-UserMenu {
    width: 100vw;
    height: 100%;
    padding: 25px;
    overflow-y: auto;
    padding-bottom: 160px;

    .iGor_UserIcon {
        min-width: 30px;
    }
}

.iGor_m-menuBtn {
    position: absolute !important;
    right: 11%;
    top: 20%;

    .iGo_m-menuIcon {
        color: $whiteColor;
        font-size: 25px;
    }
}

.iGor_menuArrow {
    color: #FF6B6B;
    font-size: 30px !important;
}

.igor_mobileList {
    min-width: 30px;

    .iGor_UserTitle {
        span {

            font-size: 14px;
            font-weight: 500;
        }
    }

    .iGoRoom_login_pop {
        .igoRoomBtn {
            height: 45px;
        }
    }
}

.iGo_mb-menuBox {
    .MuiPaper-root {
        overflow: hidden;
    }
}

.iGo_m-Menu {
    display: none;
}

@media screen and (max-width:1024px) {
    .iGor_mb_UserDetails {
        max-width: 600px;
        margin: 0 auto;
    }

    .iGr__RegAccountPopup {
        .MuiBackdrop-root {
            background: $bgGrayColor !important;
        }
    }

    .iGr__VerifictionPopup {
        .MuiBackdrop-root {
            background: $whiteColor !important;
        }
    }

    .iGo_m-Menu {
        display: block;
    }

    .iGor__SecondMenuMB {
        .iGo_m-Menu {
            position: relative;
            top: 46px;
            left: -20px;
        }
    }

    .iGor__inboxMenuMB {
        .iGo_m-Menu {
            position: static;
        }

        .iGor_m-menuBtn {
            margin-top: 0 !important;
        }
    }

    .iGor__UserLogoutBtn {
        position: fixed;
        bottom: 0;
        padding: 0px 25px;
        width: 100%;
        z-index: 1 !important;
        left: 0;
        background: white;
        height: 85px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .iGoRoom_LayoutMain {
        position: fixed;
        width: 100%;
    }

    .iGor__TeramAndConLink {
        color: #757575 !important;
        text-decoration: none !important;

    }

    .iGor__TeramAndConLinkTow {
        color: #000000 !important;
        text-decoration: none !important;
    }

    .iGr__RegAccount {
        overflow: hidden;
        overflow-y: auto;
        padding-bottom: 100px;
    }

    .iGOr_MB-listLogin {
        width: 100%;
        font-size: 14px !important;
    }

    .iGoRoom_login_pop {
        height: calc(100vh - 120px);
        overflow-y: auto;
        max-width: 100% !important;
        padding: 0 0.9375rem !important;
        text-align: center;
        padding-bottom: 150px !important;

        .iGor__CreateAccountBtn {
            display: block;
            margin: 0 auto;
        }

        .InputField {
            width: 100%;
            max-width: 340px;
            margin: 0 auto 16px auto;
            height: 45px !important;
        }

        .iGor_mailIcon {
            color: #000000;
            width: 17px !important;
        }

        .inputIcons {
            filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(209deg) brightness(0%) contrast(97%);
            width: 19px !important;

        }

        .iGor__JoinNowBtn {
            font-size: 12px;
        }

        .iGor__linkBox {
            border-bottom: 1px solid #C4C4C4;
            padding: 24px 0 35px 0;
            display: flex;
            justify-content: center;
            align-items: center;

            span {
                margin: 0 3px;
            }

            .iGor__link {
                font-size: 10px;
                color: #000000;
                font-weight: 600;
            }
        }

        .MuiListItem-root {
            border-bottom: 1px solid #E5E5E5;
            width: 100%;
            padding: 16px 0 19px 0;

            .MuiSvgIcon-root {
                font-size: 28px;
                // margin-top: 17px;
            }
        }

        .MuiList-root {
            padding: 0 18px;
            margin-bottom: 20px;

        }
    }


    .iGor_MB_loginBtn,
    .iGor__CreateAccountBtn,
    .iGOr_MB-primaryBtn {
        border-radius: 50px !important;
        color: #ffffff !important;
        font-size: 14px !important;
        height: 45px !important;
        font-size: 14px !important;
        font-weight: 600 !important;
        width: 100% !important;
        max-width: 330px !important;

    }

    .iGor__loginBtnSocial {
        align-items: center;
    }

    .iGor_MB_loginBtn-Email {
        background: #c4c4c4 !important;
    }

    .iGor_addmb {
        margin-bottom: 10px !important;
    }

    .iGor_MB_loginBtn-Google {
        background: #2D3748 !important;
        margin-bottom: 10px !important;
        margin-top: 0 !important;
    }

    .iGor_MB_loginBtn-Facebook {
        background: #2057B4 !important;
        margin-bottom: 10px !important;
    }

    .iGor_MB_loginBtn-Apple {
        background: #000000 !important;
    }
}

@media screen and (max-width:767px) {
    .iGor__SecondMenuMB {
        .iGo_m-Menu {
            position: static;
        }
    }
}