.iGor__banner-explorer {
    position: relative;
    margin-bottom: 2.0625rem;

    .iGor__header-explorer {
        position: absolute;
        z-index: 1;

    }

    .iGor__image {
        max-height: 29.125rem;
        border-radius: 0 0 1.5625rem 1.5625rem;
        overflow: hidden;
        height: 470px;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, .35);
        }

        .iframe {
            width: 100%;
            object-fit: cover;
            object-position: 50% 50%;
            height: 100%;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .noImageFound {
            object-fit: scale-down;
        }
    }

    .iGor__description {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        text-align: center;

        .iGor__title {
            font-size: 3.625rem;
            font-weight: 600;
            line-height: 5.4375rem;
            margin-bottom: 0;
            color: #ffffff;
        }

        .iGor__subtitle {
            font-size: 20px;
            color: #fff;
            font-weight: 400;
            line-height: 1.875rem;
            text-transform: uppercase;
        }
    }
}


.iGor_ExMore_cards {
    max-width: 18.625rem;
    position: relative;
    margin-right: 0.625rem;

    .iGor_Icon_InfoPro {
        position: absolute;
        right: 10px;
        top: 10px;
    }

    .iGor_ExMore_images {
        // max-height: 13.125rem;
        // position: relative;

        .iGor_ExMore_img {
            img {
                border-radius: 0.625rem;
                width: 100%;
                height: 13.125rem;
                object-fit: cover;
                background-color: #dcdcdc;
            }
        }
    }

    .iGor_ExMore_description {
        padding: 0.75rem 0.5rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .iGor_ExMore_btn {
            text-transform: uppercase;
            padding: 0 0.75rem;
            background-color: #fff;
            color: #ff6b6b;
            -webkit-transition: background .3s ease;
            transition: background .3s ease;
            border: 0.03125rem solid #c4c4c4;
            padding: 0.25rem 0.75rem;
            border-radius: 0.1875rem;

            span {
                font-weight: 400;
                line-height: 0.5rem;
                font-size: 0.5rem;
            }

        }

        .iGor_ExMore_title {
            font-size: 0.75rem;
            font-weight: 600;
            color: #000;
            line-height: 0.875rem;
            margin-bottom: 0.3125rem;
            margin-top: 0.5rem;
            text-decoration: none;
            max-height: 1.875rem;
        }
    }

}

.iGor__findThigSlider,
.iGor__regionsSlider {

    .slick-prev,
    .slick-next {

        color: #000 !important;
        font-size: 1.25rem;
    }

    .slick-disabled {
        color: #C4C4C4 !important;
    }

    .slick-prev {
        left: 88.5% !important;
    }

    .slick-next {
        right: 3% !important;
    }
}

.iGor__findThigSlider {

    .slick-prev,
    .slick-next {
        top: -13% !important;
    }
}

.iGor__regionsSlider {


    .slick-prev,
    .slick-next {
        top: -7% !important;
    }

    .iGor__portrait {
        width: 11.375rem;

        .iGor_ExMore_images {
            height: 16.25rem;
            border-radius: 0.9375rem;

            img {
                height: 260px;
            }
        }
    }
}

.iGor__ExBtnFilter {
    background: #F1F5F9 !important;
    font-size: 0.625rem !important;
    border-radius: 0.3125rem !important;
    color: #000000 !important;
    margin-right: 0.3125rem !important;
    text-transform: none !important;
}


.iGor__card-offers {
    background-color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    max-width: 34.6875rem;
    border-radius: 0.9375rem;
    padding: 1.1875rem 0.9375rem 1.1875rem 1.125rem;
    margin-bottom: 0.875rem;

    .iGor__image {
        width: 13.8125rem;
        position: relative;
        cursor: pointer;
        margin-bottom: 0;

        .iGor__img {
            border-radius: 0.625rem;
            height: 9.375rem;
            width: 100%;
            object-fit: cover;
            background-color: #cac8c7;
        }

        .iGor__icon-wishlist {
            right: 1.5rem;
            left: unset;
            top: 0.875rem;
            position: absolute;
        }
    }

    .iGor__description {
        padding-left: 1.0625rem;
        display: flex;
        flex-direction: column;
        width: 20.6875rem;
        justify-content: space-between;
        max-height: 12.9375rem;

        .iGor__head>p {
            margin-bottom: 0;
            font-weight: 400;
            font-size: 8px;
            // line-height: 0.75rem;
            color: #C4C4C4;
            display: flex;
        }

        h2 {
            font-weight: 600;
            font-size: 0.75rem;
            // line-height: 1.125rem;
            color: #000;
        }

        .iGor__icon-location {
            color: #000000 !important;
            align-items: center;

            svg {
                color: #FF6B6B;
                width: 10px;
                height: 10px;
            }
        }

        .iGor__body {

            .iGor__price {
                margin-bottom: 0.25rem;

                p {
                    font-weight: 400;
                    font-size: 0.5rem;
                    line-height: 0.75rem;
                }

                .iGor__price-wrapper {
                    display: flex;
                    margin-bottom: 0;
                    font-weight: 600;
                    font-size: 0.75rem;
                    color: #000;
                    line-height: 1.125rem;

                    // .iGor__currency {}

                    .iGor__room {
                        font-weight: 400;
                        font-size: 0.5rem;
                        line-height: 0.75rem;
                        color: #c4c4c4;
                        display: flex;
                        align-items: center;
                    }
                }

            }

            .iGor__review {
                display: flex;
                align-items: center;
                // margin-bottom: 0.6875rem;

                .iGor__stars {
                    .iGor_starIcon {
                        color: #E26721;
                        width: 8px;
                    }

                    .iGor_starIcon_not_rating {
                        color: #83807e;
                        width: 8px;
                    }

                    line-height: 1rem;
                    margin-right: 0.3125rem;
                    display: flex;
                    align-items: center;

                    svg {
                        color: #ff6b6b;
                        font-size: 0.5rem;
                    }
                }

                .iGor__ratings-value {
                    background-color: #ff6b6b;
                    margin-right: 0.3125rem;
                    padding: 0 0.3125rem;
                    border-radius: 1.25rem;
                    height: 0.75rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    span {
                        font-size: 0.5rem;
                        line-height: 0.5rem;
                        font-weight: 600;
                        color: #fff;
                    }
                }
            }
        }

    }
}

.iGor-NoDataOffersSection {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 10vh;
    font-size: 1.2rem;
    font-weight: 500;
    color: darkgray;
}

.iGor__card-offers.iGor__portrait {
    display: flex;
    flex-direction: column;
    max-width: 24.375rem;
    padding: 0;
    box-shadow: 0 0 0.25rem rgba(0, 0, 0, .15);
    border-radius: 0.625rem;
    margin-bottom: 2px;

    .iGor__body {
        display: flex;
        align-items: flex-end;
    }

    .iGor__image {
        width: 100%;
    }

    .iGor__description {
        padding: 0.5rem 1.5625rem 0.8125rem;
        display: flex;
        flex-direction: row;
        width: 100%;
    }
}

.iGor__ExptabSection {
    margin: 24px 0;
}

.iGor__ExploreTab {
    background: #F1F5F9;
    border-radius: 3.125rem;
    padding: 0.4375rem 0.3125rem;
    display: flex;
    justify-content: space-between;
    max-width: 21rem;
    text-transform: capitalize !important;
    margin: 0 auto;


    .iGor_ExploreTabs {
        background: transparent;
        color: #C4C4C4 !important;
        font-size: 0.625rem;
        border-radius: 3.125rem;
        padding: 0.4375rem 1.0625rem;
        margin: 0 auto;
        max-width: 21rem;
        text-transform: capitalize !important;

        &:hover {
            background: linear-gradient(360deg, #FF6B6B, #EE5253);
            color: #fff !important;
        }
    }

    .iGor_ExTabsActive {
        background: linear-gradient(360deg, #FF6B6B, #EE5253);
        color: #fff !important;
    }
}

.iGor__FilterBtnMain {
    color: #000 !important;
    border: 0.0625rem solid #C4C4C4 !important;
    border-radius: 3.125rem !important;
    font-size: 0.625rem !important;
    padding: 0.625rem 1.25rem !important;
    text-transform: capitalize !important;
}

.iGor-Explore-TabGrid {
    display: flex;
    flex-direction: column !important;
    width: 100%;
}

@media screen and (max-width:1024px) {
    .iGor__ExptabSection {
        margin-bottom: 10px;

        .iGor__ExploreTab {
            margin-top: 15px;
        }
    }

    .iGor__ExpMoreSidelerBox {
        flex-direction: column-reverse !important;
    }

    .iGor__ExploreTab {
        margin-top: 10px;
    }

    .iGor__header-explorer {

        width: auto !important;
        right: 30px;

        img {
            display: none;
        }
    }

    // .iGor__findThigSlider,
    // .iGor__regionsSlider {
    //     width: 94vw;
    // }

    .iGor__ExploreCardOff {
        .iGor__card-offers {
            max-width: 100%;
        }
    }

    .iGor__regionsSlider,
    .iGor_ExMore_cards {
        position: relative;

        .iGor_ExMore_description {
            position: absolute;
            bottom: 0;

            .MuiTypography-root,
            .iGor_ExMore_title,
            .subtitle {
                color: #ffffff;
            }

        }
    }

    .iGor__regionsSlider {
        .iGor__portrait {
            width: 155px !important;


            .iGor_ExMore_images {
                img {
                    height: 211px;
                }
            }
        }
    }

    .iGor__regionsSlider .slick-prev,
    .iGor__regionsSlider .slick-next {
        top: -10% !important;
    }

    .iGor__findThigSlider .slick-prev,
    .iGor__regionsSlider .slick-prev {
        left: 85.5% !important;
    }

    .iGor__findThigSlider .slick-prev,
    .iGor__findThigSlider .slick-next {
        top: -35% !important;
    }

    .iGor__banner-explorer {
        // .iGor__image {
        //     img {
        //         height: 446px;
        //     }
        // }

        .iGor__description {

            .iGor__title {
                font-size: 24px;
                font-weight: 600;
                line-height: 30px;
            }

            .iGor__subtitle {
                font-size: 16px;
                text-transform: uppercase;
                margin-top: 15px
            }
        }
    }

    .iGor__card-offers {
        .iGor__image {
            .iGor__img {
                height: 139px;
                object-fit: cover;
                background-color: #83807e;
            }
        }

        .iGor__description {
            border-radius: 25px 25px 0 0 !important;
            h2 {
                margin: 0.1rem 0;
            }
        }
    }
}

@media screen and (max-width:767px) {
    .section-article-detail {
        width: 100vw;
    }

    .iGor-article_wrapper {
        padding: 0px !important;
    }

    .iGor__banner-article-details {
        padding-bottom: 0.5rem !important;

        .iGor__btn-icon {
            background-color: rgba(0, 0, 0, 0.5) !important;

            .share {
                color: #ffffff !important;
            }
        }

        .wishlist {
            background-color: #FF6B6B !important;
        }

        .iGor__images {
            height: 100% !important;

            .iGor__img {
                height: 442px;
                border-radius: 0 !important;
            }
        }

        .iGor__description {
            padding: 20px !important;
            padding-bottom: 0px !important;
            box-shadow: none !important;
            left: 0 !important;
            border-radius: 25px 25px 0 0 !important;
            bottom: 30px !important;
            position: relative !important;

            .iGor__info {
                flex-direction: column;
                margin: 0 !important;
            }

            .iGor__readBy {
                img {
                    width: 24px !important;
                    margin-right: 5px;
                }

                .iGo__subtitle {
                    margin-top: -10px !important;
                    font-size: 12px !important;
                }
            }

            .iGor__readTime {
                img {
                    display: none;
                }

                .iGo__subtitle {
                    margin-left: 34px;
                    margin-top: -7px;
                }

            }

            .iGor__date {
                img {
                    display: none;
                }

                .iGo__subtitle {
                    margin-left: 85px;
                    margin-top: -13px;
                }
            }

            .iGor__title {
                position: absolute;
                top: -80px;
                color: #fff;
                padding: 10px;
            }

            .iGor__type {
                line-height: 20px;
                position: absolute;
                top: -95px;
                color: #fff;
                padding: 10px;

            }



            .iGor__btn-wrapper {
                position: absolute;
                top: -30px;
                flex-direction: row-reverse;
                right: 15px;
                margin-top: 15px !important;
            }
        }
    }

    .iGor__articleCards {
        background: #ffffff;
        min-height: 60vh;
        padding: 15px 20px;
        margin-top: -50px;
        width: 100vw;

        body {
            background: #ffffff !important;
        }

        img {
            width: 100% !important;
        }
    }
}