.iGoR_SearchResult {

    .iGor-No_Result_messages {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 90%;
        height: 30%;
        top: 65%;

        .iGor-No_Result_image {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            height: 60%;

            img {
                width: 50px;
                height: 50px;
            }

            P {
                font-size: 1.3rem;
                font-weight: 500;
                font-family: 'Poppins';
            }
        }

    }

    .subtitle2 {
        color: $secondaryColor;
        display: flex;

        img {
            margin-right: 5px;
        }
    }

    .priceRange {
        margin-top: 11px;
    }

    .iGor_priceRange {
        min-width: 260px;
        text-align: center;
        height: 60px;

        img {
            width: 100%;
            height: 100%;
        }
    }

}

.iGor-action_loader {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        color: #ffffff;
        font-size: 1.4rem;
    }
}

.iGor_favCardWrapper {
    padding-left: 8px !important;
    margin: 0 !important;
}

.iGor_favouriteCard {
    padding-top: 0 !important;
    padding-left: 10px !important;


    .iGoR_roomCard {
        margin: 15px 0 0 0 !important;
    }

    .iGor_cardBtn {
        width: 100% !important;
    }

    .iGor-CardLeft {
        width: 179px;
        height: 158px;

        .iGor_cardImg {
            width: 100% !important;
            height: 100% !important;
        }
    }
}

.iGor-latestOfferSection {
    // height: 510px;
    height: 75vh;
    overflow: hidden;
    overflow-y: scroll;

    .iGor-NoDataOffersSection {
        font-size: 20px;
        font-weight: 500;
        color: darkgray;
        margin: 100px 0 0 0px;
    }
}

.iGor-resultSection {
    height: 499px;
    overflow: hidden;
    overflow-y: scroll;
    margin-bottom: 1rem;

    .iGor-NoDataOffersSection {
        font-size: 20px;
        font-weight: 500;
        color: darkgray;
        margin: 100px 0 0 0px;
    }
}

.iGor_fliterSectionMb {
    background: #ffffff;
    border-radius: 50px;
    display: flex;
    justify-content: space-between;
    width: 305px;
    align-items: center;
    height: 45px;

    .iGor_PT-0 {
        padding: 0 !important;
    }

    .iGo__SearchIcon {
        color: #C4C4C4;
        margin-left: 23px;
        margin-right: 20px;
    }

    .IGor_FilterIcon {
        margin-right: 14px;
    }
}

.iGor_serR_arrow {
    background: #BFCCD9;
    width: 25px !important;
    height: 25px !important;
    border-radius: 10px;
    color: #ffffff;
    padding: 6px;
    margin: 0 10px;
}

.iGor_OurRecBtn {
    font-size: 14px !important;
    background: #E2EBF3 !important;
    border-radius: 50px !important;
    padding: 7px 10px 7px 25px !important;
    margin-right: 15px !important;
    height: 45px;
    width: 250px;
    justify-content: space-between !important;

    svg {
        margin-left: 5px;
        color: #73889D;
    }
}

.iGor_FlightDistance,
.iGor_AF-viewBy {
    border-radius: 15px;
    background-color: #F1F5F9;
    padding: 15px;
    justify-content: space-between;
    margin-bottom: 10px;

    .iGor_EastIcon {
        font-size: 17px;
    }

    .iGor_FDtitle {
        font-size: 12px;
        font-weight: 500;
    }

    .iGor_FDsub {
        font-size: 8px;

        span {
            color: #FF6B6B;
        }
    }
}

.iGoR-SearchRFilter {
    background-color: $whiteColor;
    border-radius: 15px;
    padding: 30px 30px 0;
    margin-top: 15px;



    .iGoR-SrFilterTopBtn {
        display: flex;
        justify-content: flex-end;

        button {
            font-size: 14px;
            height: 40px;
        }
    }

    .iGoR-SrFilterTop {
        .iGor-width-20 {
            position: relative;

        }

        .iGor-result_dates {
            width: 22vw;
        }

        .iGoR-Sr_line::after {
            content: '';
            position: absolute;
            width: 1px;
            height: 26px;
            top: 0;
            background: #C4C4C4;
            right: 25px;
        }

    }
}

.iGOrBtn__disable {
    background: #979797 !important;
    color: #fff !important;
}

.Igor_MapSectionOffers {
    overflow: hidden;
    position: relative;
    border-radius: 15px;
    margin-top: 22px;
    background-color: #e6e6e6;
    border: 1px solid $whiteColor;
    // height: 550px;
    height: 80vh;
    // width: 49vw;

    [role="dialog"] {
        padding: 0 !important;

        div {
            overflow: unset !important;
            background-color: transparent !important;
        }

        button {
            z-index: 2;
            opacity: 1 !important;

            span {
                background-color: #ffffff !important;
            }
        }

    }

    .gm-style-iw-chr {
        position: absolute;
        right: 0;
    }

    .iGor-rating {
        position: absolute;
        z-index: 1;
        bottom: 11%;
        left: 6%;

        .iGor_starIcon {
            font-size: 11px;
            color: #E26721;
            margin-right: 2px;
        }
    }

    .iGor-MarkerHotelCard {
        width: 221px;
        height: 133px;
        border-radius: 8px;
        z-index: 1000;
        overflow: hidden;
        cursor: pointer;

        &::before {
            content: "";
            position: absolute;
            height: 133px;
            background: #000;
            background: linear-gradient(90deg, #000, rgba(0, 0, 0, .3088585776) 50%);
            opacity: .8;
            z-index: 1;
            width: 221px;
            border-radius: 0px
        }

        img {
            width: 100%;
            height: 100%;
            border-radius: 5px;
            background-color: #979797;
        }

        h3 {
            position: absolute;
            color: white;
            font-size: 10px;
            letter-spacing: 0px;
            font-weight: bold;
            top: 85%;
            left: 7%;
            z-index: 2;
        }
    }
}

.iGor_ZoomableImage {
    overflow: hidden;
    position: relative;
    border-radius: 15px;
    margin-top: 10px;
    border: 6px solid $whiteColor;
    height: 550px;
    line-height: 12px;

    iframe {
        border: none !important;
    }

    .iGor_boxTop,
    .iGor_boxBottom {
        position: absolute;
        z-index: 1;
        width: 100%;
        color: $whiteColor;
    }

    .iGor_boxTop_location {
        display: flex;
        flex-direction: row;
        justify-content: center;
        word-spacing: 2px;
        align-items: center;
        font-size: 10px;
    }

    .iGor_boxTop_hotelName {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-size: 10px;
        word-spacing: 1px;
    }

    .iGor_boxTop {
        top: 15px;
        text-align: center;
    }

    .iGor_boxBottom {
        display: flex;
        justify-content: space-between;
        bottom: 35px;
        padding: 0 25px;
    }

    .iGor_zoomBtn {
        background: rgba(0, 0, 0, 50%);
        border-radius: 50px;
        color: $whiteColor;
        margin-right: 15px;
    }
}

.iGor-hotel_disable {
    opacity: 30%;
}

.iGoR_roomCard {

    background: $whiteColor;
    padding: 15px;
    margin: 15px 15px 15px 0;
    border-radius: 15px;

    .iGor__HotelName {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .iGor_BookNowSection {
        background: #F1F5F9;
        padding: 15px;
        border-radius: 15px;

        .iGor_BookPrice {
            color: #FF6B6B;

            span {
                color: #C4C4C4;
                font-size: 12px;
            }
        }
    }

    .iGor_BookNowBtn {
        width: 155px;
    }

    .iGoR_roomCardInner {
        @include flexbox(row, space-between, flex-start);
        height: 151px;
    }

    .iGor-CardLeft {
        width: 42%;
        height: 100%;
        position: relative;

        .iGor_cardBtnSection {
            position: absolute;
            top: 5%;
            // left: 4%;
            z-index: 1;
            width: 90%;

            .iGor_vrViewIcon {
                cursor: pointer;
            }

            .iGor_cardLeftBtn {
                background: rgba(0, 0, 0, 50%);
                // background: transparent;
                color: $whiteColor;
                width: 30px;
                height: 30px;

                svg {
                    font-size: 20px;
                }
            }
        }

        .iGor_cardImg {
            border-radius: 15px;
            width: 221px;
            height: 155px;
            object-fit: cover;
            background-color: #b7b4b2;
        }

    }

    .iGor-CardRight {
        width: 55%;
        height: 100%;

        .iGor_mainTitleFev {
            white-space: nowrap;
            overflow: hidden;
            width: 100%;
            text-overflow: ellipsis;
        }

        .iGor_Travel {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 11px 0 19px 0;

            img {
                margin-right: 3px;
            }
        }

        .iGor_villasCard {
            background-color: $bgGrayColor;
            padding: 15px;
            border-radius: 15px;
            margin: 15px 0;

            .iGor_ArrowRight {
                background: #FF6B6B;
                border-radius: 15px;
                font-size: 15px;
                color: $whiteColor;
                padding: 2px;
            }

            .iGor_villasTitle {
                font-weight: 600;
                display: flex;
                margin-right: 10px;

                span {
                    font-size: 8px;
                    color: #C4C4C4;
                }
            }
        }

        .iGor_CardBtnSection {
            @include flexbox(row, space-between, center);

            .iGor_cardPrice {
                @include flexbox(row, flex-start, center);
            }

            .iGor_cardBtn {
                width: 144px;
                height: 40px;
                font-size: 12px;
                font-weight: 600;
                line-height: 18px;
            }
        }

        .iGor-ratingSection {
            @include flexbox(row, flex-start, center);

            .iGor-rating {
                @include flexbox(row, flex-start, center);
                margin: 0 5px 0 9px;
            }

            .iGor_ratingNum {
                background: $primaryColor;
                width: 22px;
                height: 12px;
                font-size: 8px;
                color: $whiteColor;
                border-radius: 50px;
                text-align: center;
                margin-right: 5px;
            }

        }
    }

    .iGor_starIcon {
        color: #E26721;
        width: 8px;
    }

    .iGor_starIcon_not_rating {
        color: #83807e;
        width: 6px;
    }

    .iGor_cardBtn {
        font-size: 12px;

    }

    // .iGor_mb-View {
    //     position: absolute;
    //     margin-top: 10vh;
    // }

    .iGor_travel-until-mb {
        margin-top: 25px;
    }

    .iGor_subTitle {
        color: $secondaryColor;

    }

    .iGor_flex {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
    }
}

.iGor_typeBtn {
    font-size: 14px !important;
    font-weight: 400;
    background: #F1F5F9 !important;
    border-radius: 20px !important;
    padding: 6px 0px !important;
    margin-top: 27px !important;
    min-width: 130px;
    color: #000000 !important;
    font-family: 'Poppins', 'sans-serif';
}

.iGor_listPop {
    flex-direction: column;

    .MuiTypography-subtitle2,
    .MuiButtonBase-root {
        width: 100%;
        font-weight: 500;

    }


}


.IGor_FilterIcon {
    color: #E26721;
}

.iGor_AdvFilterInput {
    background: #F1F5F9;
    padding: 0 10px;
    border-radius: 50px;
    font-size: 14px;

    fieldset {
        border: none;
    }

    input {
        font-size: 14px;
    }
}

.iGor_flighthours {
    color: #FF6B6B;
    font-weight: 500;
    font-size: 12px;
    display: flex;
    justify-content: center;
    margin: 10px;

    img {
        margin-right: 10px;
    }
}

.iGOr_DropDown {
    background: #F1F5F9;
    padding: 7px 15px 0 15px;
    border-radius: 15px;
    margin-bottom: 10px;

    .MuiListItemButton-root,
    .MuiSvgIcon-root,
    .MuiButtonBase-root {
        padding: 0 !important;
    }

    .iGor_SortPrice {
        padding: 10px 0;
        border-bottom: 1px solid #E5E5E5;
        margin-bottom: 15px;
    }
}

.iGor_filterResetBtn {
    font-size: 12px !important;
    border-radius: 50px !important;
    color: #000000 !important;
    padding: 0 !important;
}

.iGor_filterBtnSection {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-around;
    background: #ffffff;
    height: 81px;
    border-radius: 15px 15px 0px 0px;
    box-shadow: 0px 0px 5px 0px #00000026;
    align-items: center;

    .iGor_filterResetBtn {
        font-size: 12px !important;
        border-radius: 50px !important;
        background: #C4C4C4;
        color: #ffffff !important;
        padding: 0 !important;

    }

    button {
        height: 40px;
        width: 158px;
    }
}

.iGor__filterPriceRange {
    h6 {
        font-size: 12px;
        font-weight: 600 !important;
    }
}

.iGor_FiltercheckBox {
    svg {
        fill: #FF6B6B;
    }
}

.iGor_FiltercheckText {
    margin-left: -10px;

    .MuiListItemText-primary {
        font-size: 14px;
        font-weight: 500;
    }
}

.iGor_PopularDesti {
    width: 100%;
    padding: 10px;

    li {
        padding-left: 0 !important;
    }
}

.iGoR_roomCardSkeleton {
    .iGoR_ImgSkeleton {
        width: 100%;
        height: 100%;
    }
}

.iGor_cardPrice {
    .iGor_price {
        margin-right: 3px;
    }

}

.iGor_room-Exceed {
    font-size: 0.7rem !important;
    color: red !important;
    display: flex;
    margin: 10px 0 10px 0 !important;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.iGor__latestImgBox {
    display: flex;
    height: calc(100vh - 200px);
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .iGor__latestLogoImg {
        margin-top: 20px;
        width: 300px;
        height: 200px;
    }
}

.iGoR_LatestOffers {

    .iGor_ZoomableImage {
        height: 80vh;
        margin-top: 20px;

        div {
            height: 80vh;
        }
    }
}

@media screen and (max-width:1024px) {
    .iGor_fliterMbMain {
        justify-content: center;
    }



    .iGoR_LatestOffers {
        padding: 0 !important;

        .iGor__mbHeight100 {
            height: 97vh !important;

        }

        .iGor__mbHeight40 {
            height: 42vh !important;
        }

        .iGor__mbHeight60 {
            height: 60vh !important;
        }

        .iGor__mbHeight5 {
            height: 55px !important;

            .iGor__LatestSwipe {
                // background: #FF6B6B;
                // color: #fff;
                transition: all ease-in-out 1s;
            }
        }

        .iGor-hotel_disable {
            opacity: 30%;
        }

        .iGoR_roomCard {
            .iGor-CardRight {
                position: relative;
            }

            .iGoR_roomCardInner {
                height: 110px;
            }
        }

        .iGor_CardBtnSection {
            position: absolute;
            bottom: 0;
        }

        .iGoR_roomCardSkeleton {
            width: 90vw !important;
        }

        .iGor_ZoomableImage {
            display: none;
        }

        .Igor_MapSectionOffers {
            width: 100%;
            height: 50vh;
            transition: height 1s ease-in-out;
            margin: 0;
            border-radius: 0;
        }

        .iGor-latestOfferBox {
            height: 50vh;
            position: fixed;
            z-index: 1;
            background: #fff;
            bottom: 0;
            border-radius: 30px 30px 0 0;
            left: 0;
            width: 100vw;
            transition: height 1s ease-in-out;
            overflow: hidden;

            .iGor__LatestSwipe {
                padding: 22px 0 14px 0;

                &::after {
                    content: '';
                    position: absolute;
                    width: 69px;
                    height: 5px;
                    background: #C4C4C4;
                    top: 10px;
                    left: 50%;
                    transform: translateX(-50%);
                    border-radius: 50px;
                }
            }

            .iGor-latestOfferSection {
                width: 100vw;
                display: flex;
                flex-wrap: wrap;
                height: 80%;

                .iGoR_roomCard {
                    margin-bottom: 14px;
                    padding: 0 10px;
                    margin-right: 0;
                }

                .iGor-CardRight {
                    margin: 5px 0;
                }

                .iGor_Travel {
                    display: flex;
                    margin: 0;
                }

                .iGor-ratingSection {
                    margin-left: 3px;
                }
            }

            .iGor__LatestFilter {
                position: absolute;
                z-index: 5;
                top: 18px;
                right: 22px;

                .iGo__filter {
                    border: 2px solid #000000;
                    width: 30px;
                    height: 30px;
                    border-radius: 50px;
                    padding: 5px;
                    display: none;
                }
            }

            .iGo__titltMb {
                font-size: 14px !important;
                font-weight: 600 !important;
                text-align: center;
            }
        }
    }

    .iGor-resultSection {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-content: flex-start;
    }

    .iGoR_SearchResult {

        .iGor-No_Result_messages {
            top: 40%;

            .iGor-No_Result_image {
                height: 40%;
            }
        }

        // //
        // .iGor-No_Result_msg {
        //     font-size: 15px !important;
        //     display: flex !important;
        //     justify-content: center !important;
        //     align-items: center;
        //     height: 50vh;
        //     font-weight: 600;
        // }

        .iGoR_roomCard {
            position: relative;
            width: 49%;

            .iGoR_roomCardInner {
                height: 110px;
            }



            .iGor_CardBtnSection {
                position: absolute;
                bottom: 10px;
            }

            .iGor__HotelName {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 10px;
                margin-left: 2px;
            }

            .iGor_subTitle,
            .iGor_Location {
                font-size: 8px;
            }

            .iGor_Location {
                margin-left: 2px;
            }

            .iGor_cardPrice {
                .iGor_price {
                    font-size: 12px;
                    margin-right: 0px;
                }
            }
        }
    }

    .iGor__SearchSwipe {
        .iGor__inputflied {
            background: #F1F5F9;
            border-radius: 50px;
            display: flex;
            align-items: center;
            height: 45px;
            margin-bottom: 8px;

            .iGor__input {
                font-size: 12px;
            }

            .iGor__icon {
                font-size: 16px;
                color: #FF6B6B;
                margin: 0 15px 0 25px;
            }
        }

        .MuiPaper-root {
            border-radius: 20px 20px 0 0;
        }

        .iGor_Travel {
            margin-top: 0;
        }
    }

}

@media screen and (max-width:767px) {
    .iGor_favouriteCard {
        padding-left: 0px !important;
    }

    .iGor_favCardWrapper {
        width: 100vw;
        margin-left: 5px !important;

        .iGoR_roomCardInner {
            height: 135px;
        }

        .iGoR_roomCard {
            margin: 15px 10px 0 0 !important;

            .iGor-CardLeft {
                width: 45%;
            }

            .iGor-CardRight {
                width: 48%;
            }
        }
    }

    .iGoR_SearchResult {

        //
        .iGor-No_Result_msg {
            font-size: 15px !important;
            display: flex !important;
            justify-content: center !important;
            align-items: center;
            height: 50vh;
            font-weight: 600;
        }

        .iGor-hotel_disable {
            opacity: 30%;
        }

        .iGoR_roomCard {
            position: relative;
            height: 130px;
            width: 100%;
        }
    }
}