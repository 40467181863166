.iGoRoom_NotIfrome {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100vw;
  min-height: 100vh;
  max-height: 100%;
  position: absolute;
}

#landingPageIframe {
  position: absolute;
  width: 100%;
  height: 100vh;
  z-index: -1;
  border: none !important;
}

// .iGoRoom_LayoutMain {
//   height: 100vh;
// }

.container {
  position: relative;
}

.content {
  transition: all 0.5s;
  height: 12.5rem;
  width: 100%;
  background-color: lightblue;
  position: absolute;
  top: 0;
  bottom: 0;
}

.visible {
  transform: translateY(0%);
}

.hidden {
  transform: translateY(100%);
}

.iGor_IframeCity {
  display: flex;
  margin-top: -20px !important;
  margin-bottom: 5px !important;
  margin-left: -5px !important;
}

.IGor_Mb-Destination {
  margin-top: 10px;

  button {
    padding: 3px;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      svg {
        padding-left: 0.625rem !important;
      }
    }
  }

}

//facebook data deletion page
.igo_facbookContent {
  padding: 2rem;
  border-radius: 15px;
  margin: 1rem 0;
  background: #ffffff;

  ul {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;

    li {
      padding: 5px 0 0 0;
    }
  }

  body {
    background: #ffffff !important;
  }
}

.iGor__pageslink {
  padding: 2rem;
  border-radius: 15px;
  margin: 1rem 0;
  background: #ffffff;
 

  body {
    background: #ffffff !important;
  }

  h3 {
    margin: 5px 0;
  }

  p,
  ol {
    font-size: 14px;
    text-align: justify;
  }

  li[id] {
    h5 {
      margin: 0.625rem 0;
    }

    ol {
      padding-left: 1rem;
    }
  }

}

.iGor__TermAndcon {
  position: sticky;
  top: 20px;
  li {
    list-style: none;

    a {
      text-decoration: none;
      font-size: 12px;
      font-weight: 600;
      color: #000;
      &:active{
        color: #FF6B6B !important;
      }
      &:hover{
        color: #FF6B6B !important;
      }
    }
  }
}

.iGOr__footer-Fix {
  position: fixed;
  bottom: 0;
  width: 100%;
}

@media screen and (max-width:1024px) {
  .IGor_Mb-Destination {

    button {
      margin-top: -15px;
    }
  }
}