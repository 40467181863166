.iGor__side-error {
    width: 100%;
    height: 100vh;
}

.iGor__404 {
    font-size: 7.5em !important;
    font-weight: bold !important;
}

.iGor__bg-error {
    background: rgb(230, 105, 134);
    background: linear-gradient(346deg, rgba(230, 105, 134, 1) 0%, rgba(238, 63, 164, 1) 35%, rgba(228, 93, 134, 1) 100%);
}

.iGor__pageNotRight {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: 20px;
    align-items: center;
}

.iGor__loadingIcon {
    color: rgb(230, 105, 134);
    font-size: 3rem !important;
    margin-bottom: 1rem;
}

.iGor__errorText {
    font-size: 0.9rem !important;
    font-weight: 500 !important;
    font-family: 'poppins' !important;
}

.iGorClose_alert_button {
    margin-top: 20px;
    width: 75px;
    height: 30px;
    border-radius: 9px;
    border: 1px transparent;
    color: white;
    background: linear-gradient(180deg, #FF6B6B 0%, #EE5253 100%);
    cursor: pointer;
    font-size: 0.8rem;
    font-weight: 500;
    letter-spacing: 1.3px;
}
.iGor__NotFoundimg{
    width: 100%;
    height: 100%;
}
.iGor__loadingAnimation {
    animation: setAnimation 3s linear infinite;;
    @keyframes setAnimation {
        from {
            rotate: 0deg;
        }
        to {
            rotate: 365deg;
        }
    }
}