.iGor-Text-center {
    text-align: center !important;
}

.iGor_setFlex {
    display: flex;
}

.iGor_Text-decoration {
    @include textDecorationforLinks(none, pointer, black)
}

.iGor_txt_nowrap {
    white-space: nowrap;
}

.iGor_setRelative {
    position: relative !important;
}

.iGor_justifyBetween {
    justify-content: space-between;
}

.iGor_justifyCenter {
    justify-content: center;
}

.iGor_alignCenter {
    align-items: center;
}

.iGor_pointer {
    cursor: pointer !important;
}

.iGor-M-0 {
    margin: 0;
}

.iGor-ML-10 {
    margin-left: 0.625rem;
}
// .iGor-marginX {
//     margin: 0 0.625rem;
// }
.iGor-MB-15 {
    margin-bottom: 0.9375rem;
}

.iGor_PT-0 {
    padding-top: 0 !important;
}

.iGor-PR-20 {
    padding-left: 1.25rem;
}

.iGor-Checkbox {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    
    span {
        margin: 0.326rem 0 !important;
        color: #FF6B6B !important;
        svg{
            font-size: 1.2rem;
        }
    }
}

.iGor-width-100 {
    width: 100%;
}

.iGor-width-90 {
    width: 90%;
}

.iGor-width-80 {
    width: 80%;
}

.iGor-width-70 {
    width: 70%;
}

.iGor-width-60 {
    width: 60%;
}

.iGor-width-50 {
    width: 50%;
}

.iGor-width-40 {
    width: 40%;
}

.iGor-width-30 {
    width: 30%;
}

.iGor-width-25 {
    width: 25%;
}

.iGor-width-20 {
    width: 20%;
}

.iGor-width-15 {
    width: 15%;
}

.iGor-width-10 {
    width: 10%;
}

.iGor_bgWhite {
    background: #ffffff !important;
}

.iGOr_BorderRadius15 {
    border-radius: 15px;
}


.iGor_silder {
    color: #FF6B6B !important;
    height: 2px !important;

    .MuiSlider-thumb {
        width: 8px;
        height: 8px;
        transform: rotate(45deg) !important;
        background: #FF6B6B;
        border-radius: 0% !important;
        top: 31% !important;
    }
}