.iGor_CurrenciBtn {
    position: absolute !important;
    top: 3%;
    right: 1%;
    font-size: 0.625rem !important;
    color: #ffffff !important;
    font-weight: 600;

    .iGor_Curr_DollerIcon {
        color: #0AA83E;
        font-size: 1rem;
        margin-right: 0.25rem;
    }

    .iGor_Curr_Arrow {
        font-size: 1.1875rem;
    }
}

.iGor_currColor {
    color: #000000 !important;
}

.iGoR_curr_Modal {
    min-width: 40rem;
    padding: 1.5rem;

    .modal-close {
        top: 20px;
        right: 20px;
        font-size: 24px;
    }

    .iGor_CurrSearch {
        display: flex;
        align-items: center;
        background: #F0F2F5;
        border-radius: 1rem;
        border: 0.0625rem solid #D0D6E3;
        // box-shadow: 0 0 0.3125rem 0rem #0AA83E;
        margin: 0.625rem 0;


        .MuiInputBase-root {
            border-radius: 1rem;
        }

        input {
            padding: 10px 20px !important;
        }

        .iGoR_autoCom {
            .MuiOutlinedInput-root {
                padding: 0 1.875rem 0 0 !important;
            }

            background: #F0F2F5;
            width: 100%;

            input {
                padding-left: 0 !important;
            }
        }

        .iGor_CurrSearchIcon {
            color: #C4C4C4;
            margin: 5px 0 5px 0.625rem;
        }
    }

    .iGor_Curr-subTitle {
        color: #C4C4C4;
        margin-left: 0.9375rem !important;
    }

    .iGor_Curr_DollerVal {
        // color: #757575;
        display: block;
    }

    .iGor_CurrSelect {
        background: #F0F2F5;
    }

    .iGor_CurrCard {
        display: flex;
        border-radius: 0.625rem;
        padding: 0.625rem 0.9375rem;
        align-items: center;
        justify-content: flex-start;
        width: 12.5rem;
        cursor: pointer;


        .iGor_Curr_DollerIcon {
            color: #0AA83E;
            font-size: 1.625rem;
            padding: 0 !important;
            width: 20px;
            height: 20px;
            margin-right: 15px;
        }

        .iGor_Check {
            color: #0AA83E;
            font-size: 1.25rem;
        }
    }


    .iGor_Curr-Scroll {
        overflow: hidden;
        overflow-y: auto;
        max-height: 130px;
    }
}
.currency-flag {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
    filter: drop-shadow(0 0 1px rgba(0, 0, 0, .2))
}

@media screen and (max-width:1024px) {
    .iGoR_curr_Modal {
        min-width: 100%;

        .iGor_Curr-Scroll {
            max-height: 55vh;
            padding-bottom: 50px;
        }
    }
}