.iGor__ProDetailsView {
    position: relative;
    height: 100vh;
    z-index: 1;

    .iGor__proTitle {
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
    }

    .iGor__proResorts {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        margin-right: 5px;
    }

    .iGor__proRStar {
        width: 6px;
        height: 6.37px;
        color: #E26721;
        margin: 0 2px;
    }

    .iGor_starIcon_not_rating {
        width: 6px;
        height: 6.37px;
        color: #83807e;
        margin: 0 2px;
    }

    .iGor__proReviwe {
        width: 22px;
        height: 12px;
        border-radius: 100px;
        background: linear-gradient(180deg, #FF6B6B 0%, #EE5253 100%);
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 8px;
        margin: 0 4px;
    }

    .iGor__proReviweBox {
        align-items: center;
    }

    .iGor__proReviewTitle {
        font-size: 12px;
        font-weight: 400;
        color: #C4C4C4;

    }

    .iGor__proPriceIcon {
        font-size: 12px;
        font-weight: 600;
        line-height: 18px;
        margin: 0 4px;
    }

    .iGor__proPrice {
        font-size: 16px;
        font-weight: 600;
        line-height: 18px;
    }

    .iGor__prPriceInfo {
        font-size: 8px;
        font-weight: 400;
        line-height: 18px;
        color: #C4C4C4;
    }

    .iGor_proInfoIcon {
        width: 20px;
        margin-right: 10px;
        cursor: pointer;
    }

    .iGor_proPrice_Detls {
        margin: 20px 0 15px 0;
        align-items: center;
    }

    .iGor__proBackBtnBox {
        position: fixed;
        top: 56px;
        left: 86px;

        .iGor__proBackBtn {
            background: #00000080;
            color: #fff;
            width: 106px;
            height: 35px;

            border-radius: 15px;
            font-size: 14px !important;
            text-transform: capitalize !important;
            font-weight: 600;
            justify-content: space-evenly;
            cursor: pointer;

            svg {
                font-size: 17px;
            }
        }

    }

    .iGor__ProDetailsViewCard {
        position: fixed;
        bottom: 5%;
        left: 86px;
        width: 336px;
        min-height: 157px;
        border-radius: 10px;
        background: #fff;
        padding: 15px 20px 5px 20px;

        .iGor__ProDContentBox {
            padding: 15px 0;
            border-top: 1px solid #C4C4C4;
            text-align: justify;
        }

        .iGor_proBookNow {
            width: 105px;
            height: 30px;
            font-size: 10px;
        }

        .iGor_proBookNow_disable {
            width: 105px;
            height: 30px;
            font-size: 10px;
            color: #fff !important;
            background: #979797 !important;
        }

        .iGor__proMoreLess {

            font-size: 10px;
            font-weight: 600;
            line-height: 18px;
        }

        .iGor__ProDetailsContent {
            font-size: 8px;
            font-weight: 400;
            line-height: 12px;
            text-align: justified;

        }

        .iGor__ProDetailsMain {
            font-size: 10px;
            font-weight: 600;
            line-height: 16px;
            margin: 0 5px;
        }

        .iGor__ProDetailsSub {
            font-size: 10px;
            font-weight: 400;
            line-height: 16px;
        }

        .iGor__ProDRoom {
            margin-bottom: 25px;
        }
    }

    .iGor__proMoreLessBox {
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;

        .iGor__proML_Icon {
            margin-top: -5px;
        }
    }
}