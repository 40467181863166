.iGOr_Desk-D-none {
    display: none;
}

@media screen and (min-width:1320px) {
    .MuiContainer-root {
        max-width: 1280px !important;
    }

    .IgoRoomIcon {
        cursor: pointer;
    }
}

@media screen and (max-width:1024px) {

    html {
        font-size: 13px;
    }

    body,
    html {
        overflow-x: hidden !important;
        -ms-scroll-chaining: none !important;
        overscroll-behavior-x: none !important;
    }


    .IgoRoomLogo {
        text-align: center;
    }

    .iGOr_Desk-D-none {
        display: block;
    }

    .iGOr_mb-D-none {
        display: none !important;
    }

    .iGor_MB-social {
        color: #BFCCD9;
        margin: 21px 5px 0 5px;
    }

    .iGOr_MB-primaryBtn {
        // padding: 20px !important;
        border-radius: 50px !important;
        height: 45px !important;
    }

    .iGor-action_loader {
        height: 50%;

        svg {
            font-size: 1.8rem;
        }
    }

    .iGor_Modal {
        width: 100% !important;
        height: 100%;
        border-radius: 0 !important;

        .iGor__registerSubTitle {
            text-align: center;
        }
    }

    .iGoR_NeedHelpBtn {
        border-radius: 5px !important;
        // padding: 10px 20px !important;
        width: 105px !important;
        height: 30px;
        // margin-bottom: 10px !important;
    }

    .iGor_curr-MB {
        position: static !important;
        background: #E5E5E5 !important;
        border-radius: 5px !important;
        // padding: 10px 20px !important;
        width: 100px !important;
        margin-right: 10px !important;
        // margin-bottom: 10px !important;
        font-size: 10px !important;
        height: 30px;
        width: 105px;

        .MuiSvgIcon-root {
            margin: 0 !important;
        }
    }

    .iGor_DestAutoTitBtn {
        margin: 0 auto;
        width: 315px;
        height: 45px;
    }

    // Search results
    .iGoR_roomCard {
        margin: 0;
        margin-bottom: 10px;
        padding: 10px;

        .iGor_cardImg {
            width: 100% !important;
            height: 100% !important;
        }
    }

    .iGor__filterPriceRange {
        text-align: center;
    }

    // .iGOr_DropDown.iGor_Modal {
    //     position: fixed !important;
    //     top: 50% !important;
    //     left: 50% !important;
    //     -webkit-transform: translate(-50%, -50%) !important;
    //     -moz-transform: translate(-50%, -50%) !important;
    //     -ms-transform: translate(-50%, -50%) !important;
    //     transform: translate(-50%, -50%) !important;
    // }


    .iGor__AdvfilterSection {
        padding-top: 30px;
        height: 85%;
        overflow-y: auto;
        overflow-x: hidden;
        position: relative;
        z-index: 333;
    }


    .iGOr_DropDown {
        // .iGor__AdvanDrop {
        //     height: 25vh;
        //     overflow-y: auto;
        // }

        .iGor_priceRange {
            .MuiSlider-root {
                padding: 13px 0;
                width: 93% !important;
            }
        }

        .iGor_checkBoxDrop {
            .MuiListItemSecondaryAction-root {
                right: 12px !important;
            }

            .MuiTypography-root {
                font-family: Poppins;
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: 0em;
                text-align: left;

            }
        }

        .MuiListItem-root {
            margin-bottom: 11px;
        }

        .iGOr_DropDown {
            min-height: 40px;
            padding: 10px 13px 10px 20px;
            border-radius: 10px;
        }

        .priceRange {
            width: 100%;
        }
    }

    .iGor__AdvClose {
        left: 25px;
        right: auto !important;
        top: auto !important;
        width: 25px !important;
        height: 25px;

        button {
            width: 25px;
            height: 25px !important;
            border-radius: 10px;

            // background: #BFCCD9;
            svg {
                font-size: 32px;
                color: #757575;
            }
        }

    }

    .iGor-resultSection {
        height: calc(100vh - 201px);
    }

    .iGor_CustomTabs {
        background: #ffffff;
        padding: 5px;
        display: flex;
        justify-content: space-between;
        width: 100%;
        max-width: 600px;
        margin: 0 auto;
        margin-top: 0;
        margin-bottom: 10px;
        text-transform: none;

        .iGor_btnTab {
            padding: 0.25rem 0.625rem;
            font-size: 10px;
            height: 30px;
            color: #c4c4c4;
        }
    }


    .iGor__SecondMenuMB {
        padding: 0 !important;

        .IgoRoomLogo {
            padding-top: 0 !important;
        }

        img {
            display: none;
        }

        .iGor_m-menuBtn {
            right: 90% !important;
            top: 90% !important;
            margin-top: 20px;

            .iGo_m-menuIcon {
                color: #000000;
            }
        }
    }

    .iGor__CardForCenter {
        display: flex;
        align-items: stretch;
        justify-content: center;
        align-content: center;
        height: 75vh;
    }

}