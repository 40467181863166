.iGor_Ex-Destination {
    display: flex;
    padding: 0.9375rem 1.0625rem;
    max-width: 713px;
    cursor: pointer;
    margin: 0 !important;
    height: 63px;
}

.iGo__ExploreSearch {
    height: 63px;
    width: 100%;
}

.IGor_ExploerTitle {
    font-size: 1.75rem !important;
    color: #ffffff;
    font-weight: 600 !important;
}

.IGor_ExploerSub {
    font-size: 1rem !important;
    color: #ffffff;
}

.iGo_Explore-close {
    position: absolute;
    top: 5%;
    right: 0%;

    .close-button {
        color: #fff;
        border: 0.0625rem solid #ffffff;
        border-radius: 25px;
        font-size: 12px;
        padding: 0.3125rem 0.6875rem;
        width: 111px;
        height: 40px;

        svg {
            font-size: 17px;
            margin-right: 0.375rem;
        }
    }
}

.iGor_card-explorer {
    position: relative;
    max-width: 14.6875rem !important;
    height: 20.8125rem;
    width: 100%;
    overflow: hidden;
    margin-top: 0.0625rem;
    cursor: pointer;

    &:hover .iGor_image {
        max-height: 5.625rem;
    }

    .iGor_image {
        position: relative;
        overflow: hidden;
        border-radius: 0.9375rem;
        max-height: 100%;
        height: 100%;
        transition: max-height .5s cubic-bezier(.16, 1, .3, 1);

        .img {
            transform: translateY(0);
            transition: transform .5s cubic-bezier(.16, 1, .3, 1);
            height: 100%;

            img {
                width: 100% !important;
                min-height: 20.625rem !important;
                -o-object-fit: cover;
                object-fit: cover;
            }
        }
    }

    .iGor_text {
        position: absolute;
        bottom: 1.125rem;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        width: 100%;

        h3 {
            color: #fff;
            text-shadow: 0 0 0.25rem #000;
            margin-bottom: 0;
            line-height: 1.5rem;
            font-size: 16px;
        }
    }

    .iGor_body {
        max-height: 0;
        position: relative;
        padding: 1.4375rem 1.0625rem 1.125rem;
        -webkit-transition: max-height .5s cubic-bezier(.16, 1, .3, 1);
        transition: max-height .5s cubic-bezier(.16, 1, .3, 1);

        .iGor_title {
            font-weight: 600;
            font-size: 0.75rem;
            color: #fff;
            margin-bottom: 0.25rem;
        }

        .iGor_description {
            height: 9.0625rem;
            overflow: hidden;
            text-overflow: clip;
            color: #fff;
        }
    }

    &:hover .body {
        max-height: 100%;
    }
}

.iGo__ExploreSilder {
    margin-top: 2rem;

    .slick-prev,
    .slick-next {
        top: 115% !important;
    }

    .slick-prev {
        left: 0% !important;
    }

    .slick-next {
        left: 3% !important;
    }
}

.favTripCard {
    height: 262px;
    border-radius: 15px;
    background-color: $whiteColor;
    @include flexbox($direction: column);
    padding: 13px;
    margin-top: 14px;
    margin-left: 10px;

    .favTrip_ImgSctionRight {
        @include flexbox($direction: column);


    }

    .favTrip_imgSection {
        @include flexbox();

        .favTrip_leftImg,
        .favTrip_rightImg {
            margin: 2px;
            cursor: pointer;
        }
    }

    .favTrip_leftImg {
        width: 214px;
        height: 194px;
        // border-radius: 10px 0px 0px 10px;
        border-radius: 10px;

    }

    .favTrip_rightImg {
        width: 120px;
        height: 96px;
        border-radius: 0px 10px 10px 0px;
    }

    .favTrip_title {
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        color: #000;
        margin-top: 8px;
    }

    .favTrip_date {
        font-size: 10px;
        font-weight: 400;
        line-height: 18px;
        color: #757575;

    }
}

.iGorFav_ArticalCard {
    display: flex;
    flex-wrap: wrap;
}

.articalCard {
    width: 220px;
    height: 261px;
    padding: 15px 20px;
    border-radius: 15px;
    background: $whiteColor;
    margin-top: 14px;
    margin-left: 10px;
    cursor: pointer;

    .artical_img {
        width: 179px;
        height: 171px;
        border-radius: 10px;
    }

    .artcal_title {
        font-size: 12px;
        font-weight: 600;
        line-height: 14px;
        margin-bottom: 5px;
    }

    .artcal_subtitle {
        font-size: 8px;
        font-style: italic;
        font-weight: 300;
        line-height: 14px;
        color: #757575;
    }
}

.iGor_WhishListTab {
    background: #ffffff !important;
    color: #000 !important;
    font-size: 10px !important;
    margin-right: 8px !important;
    text-transform: capitalize !important;
    border-radius: 5px !important;
}

.iGor_WhishListTab.iGor_tabActive {
    background: #757575 !important;
    color: #ffffff !important;
}

.iGor__ExpMoreSidelerBox {
    .iGor__ExpMoreSidelerTitle {
        margin-bottom: 14px;
    }

    .iGOr_ExBtnFilterWrapper {
        margin-bottom: 16px;
        .iGor__ExBtnFilterActive{
            background: #FF6B6B !important;
            color: #ffffff !important;
        }
        .iGor__ExBtnFilter{
            margin-bottom: 4px;
            min-width: auto;
            padding: 6px 10px;
        }
    }

}

@media screen and (max-width:1024px) {
    .iGo_Explore-Model {
        padding: 0 !important;
    }

    .iGo__ExploreSilder {
        width: 100vw;

        .slick-slide {
            padding: 0 5px;
        }

        .iGor_body {
            padding: 10px 0 0 0;
            text-align: center;
        }

        .iGor__ExploreBtn {
            padding: 0;
            height: 25px;
            margin-top: 16px;
        }
    }

    .iGo__ExploreSilder .slick-prev {
        left: 35% !important;
        font-size: 35px;
    }

    .iGo__ExploreSilder .slick-next {
        left: 50% !important;
        font-size: 35px;
    }

    .iGo_Explore-close {
        top: -20%;
        left: 50%;
        transform: translate(-50%, 10px);

    }

    .iGo__Exp-closeSection {
        text-align: center;

        .close-button {
            padding: 0;
            border: none;

            svg {
                font-size: 35px;
                margin: 0;
            }
        }
    }

    .iGorFav_ArticalCard {
        flex-wrap: nowrap !important;
        overflow-x: auto;
        width: 95vw;

        .articalCard {
            margin-left: 0 !important;
            margin-right: 10px !important;
        }
    }

    .favTripCard {
        flex-direction: row;
        height: 165px;
        width: 100% !important;

        .favTrip_skeletonContent,
        .favTrip_imgSection {
            width: 50%;
            margin-right: 1rem;

            .favTrip_leftImg {
                width: 100%;
                height: 100%;
            }
        }

        .favTrip_date {
            padding-top: 90px;
            font-size: 10px;
            font-weight: 400;
            line-height: 18px;
            color: #757575;

            span {
                font-weight: 600;
                color: #000;
            }
        }
    }

    .iGor__ExpMoreSidelerBox {
        .iGor__ExpMoreSidelerTitle {
            margin-bottom: 11px;
        }
    }
}