@mixin flexbox($direction: row, $justify-content: normal, $align-items: stretch) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify-content;
  align-items: $align-items;
}

@mixin textDecorationforLinks ($text-decoration, $cursor, $color) {
  text-decoration: $text-decoration;
  cursor: $cursor;
  color: $color;
}