.iGoRoom_login_pop {
    background: $bgGrayColor !important;
    padding: 0.9375rem;
    .iGor__loginBtnSocial{
        .MuiButtonBase-root{
            padding: 0  5px  0 0 !important ; 
        }
    }
}

.iGoRoomIcon {
    margin-right: 0.625rem;
    align-items: center !important;
}

.iGoRoomNav {
    font-size: 0.75rem !important;
    font-weight: 600 !important;
    color: $whiteColor !important;
    border-radius: 10px !important;
}

.iGoRoomHeader {
    .iGoRoomNav,.iGoRoomIcon {
        color: $blackColor !important;
    }
}
.iGor_userLoginProBtn {
    border: 1px solid #C6C6C6 !important;
}


