.iGor_DestAutoTitBtn {
    display: flex;
    padding: 0.9375rem 1.0625rem;
    width: 23.3125rem;
    margin: 0;
    cursor: pointer;

    .iGor_destSearchTitle {
        font-size: 0.875rem;
        color: #C4C4C4;
        margin-left: 0.3125rem
    }

    input {
        padding-left: 12px;
    }
}

.iGor__DesSearchBar {
    position: fixed;
    bottom: 10px;
    width: 100%;

    .iGoR_NeedHelpBtn {
        margin-top: 8px;
    }

    // z-Index: 2;
    &::after {
        content: "";
        width: 100vw;
        background: linear-gradient(180deg, transparent, #000);
        position: absolute;
        top: 0;
        height: 305px;
        z-index: -1;
        margin-top: -100px;
    }
}

.iGor__DestImAlone {
    .MuiPaper-root {
        padding: 0 !important;
    }

    .iGor_Desti_header {
        padding: 20px 20px 9px 20px;
        cursor: pointer;
    }

    .iGor__ImAloneBox {
        padding: 0px 20px 20px 20px;

    }

    .iGor__ImConentBox {
        position: relative;
        padding: 45px;
        margin-bottom: 15px
    }
}

.iGor__ImAloneBtn {
    height: 48px !important;
    width: 71px !important;

    .MuiSwitch-switchBase {
        top: 3px !important;
    }

    .MuiSwitch-track {
        border-radius: 100px !important;
        background-color: #D8D8D8;
    }

    .MuiSwitch-thumb {
        margin: 2px 0px 0 6px;
    }

    .Mui-checked+.MuiSwitch-track {
        background-color: linear-gradient(100deg, #EE5253, #FF6B6B) !important;
    }
}

.iGor__roomAddSeciton {
    .rdrMonthsVertical {
        margin: 0 -10px;
    }
}

.iGor_DestiListSection {
    height: 200px;
    overflow: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px #fff;
        background: #ffffff !important;
    }

    // &::-webkit-scrollbar {
    //     height: 52px !important;
    //     width: 2px !important;
    // }


    &::-webkit-scrollbar-thumb {
        background: #D9D9D9 !important;
    }


    .Igo_Nomatch_destinations {
        padding: 30px 0 0 10px;
        font-size: 14px;
        color: #a8b1b9;
    }

    li {
        margin: 0 4px 10px 0px;
        padding-left: 5px;

        &:hover {
            background-color: #F1F5F9;
            border-radius: 50px;
        }
    }

    img {
        margin-right: 12px;
        margin-left: 10px;
    }
}

.iGor_d_roomBtnDel {
    color: #f20707;
    width: 20px;
    margin-top: 25px;
    cursor: pointer;
}

.iGor_DestAutoMain {
    padding-bottom: 0.3125rem;
    width: 23.3125rem;

    .iGor__RoomTitle {
        margin-top: 20px;
    }

    .iGor_d_roomBtnSectiom {
        max-height: 31vh;
        overflow: hidden;
        overflow-y: auto;
        padding: 10px;

    }

    .MuiPaper-root {
        border-radius: 0.9375rem;

        .iGor_room-Exceed {
            font-size: 0.7rem !important;
            color: red !important;
            display: flex;
            margin: 10px 0 10px 0;
            top: 45vh;
            /* position: absolute; */
            justify-content: center;
            align-items: center;
            width: 100%;
        }

        .iGor_room-Exceed_mb {
            font-size: 0.8rem;
            color: red;
            display: flex;
            position: relative;
            justify-content: center;
            align-items: center;
            width: 100%;
            margin-top: 30px;
        }
    }

    .aloneIcon {
        position: absolute;
        top: -11%;
        left: 20%;
    }

    .igoRoomBtn {
        max-width: 20.0625rem;
    }

    .iGor_Desti_border {
        &:after {
            content: '';
            width: 112%;
            height: 0.5px;
            background: #C4C4C4;
            position: absolute;
            left: -20px;
            bottom: -10px;
        }

    }


    .iGor_Desti_header {
        display: flex;
        align-items: flex-end;
        margin-bottom: 15px;
        position: relative;
        cursor: pointer;

        .iGor_item {
            width: 29%;
            text-align: left;
        }

        .iGor_sub {
            font-size: 0.625rem;
            color: $primaryColor;
            font-weight: 600;
        }

        .iGor_title {
            font-size: 0.875rem;
            white-space: nowrap;
            overflow: hidden !important;
            text-overflow: ellipsis;
        }

        .iGor_rightIcon {
            width: 0.9375rem;
            color: $primaryColor;
            margin: 5px 15px;
        }

        .iGor_rightIcon2 {
            margin: 5px 10px 5px 18px;
        }
    }

    .iGor_DestiTitle {
        color: $primaryColor;
        padding: 0;
        margin-left: 21px;
        margin-bottom: 10px;
    }

    .iGor_DestiList {
        padding: 0;

        li {
            font-size: 0.875rem;
            border-radius: 0.9375rem;
            width: 100% !important;

            img {
                margin-right: 0.875rem;
            }

        }
    }
}



.iGor_IframeTitle {
    color: #ffffff;
    cursor: pointer;
}

.iGor_IframeCity {
    color: #ffffff;
}

.iGOr_DestiMB {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #F1F5F9;
    display: flex;
    padding: 20px 0;
    opacity: 1 !important;
    z-index: 2;
    transition: opacity 0.6s linear;
    flex-direction: column;
    .iGor_DestAutoMain,
    .iGor_DestAutoTitBtn {
        visibility: visible !important;
    }


    .iGor_DestiMb_arrow {
        background: #BFCCD9;
        width: 25px;
        height: 25px;
        border-radius: 10px;
        color: #ffffff;
        padding: 4px;
        margin: 10px 14px 10px 25px;

        svg {
            font-size: 15px;
        }
    }



    .iGor_DestAutoMain {
        height: 100%;
        width: 100%;

        .MuiPaper-root {
            background: #F1F5F9;
            box-shadow: none;

            .iGor_mb_Btn {
                position: fixed;
                bottom: 15%;
                left: 50%;
                width: 100%;
                max-width: 330px;
                font-size: 14px;
                transform: translate(-50%, 10px);
            }

            .iGor_mb_BtnAddSection {
                flex-direction: column !important;
            }

            .iGor_mb_BtnAdd {
                width: 275px;
                margin: 1rem auto;
                position: relative;
                // left: 50%;
                // transform: translate(-50%, 10px);
            }
        }

        .iGoRoom_CountInput {
            background: #ffffff;
        }

        .rdrCalendarWrapper {
            background: #F1F5F9;

        }

        .rdrMonth {
            font-size: 0.875rem !important;
        }
    }

    .iGor_Desti_header {
        display: none;
        // cursor: pointer;
    }

    .iGor__ImAloneBox {
        position: relative;
    }
}

.iGor_DropDownItem {
    li {
        font-size: 0.875rem;
        border-radius: 0.9375rem;
    }

}

.iGor_NightCount {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    align-items: center;
    width: 100%;
}

.iGor_animation_1 {
    padding: 20px 5px 20px 9px;
}

.iGor_animation_3 {
    .iGor_DatesBox {
        width: 98px !important;

        .iGor_title {
            overflow: visible !important;
        }
    }
}

.iGor_animation_2 {
    padding: 20px;
    height: 480px;

    .iGoRoom_CountInput {
        min-width: 194px !important;
    }
}

.iGor_verSion {
    display: flex;
    justify-content: center;
    font-size: 0.6rem !important;
    font-weight: 600;
    color: whitesmoke;
}

.iGor__footerBox {
    display: flex;
    align-items: center;
    margin-top: 44px;
    margin-bottom: 10px;

    .iGOr_mb-footer-link {
        @include textDecorationforLinks(none, cursor, white)
    }

    .iGor_footer-link {
        font-size: 0.75rem !important;
        color: #ffffff;
        font-weight: 600 !important;
        margin-right: 10px;
    }

    a {
        color: #ffffff !important;
        text-decoration: underline;
        position: relative;
        font-size: 0.75rem;
        margin-right: 10px;

        &:last-child {
            margin: 0;
        }

        &:before {
            content: '';
            width: 2px;
            height: 2px;
            background: #fff;
            position: absolute;
            left: -6px;
            top: 50%;
            border-radius: 50px;
        }
    }
}

@media (min-width: 1536px) {

    .iGor_animation_2 {
        height: 480px !important;

        .iGor__ImAloneBox {
            margin-top: 20px !important;
            
        }

        .iGor_Desti_header {
            margin-bottom: 30px !important;

        }
    }


}


@media screen and (min-width:992px) {
    .iGor_animation {
        transition: height 1s;
        overflow: hidden;
    }

    .iGor_animation_1 {
        height: 275px;
    }

    .iGor_animation_2 {
        height: 450px;
        transition: height 1s;

        .iGor__ImAloneBox {
            margin-top: 4px;
        }

        .iGor__conBtn {
            position: absolute;
            left: 3%;
            bottom: 17px;
            z-index: 1;
            width: 95%;
        }

    }

    .iGor_animation_3 {
        height: 331px;
    }

    .iGor_DestAutoMain {
        .iGor_Desti_header {
            margin-bottom: 15px;

        }
    }
}

@media screen and (max-width:1024px) {
    .iGor_IframeCity {
        justify-content: center;
        margin-bottom: 0 !important;
        font-size: 10px !important;
        font-weight: 400 !important;
    }

    .iGor_DestiListSection {
        height: 84vh;

        margin-top: 37px;
    }

    .iGor_animation {
        padding: 0 25px !important;

        .rdrDay {
            margin-bottom: 14px;
        }
    }

    .iGor_animation_2 {
        padding: 0px;
        height: auto;

        .iGoRoom_CountInput {
            height: 45px;
            min-width: 182px !important;

            .MuiButtonGroup-middleButton {
                font-size: 14px;
            }

            .MuiButton-root {
                min-width: 40px !important;
            }

            svg {
                color: #C4C4C4;
                width: 18px;
                height: 18px;
            }
        }

        .rdrDay {
            margin-bottom: 14px;
        }

        .rdrMonthAndYearPickers {
            font-size: 18px;
        }
    }

    .iGor_animation_3 {
        .iGoRoom_CountInput {
            height: 45px;
            min-width: 182px !important;

            .MuiButtonGroup-middleButton,
            .MuiButtonBase-root {
                font-size: 14px !important;
            }

            .MuiButton-root,
            .MuiButtonBase-root {
                min-width: 40px !important;
            }

            svg {
                color: #C4C4C4;
                width: 18px;
                height: 18px;
            }
        }


        .rdrDay {
            margin-bottom: 14px;
        }
    }

    .iGOr_DestiMB {
        .rdrNextPrevButton {
            margin-left: 14px !important;
        }
    }

    .iGor__textFieldMb {
        background: transparent !important;

        img {
            display: none;
        }

        input {
            color: #FF6B6B;
            text-align: center;

        }
    }

    .iGor_NightCount {
        border-bottom: 1px solid #C4C4C4;
        padding-bottom: 21px;
        margin-bottom: 21px;
        font-weight: 600;
    }

    .iGor_DestAutoMain {

        .iGor__RoomTitle,
        .iGor_d_roomBtnDel {
            margin-top: 11px;
            margin-bottom: 12px;
        }

        .iGor_mb_BtnAdd {
            width: 100% !important;
            max-width: 330px;
            font-size: 14px;

            svg {
                color: #FF6B6B !important;
            }

        }

        .rdrCalendarWrapper,
        .iGor__roomAddSeciton {
            height: calc(100vh - 160px);
        }

        .rdrDayNumber {
            span {
                font-size: 1.5rem;
            }

        }

        .rdrWeekDay {
            font-size: 13px;
            color: #3C3C434D;
            font-weight: 600;

        }

        .iGor_DestiTitle {
            display: none;
        }

        .iGor_DestiListSection {
            img {
                width: 16px;
                height: 16px;
            }

            li {
                margin-bottom: 0;
                padding-left: 5px;
            }
        }
    }

    .iGor__SearchCopy {
        position: fixed;
        bottom: 60px;
        z-index: 1;
        transition: all 0.7s ease-in;
    }

    .iGor__SearchCopytop {
        bottom: calc(100vh - 125px);
    }

    .iGor__destinMBshow {
        opacity: 0;

        .iGor_DestAutoMain,
        .iGor_DestAutoTitBtn {
            visibility: hidden;
        }

        .iGor_destiMBSection {
            max-width: 600px;
            margin: 0 auto;
            display: flex;
        }

        .iGor_DestAutoMain {
            .MuiPaper-root {
                max-width: 420px;
                margin: 0 auto;
            }
        }

        .iGor_DestAutoTitBtn {
            margin: 0 15px 0 0;
        }

        .iGor__MbSerInput {
            background: transparent;
            margin: 0;
            // width: 100%;

            .MuiTypography-root {
                text-align: center;
                width: 80%;
                margin: 0;
            }
        }
    }

    .iGo__ExploreSearch {
        opacity: 1 !important;
        width: 315px;
        height: 45px !important;
    }

    .iGor__DestImAlone {
        .MuiPaper-root {
            padding: 2px 0px !important;
        }

        .iGor__ImAloneBox {
            padding: 0 !important;

            .iGo__titleImalone {
                font-size: 12px;
                margin-top: 10px;
            }

            .aloneIcon {
                top: -24%;
                left: 8%;
                width: 62px;
                height: 48px;
            }

            .iGor__ImConentBox {
                padding: 30px 0;
            }

            .iGor__ImAloneContent {
                font-size: 12px;
                color: #757575;
            }
        }

        .iGor_d_roomBtnSectiom {
            border-top: 1px solid #C4C4C4;
            // padding: 10px 0px 10px 10px;
        }
    }

    .IGor_Mb-Destination {
        @include flexbox(row, space-between, center);
        margin: 0 auto 25px auto !important;
        width: 315px;
    }
        .iGor_verSion {
            display: flex;
            justify-content: center;
            font-size: 0.6rem;
            font-weight: 600;
            color: whitesmoke;
        }
        .iGor__footerBox {
        width: 100%;
        justify-content: space-evenly;
        margin-top: 25px;
        max-width: 455px;
        
        .iGor_footer-link {
            font-weight: 400 !important;
        }

        a {
            font-weight: 600;
            text-decoration: underline !important;
        }
    }

    .iGor__ImAloneBtn {
        height: 54px !important;
        width: 82px !important;
        padding-right: 0;

        .MuiSwitch-thumb {
            width: 26px;
            height: 26px;
        }

        .MuiSwitch-switchBase.Mui-checked {
            -webkit-transform: translateX(27px) !important;
            -moz-transform: translateX(27px) !important;
            -ms-transform: translateX(27px) !important;
            transform: translateX(27px) !important;
        }
    }

    //add Room 
    .iGor_d_roomBtnSectiom {
        max-height: calc(100vh - 300px) !important;


        .iGoRoom_CountInput {
            // width: 162px;
            height: 45px;
            min-width: 162px !important;
            max-width: 162px !important;

            .MuiButtonGroup-middleButton,
            .MuiButtonBase-root {
                font-size: 14px !important;
            }

            .MuiButton-root,
            .MuiButtonBase-root {
                min-width: 40px !important;
            }

            svg {

                color: #C4C4C4;
                width: 18px;
                height: 18px;
            }
        }

        p {
            font-size: 12px;
            font-weight: 600;
            width: 75px;
        }

        .iGor__RoomsubTitle {
            text-align: left;
            padding-left: 19px;
            padding-top: 5px;
        }

        .iGor_childAgeDe {
            border-top: 1px solid #DCDCDC;
            padding: 17px 0px 0 0px;
            margin-bottom: 16px;
            align-items: center;
            margin-top: 0 !important;
        }

        .iGor_MB_addRoomBox {
            align-items: center;
            padding: 0 !important;
            margin-bottom: 16px;
            justify-content: space-between;
        }

    }
.iGor__roomAddSeciton{
    .iGor_mb_BtnAddSection{
        position: fixed;
        bottom: 15%;
        left: 15%;
        width: 100%;
        max-width: 150px;
        font-size: 14px;
        transform: translate(-15%, 10px);
        .iGor_mb_BtnAdd{
            margin-bottom: 0 !important;
        }
    }
}
.iGor_animation{
 

        .iGor_mb_Btn {
            max-width: 150px !important;
            left: 85%;
            transform: translate(-85%, 10px);
        }
  .iGor__conBtnwithAddRoomBtn{
    max-width: 320px !important;
    left: 50% !important;
    transform: translate(-50%, 10px) !important;
  }
}
}