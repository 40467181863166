.iGoR_AccountCard {
    padding: 20px 30px;
}

.iGoR_SideCard {
    padding: 20px 30px;

    .iGor_otherInfo {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 15px;

        a {
            text-decoration: none;
            color: #000000;
            margin-bottom: 16px;

            p {
                cursor: pointer;
                font-weight: 500;
                font-size: 0.875rem;
            }
        }
    }

    .iGor_Accountlist {
        .iGor_UserIcon {
            min-width: 30px;
        }

        .iGor_UserTitle {
            span {

                font-size: 14px;
                font-weight: 500;
                white-space: nowrap;
            }
        }
    }

    .iGor_checkpoint {
        color: #FF6B6B;
        text-decoration: underline;
    }
}

.iGor-identityBox {
    p {
        font-size: 12px;
        color: #757575;
        line-height: 18px;
    }
}

.iGor_Number {
    color: #FF6B6B;
    border: 1px solid #FF6B6B;
    padding: 5px;
    border-radius: 50px;
    width: 35px;
    height: 35px;
    text-align: center;
    margin-top: 1.5rem;
}

.iGor_UploadSection {
    background-color: #F1F5F9;
    border-radius: 10px;
    padding: 20px;
    align-items: center;

    .iGor_UploadImg {
        width: 53px;
        height: 53px;
        border-radius: 50%;
    }

    .iGor_UploadBtn {
        color: #FF6B6B;
        font-size: 10px;
        margin-left: 0.625rem;
        white-space: nowrap;

        .iGor_UploadTxt {
            border-bottom: 1px solid #FF6B6B;
        }
    }
}

.iGoR_AccGoogleBtn {
    font-size: 12px !important;
    font-weight: 600 !important;
    color: #ffffff !important;
    background: #4285F4 !important;
    border-radius: 50px !important;
    height: 40px;
    text-transform: unset !important;
    width: 40%;

    .svg1 {
        width: 19px;
        margin-right: 5px;
    }

    .svg2 {
        width: 20px;
        height: 11.4px;
        margin-left: 2px;
    }
}

.iGor_AccountEditBtn {
    font-size: 12px !important;
    font-weight: 600 !important;
    color: #000000 !important;
    text-transform: capitalize !important;
    text-decoration: underline !important;
}

.iGor_currencyEdit {
    .iGoR_autoCom {
        width: 100%;
        border: 1px solid #c4c4c4;
        margin-top: 10px;

        .MuiInputBase-root {
            padding: 0 !important;
        }

    }
}

.iGo__AccountTextField {
    margin-top: 10px !important;
    margin-right: 5px !important;

    input {
        padding: 8px;
    }
}

@media screen and (max-width:1024px) {
    .iGoR_AccountCard {
        padding: 1px 16px;
    }

    .iGor_AccountTitleMB {
        text-align: center;
    }
}