.iGor_orderSumHeader {
    padding: 1.875rem 0;
    background: #FFFFFF;
    margin-bottom: 1.875rem;

    .iGor_orderBack {
        font-size: 0.875rem;
        font-weight: 600;
        color: #000000 !important;
        border-radius: 0.625rem !important;

        .iGor_orderBackIcon {
            background: #F1F5F9;
            border-radius: 15px;
            margin-right: 10px;
            padding: 0.1875rem;
            width: 35px;
            height: 35px;
        }
    }

    .iGor_OrderBtn {
        font-size: 0.875rem;
        font-weight: 600;
        color: #D8D8D8;
        border-radius: 0.625rem !important;
        padding: 0;

        &:hover {
            background: transparent !important;
            cursor: none;
        }

        .iGor_orderArrow {
            margin: 30px;
        }
    }

    .iGor_OrderBtnActive {
        color: #000000;
        pointer-events: none;

        .iGor_OrderBtnIcon {
            width: 2.5rem;
            height: 2.5rem;
            background: linear-gradient(#FF6B6B, #EE5253);
            border-radius: 3.125rem;
            color: #ffffff;
            padding: 0.625rem;
            margin-right: 0.75rem;
        }

        &:hover {
            background: transparent !important;
        }
    }

}

.iGor_mainTitle {
    font-size: 3rem;
}

.iGor_Guestdetails {
    .iGor__listGuest {
        th {
            padding: 0 15px 15px 15px;
        }

        td {
            padding: 15px;
            border-left: 1px solid #e0e0e0;

            &:first-child {
                border-left: 0;
            }
        }
    }

    .MuiAccordionSummary-content {
        align-items: center;

        svg {
            color: #FF6B6B;
            margin-right: 5px;
        }
    }

    .Mui-expanded {
        margin: 0 !important;
    }

    .MuiAccordionDetails-root {
        padding: 0 !important;
    }

    .iGor_GuestRadioSection {
        margin-left: 1.5rem;
    }

    .iGor_userIcon {
        margin-right: 1rem;
    }

    .iGor_orderAnotherper {
        padding: 0 1.5rem;

    }

    .iGor_orderGuestTitles {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin: 20px 0 10px 0;

        .iGor_titles {
            display: flex;
            width: 50%;
            flex-direction: column;
        }

        p {
            display: flex;
            flex-direction: row;
        }
    }

    .iGor__OrderSymList {
        overflow: auto;
    }
}

.iGor_OrderRegBtn {
    padding: 1.25rem 1.875rem;
    margin-bottom: 1rem;

    .iGor__OrderJoinNowBtn {
        height: 40px;
        width: 164px;
        font-size: 12px;
    }
}

.iGor_RoomName-Clamped {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    /* Number of lines to display */
    -webkit-line-clamp: 2;
    /* Limit to 2 lines */
    margin-top: 0px !important;
}

.iGoi_OrderSumLeftCrad {
    .iGoi_OrderSumTopTitle {
        margin-bottom: 17px;
    }

    .iGor_taxandfees {
        p {
            font-size: 0.75rem;
            font-weight: 600;
        }
    }

    .iGor_orderBedType {
        .iGor_orderBedTyTilte {
            margin-top: 0.5rem;
            font-weight: 600;
        }
    }

    .iGoi_OrderSumImgCard {
        width: 235px;
        height: 126px;
        margin-bottom: 24px;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .iGor__ShowTitle {
        margin-bottom: 16px;
    }

    .iGor__checkInOut {
        padding-bottom: 21px;
        border-bottom: 1px solid #D4D4D4;
        margin-bottom: 17px;
        display: flex;
        align-items: flex-end;
    }

    .iGor-Policies {
        padding-bottom: 21px;
    }

    padding: 1.25rem 1.875rem;

    .iGor_OrderSumList {
        padding-left: 22px;
        margin-bottom: 15px;

        li P {
            font-size: 10px;
        }

        li::marker {
            font-size: 10px;
        }
    }

    .iGor_Icon_moon {
        text-align: center;
    }
}

.iGor_OrderInfo {
    background: #FEFADC;
    border-left: 8px solid #F9DD4A;
    padding: 16px;
    border-radius: 0.625rem;

    svg {
        margin-right: 0.625rem;
    }

    .iGor_Section {
        padding: 0 1.25rem 1.25rem 0;
    }
}

.iGor_OrderCouponcode {
    margin-top: 1rem;
    padding: 1.25rem 1.875rem;

    .iGor_orderPromBtn {
        font-size: 0.75rem !important;
        text-transform: lowercase !important;
        color: #FF6B6B !important;
        text-decoration: underline !important;
    }

    .iGor_OrderS_PromCode {
        border-radius: 3.125rem;
        background: #F1F5F9;
        width: 100%;

        fieldset {
            border: none;

        }
    }

}

.iGor_OrderSumDelBtn {
    position: absolute;
    bottom: 15%;
    cursor: pointer;
    right: -1.25rem;
}

.iGor_OrderAge {
    margin-top: 2.0rem !important;
}

.iGor_OrderAmount {
    font-size: 1.75rem !important;
    font-weight: 600 !important;
}

.iGor_paymentSection {
    // padding-right: 1rem;
    // height: 25vh;
    // .Igo_paypal-button {
    //     width: 20vw;
    //     height: 20vh;
    //     margin: 0px 0px 20px 0px;
    //     background-color: #0070ba;
    //   color: #ffffff;
    //   border: none;
    //   padding: 15px 30px;
    //   font-size: 18px;
    //   font-weight: bold;
    //   border-radius: 5px;
    //   cursor: pointer;
    //   transition: background-color 0.3s ease;
    //   text-align: center;
    //   text-decoration: none;
    //   display: inline-block;
    //   white-space: nowrap;
    //   }

    //   .Igo_paypal-button:hover {
    //     background-color: #005d9c;
    //   }

    //   .Igo_stripe-button {
    //     margin: 20px 0px;
    //     background-color: #5082BE;
    //     color: #fff;
    //     border: none;
    //     padding: 10px 20px;
    //     font-size: 16px;
    //     font-weight: bold;
    //     cursor: pointer;
    //     border-radius: 4px;
    //   }

    //   .Igo_stripe-button:hover {
    //     background-color: #4173AC;
    //   }

    .iGor_Credit {
        background: #fff;
        border-radius: 3.125rem;
        font-size: 0.75rem;
        padding: 0.625rem 0.9375rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 10.9375rem;
        margin: 0 0.625rem;
    }

    .iGor_cardNumber {
        border-radius: 0.625rem;
        background: #fff;
        margin: 0.625rem 0;

        input {
            font-size: 0.875rem !important;
        }
    }

    .iGor_CvvNumber,
    .iGor_CardYear {
        input {
            text-align: center;
        }
    }
}

.iGor-redirectBox {
    display: flex;
    flex-direction: column;
    height: 20vh;
    align-items: center;

    span {
        width: 24px !important;
        height: 24px !important;
        margin-bottom: 10px;
        color: gray;
    }

    p {
        font-size: 12px;
        font-weight: 300;
        line-height: 15px;
    }
}

.iGor_finishCard {
    background: #fff;
    border-radius: 0.9375rem;
    text-align: center;
    padding: 1.25rem;
    max-width: 21.375rem;
    margin: 0 auto;
    margin-top: 8%;
    margin-bottom: 2%;

    .iGor-finishText {
        display: flex;
        justify-content: center;

        p {
            width: 65%;
        }
    }

    .iGor-finishShareTrip {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        img {
            margin: 0 4px;
        }
    }

    .iGor_backToHome {
        cursor: pointer;
    }

}

.iGor_amountBold {
    font-size: 0.75rem;
    font-weight: 600;
    color: #000000;
}

.iGor_radioBtn_Tags {
    padding-left: 200px;
}

.iGor_radioBtn {
    .MuiSvgIcon-root:first-child {
        color: #E5E5E5 !important;
    }

    .MuiSvgIcon-root:last-child {
        color: #FF6B6B !important;
    }
}

.iGor_selectOrder {
    border-radius: 25px !important;
    background: #f1f5f9;
}

// .iGor__orderSumSection {
//     height: 100vh;
// }

.iGoi_OrderPayBox {
    margin-bottom: 200px;

    button {
        font-size: 12px;
    }
}

.iGo__OrderSumPaybox {
    padding: 0 17px 4px 26px;
    text-align: center;
}

.iGor__OrderSymList {
    margin-left: 10px;

    .MuiListItemAvatar-root {
        min-width: 30px;
    }
}

// .iGor__orderSumSection {
//     margin-bottom: 150px;
// }

.iGor__orderSumSticky {
    position: sticky;
    top: 25px;
}

.iGor__UpGradeMyRoom {
    // height: 100%;

    .image,
    .description {
        width: 70% !important;
    }

    .description {
        padding: 22px 22px 15px 50px;

        .body {

            .icon-wrapper {
                display: flex;
                justify-content: space-between;
            }
        }
    }
}

.iGor-noService-Available {
    font-size: 0.8rem;
    font-weight: 500;
    font-family: 'Poppins';
}

.iGor__GuestdetailsTilte {
    margin: 32px 0 16px 0 !important;
}

.iGor__OrdSumContainer {
    padding-bottom: 65px;
    min-height: calc(100vh - 250px);
    position: relative;
}

@media screen and (max-width:1024px) {

    .iGor_orderSumJoinMod {
        padding: 0 !important;

        .iGo_mb-menuTop {
            background: #ffffff;
        }
    }

    .iGor_Guestdetails {
        border-radius: 10px !important;
        padding: 13px 20px !important;

        .iGor_userIcon {
            width: 17px;
            height: 17px;
        }
    }

    .iGor__GuestdetailsTilte {
        margin: 15px 0 10px 0 !important;
    }



    .iGor__orderSumSticky {
        position: static !important;
    }

    .iGor__orderSumSection {
        margin-bottom: 200px;
    }

    .iGoi_OrderPayBox {
        margin-bottom: 0px;
        position: fixed;
        width: 100%;
        bottom: 0;
        background: #fff;
        left: 0;
        // padding: 25px;
        border-radius: 15px 15px 0 0;
        height: 10%;
        display: flex;
        justify-content: center;
        align-items: center;

        button {
            width: 341px;
        }
    }

    .iGor_orderSumHeader {
        background-color: transparent;
        margin-bottom: 0 !important;
        padding-bottom: 0;

        .iGor_orderBack {
            padding: 8px 0;
        }

        .iGor_orderBackIcon {
            background: #BFCCD9 !important;
            color: #fff;
            width: 25px !important;
            height: 25px !important;
            border-radius: 10px !important;
        }
    }

    .iGor__orderSumSection {
        height: 100% !important;
    }

    .iGor_Guestdetails {
        .iGor_GuestRadioSection {
            margin-left: 0rem;
        }

        .iGor_orderAnotherper {
            padding: 0;

            .MuiSelect-select {
                padding: 10px !important;
            }
        }

    }

    .iGor_OrderInfo {
        .iGor_Section {
            padding: 0;
        }

        svg {
            display: none;
        }
    }

    .iGoi_OrderSumLeftCrad {
        margin-bottom: 15px;

        .iGor_RoomName-Clamped {
            line-height: 1.5;
        }

        .iGor_orderBedType {
            display: flex;
            align-content: center;
            margin-top: 5px;
            margin-bottom: 10px;

            .iGor_orderBedTyTilte {
                margin-top: 0;
                margin-right: 24px;
            }
        }

        .iGoi_OrderSumTopTitle {
            margin: 0;
        }

        .iGoi_OrderSumImgCard {
            width: 137px;
            height: 87px;
            margin-bottom: 10px;
        }

        .iGor__checkInOut {
            padding-bottom: 0px;
            border-bottom: none;
            margin-bottom: 0px;
        }

        .iGor-Policies {
            padding-bottom: 0;
        }
    }

    .iGoi_OrderSumMainTitle {
        position: relative;
        left: 0%;
        transform: translate(-50%, -2px);
        font-size: 12px !important;
    }

    .iGor_OrderRegBtn {
        padding: 18px 16px 18px 20px;
        display: flex;
        align-items: center;
        border-radius: 10px;
        margin-top: 15px;

        .MuiTypography-subtitle1 {
            font-size: 8px;
        }

        .iGor__OrderJoinNowBtn {
            height: 25px;
            width: 75px;
            font-size: 8px !important;
            font-weight: 600 !important;
            letter-spacing: 0.005em;
            border-radius: 50px !important;
        }
    }

    .iGor_paymentSection {
        height: auto !important;
        padding: 0 !important;
    }



    .iGoi_OrderPaypl {
        position: fixed;
        text-align: center;
        bottom: 0;
        width: 100%;
        background: #ffffff;
        padding: 25px;
        border-radius: 15px 15px 0 0;

        .iGo__OrderSumPaybox {
            margin: 0;
            padding: 0;
        }
    }

    .iGor_orderAnotherper {
        .iGor__orderField {
            justify-content: flex-end;

            input {
                padding: 10px 15px;
                font-size: 11px;
            }
        }
    }

    .iGor_OrderSumRoomDetails {
        padding: 0;

        .iGoi_OrderSumImgHeader {
            padding: 1.25rem 1.875rem 0;
        }

        .iGor_PropertyDAccordion {
            .MuiPaper-root:first-child {
                border-radius: 0;
                border: 0;
                border-bottom: 1px solid #C4C4C4;
            }

            .MuiPaper-root:last-child {
                border: 0;
            }
        }
    }
}