.iGor_RegionsTop{
    text-align: center;
    color: #ffffff;
    // padding-top: 50px;
    position:fixed;
    top: 7%;
    .iGor_backBtn{
        background: #00000080;
        font-size: 28px;
        padding: 7px;
        border-radius: 10px;
        cursor: pointer;
    }
}

// .iGor_RegionsBottom{
//     position:fixed;
//     bottom: 0;
//     color: #ffffff;
//     margin-bottom: 35px;
// }

.iGor__RegionIframe{
    width: 100%;
    height: 100dvh;
    min-height: -webkit-fill-available;
    max-height: -webkit-fill-available; 
    overflow: hidden;
    border: none !important;
}