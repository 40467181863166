@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;1,300;1,500&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

body {
  background-color: #F1F5F9;
}

html {
  scroll-behavior: smooth;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #000000;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: none;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.igoRoomBtn {
  font-size: 0.75rem !important;
  font-weight: 600 !important;
  border-radius: 1.25rem !important;
  border: none !important;
  color: #ffffff !important;
  background: linear-gradient(180deg, #FF6B6B 0%, #EE5253 100%);
  width: 100%;
  text-transform: inherit !important;
}

.iGor_LoginAccount {
  visibility: hidden;
}

.iGor-action_loader {
  height: 80%;
}
.iGor-action_loader svg {
  font-size: 1.1rem;
}

.iGor_IframeBtn {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 3.125rem;
  color: #ffffff;
  width: 2.5rem !important;
  padding: 0.625rem 0.4375rem 0.625rem 0.8125rem;
  height: 2.5rem !important;
  text-align: center;
  cursor: pointer;
}

.InputField {
  background: #ffffff;
  border-radius: 3.125rem;
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  height: 2.8125rem;
  padding-left: 1.25rem;
}
.InputField .MuiFormLabel-root {
  font-size: 13px;
  color: #a2a2a2;
}
.InputField .MuiTextField-root {
  width: 100%;
}
.InputField input {
  padding: 0.9375rem 0;
  border: none;
  font-size: 0.875rem;
}
.InputField fieldset {
  border: none !important;
}

.InputField:has(.iGor-ErrorMsg),
.iGor_CountryCodeinput:has(.iGor-ErrorMsg) > .iGor_codeField {
  margin-bottom: 27px;
  border: 1px solid #f55c5d;
}

.iGor_passwordInput:has(.iGor-ErrorMsg),
.iGoR_autoComBox:has(.iGor-ErrorMsg) {
  margin-bottom: 1rem;
  border-radius: 50px;
}

.iGor_passwordInput:has(.iGor-ErrorMsg) > .InputField,
.iGor_CountryCodeinput:has(.iGor-ErrorMsg) > .iGor_codeField,
.iGor_loginPass:has(.iGor-ErrorMsg) > .InputField {
  border: 1px solid #f55c5d;
}

.iGor_passwordInput > .iGor-ErrorMsg {
  bottom: -4px !important;
}

.iGoR_autoComBox > .iGor-ErrorMsg {
  bottom: -20px !important;
}

.iGr__RegAccount .iGor__RegField {
  position: relative;
  top: 6px;
}
.iGr__RegAccount .InputField [data-shrink=true] {
  color: #a2a2a2 !important;
  transform: translate(0px, 3px) scale(0.75) !important;
}
.iGr__RegAccount .InputField [data-shrink=false] {
  color: #a2a2a2 !important;
  transform: translate(14px, 11px) scale(1) !important;
}
.iGr__RegAccount .iGor__RegDate [data-shrink=false],
.iGr__RegAccount .iGor_regEmail [data-shrink=false] {
  color: #C4C4C4 !important;
  transform: translate(0px, 10px) scale(1) !important;
}

.iGoRoom_CountInput {
  background: #F1F5F9;
  border-radius: 3.125rem !important;
  width: 100%;
  height: 40px;
  justify-content: space-between;
  color: #000;
}
.iGoRoom_CountInput .MuiButton-root {
  border: none !important;
  color: #000;
  border-radius: 3.125rem !important;
  min-width: 1.875rem !important;
}

.iGor__ChildBox {
  display: flex;
  align-items: center;
  margin-top: 10px;
  justify-content: center;
}
.iGor__ChildBox .iGoRoom_CountInput {
  width: 180px;
  margin-left: 10px;
  align-items: center;
}
.iGor__ChildBox .iGoRoom_CountInput .iGor__childAge {
  background: #FF6B6B;
  color: #ffffff;
  width: 40px;
  height: 40px;
}
.iGor__ChildBox .iGoRoom_CountInput .iGor__childAge .MuiSvgIcon-root {
  width: 18px;
  height: auto;
}
.iGor__ChildBox .iGor__YearsOld {
  width: 80px;
  text-align: right;
}

.iGor__VerificationBg {
  background-color: #ffffff !important;
}

.iGoRoom_login_pop .inputIcons {
  width: 0.9375rem !important;
}
.iGoRoom_login_pop .iGor_passIcon {
  width: 1.125rem !important;
}

.inputIcons {
  margin-right: 0.9375rem;
}

.modal-close {
  position: absolute;
  top: 0;
  right: 0;
}

.iGor__regiterClose {
  top: 5px !important;
  right: 15px !important;
}

.iGoroom_NhTitle span {
  font-size: 0.75rem;
  font-weight: 600;
}
.iGoroom_NhTitle p {
  font-size: 0.625rem;
  color: #C4C4C4;
  font-weight: 400;
}

.iGor_UserLoginPop .MuiPaper-root {
  color: #000000;
}
.iGor_UserLoginPop .iGor_UserIcon {
  min-width: 2.1875rem;
  justify-content: center;
  margin-right: 0.625rem;
}
.iGor_UserLoginPop .iGor_UserIcon .iGor_userOutline {
  width: 0.875rem;
}
.iGor_UserLoginPop .iGor_UserIcon svg,
.iGor_UserLoginPop .iGor_UserIcon .ticketIcon {
  width: 1rem;
  color: #000000;
}
.iGor_UserLoginPop .iGor_UserIcon svg,
.iGor_UserLoginPop .iGor_UserIcon .faviourateIcon {
  width: 1rem !important;
  color: #000000;
}
.iGor_UserLoginPop .iGor_UserTitle span {
  font-size: 0.75rem;
  font-weight: 500;
}

.iGoR_autoCom {
  background: #ffffff;
  border-radius: 3.125rem;
}
.iGoR_autoCom .MuiInputBase-root {
  padding: 0rem;
}
.iGoR_autoCom fieldset {
  border: none;
}
.iGoR_autoCom input {
  font-size: 0.875rem;
  padding-left: 1.25rem !important;
}
.iGoR_autoCom .MuiOutlinedInput-root {
  padding: 0.3125rem 1.875rem 0.3125rem 0.3125rem !important;
}

.rdrSelected,
.rdrInRange,
.rdrStartEdge,
.rdrEndEdge {
  top: 1px !important;
  height: 33px !important;
}

.rdrStartEdge {
  border-top-left-radius: 50px !important;
  border-bottom-left-radius: 50px !important;
  left: 0px !important;
}

.rdrEndEdge {
  border-top-right-radius: 50px !important;
  border-bottom-right-radius: 50px !important;
  right: 0px !important;
}

.rdrNextButton i,
.rdrPprevButton i {
  border: solid #FF6B6B !important;
  border-width: 0 2px 2px 0 !important;
  display: inline-block !important;
  padding: 4px !important;
}

.rdrNextButton i {
  transform: rotate(-45deg) !important;
  -webkit-transform: rotate(-45deg) !important;
}

.rdrPprevButton i {
  transform: rotate(135deg) !important;
  -webkit-transform: rotate(135deg) !important;
}

.iGor_DestAutoMain .rdrCalendarWrapper {
  font-size: 0.75rem !important;
  width: 100% !important;
  margin-bottom: 0.625rem;
}
.iGor_DestAutoMain .rdrMonth {
  padding: 0 !important;
  width: 100% !important;
}

.rdrDayDisabled {
  background: #ffffff !important;
}

.rdrMonths {
  text-transform: uppercase !important;
}

.rdrMonth {
  padding: 0 !important;
}

.rdrMonthAndYearWrapper {
  height: 2.5rem !important;
  padding-top: 0 !important;
}

.rdrNextPrevButton {
  order: 1;
  background: transparent !important;
  color: #FF6B6B;
  width: 18px !important;
  height: 18px !important;
  margin-right: 0px !important;
  margin-left: 0 !important;
}

.rdrMonthAndYearPickers {
  padding-left: 0rem;
  font-size: 1.125rem !important;
  color: #FF6B6B;
  justify-content: start !important;
}

.rdrDayToday .rdrDayNumber span {
  position: relative;
  z-index: 1;
}
.rdrDayToday .rdrDayNumber span:after {
  background: #C4C4C4 !important;
  border-radius: 100% !important;
  width: 35px !important;
  height: 35px !important;
  color: #ffffff;
  bottom: 0px !important;
  z-index: -1;
}
.rdrDayToday .rdrStartEdge ~ .rdrDayNumber span::after {
  height: 0 !important;
}

.iGor_calendar {
  padding: 7px;
}
.iGor_calendar .rdrMonthAndYearPickers {
  padding: 0;
}
.iGor_calendar .rdrPprevButton i {
  border-color: transparent #EE5253 transparent transparent;
}
.iGor_calendar .rdrNextButton i {
  border-color: transparent transparent transparent #F44336;
}
.iGor_calendar .rdrDay {
  background-color: transparent;
}
.iGor_calendar .rdrSelected {
  color: #EE5253 !important;
  top: 2px !important;
  height: 2.2rem;
  width: 2.2rem;
  border-radius: 100%;
  left: 6px !important;
}
.iGor_calendar .rdrDayToday .rdrDayNumber span {
  color: #fff;
}
.iGor_calendar .rdrMonthPicker,
.iGor_calendar .rdrYearPicker {
  margin: 0;
}
.iGor_calendar .rdrMonthPicker select,
.iGor_calendar .rdrYearPicker select {
  padding-left: 0.9375rem;
  text-align: left;
  font-size: 1rem;
  font-weight: 600;
  color: #FF6B6B;
}
.iGor_calendar .rdrMonthPicker select option,
.iGor_calendar .rdrYearPicker select option {
  color: #000000;
  padding: 0.3125rem;
}
.iGor_calendar .rdrYearPicker select {
  padding-right: 1.4375rem;
}
.iGor_calendar .rdrDayHovered span {
  border-color: #EE5253;
}

.iGor_CustomTabs_Offers {
  background-color: #ffffff;
  border-radius: 3.125rem;
  margin-bottom: 0.5rem;
  color: #EE5253;
  display: flex;
  width: 360px;
  margin-top: 20px;
  align-items: center;
}
.iGor_CustomTabs_Offers .iGor_btnTab {
  text-transform: none;
  font-size: 0.675rem;
  color: #000000;
  border-radius: 3.125rem;
  margin: 5px;
}
.iGor_CustomTabs_Offers .iGor_btnTab:hover {
  background: linear-gradient(180deg, #FF6B6B 0%, #EE5253 100%);
  color: #ffffff;
}
.iGor_CustomTabs_Offers .iGor_activeTab {
  background: linear-gradient(180deg, #FF6B6B 0%, #EE5253 100%);
  color: #ffffff;
}
.iGor_CustomTabs_Offers .btncenter {
  margin: 0 0.5rem;
}

.iGor_CustomTabs {
  background: #F1F5F9;
  border-radius: 3.125rem;
  margin-top: 27px;
}
.iGor_CustomTabs .iGor_btnTab {
  font-size: 0.875rem;
  color: #000000;
  border-radius: 3.125rem;
  padding: 0.3125rem 0.9375rem;
  text-transform: none;
}
.iGor_CustomTabs .iGor_btnTab:hover {
  background: linear-gradient(180deg, #FF6B6B 0%, #EE5253 100%);
  color: #ffffff !important;
}
.iGor_CustomTabs .iGor_activeTab {
  background: linear-gradient(180deg, #FF6B6B 0%, #EE5253 100%);
  color: #ffffff !important;
}
.iGor_CustomTabs .btncenter {
  margin: 0 0.5rem;
}

.MuiAutocomplete-listbox {
  font-size: 0.875rem !important;
}
.MuiAutocomplete-listbox .MuiAutocomplete-option {
  font-size: 0.875rem !important;
}

.firstNameError {
  left: 35px !important;
}

.lastNameError {
  left: 35px !important;
}

.phoneError {
  left: 40px !important;
}

.iGor-ErrorMsg {
  font-size: 0.625rem;
  color: #ff1807;
  position: absolute;
  z-index: 1;
  bottom: 7px;
  left: 25px;
}

.iGorCurrError {
  bottom: -15px;
}

.iGoRoom_login_pop .iGor-ErrorMsg {
  position: absolute;
  bottom: -20px;
  left: 50px;
}
.iGoRoom_login_pop .InputField {
  height: 40px !important;
}
.iGoRoom_login_pop .iGor_mailIcon {
  color: #EE5253;
}

.iGor_TextFieldGrey {
  background: #F1F5F9;
  border-radius: 50px;
  padding: 10px 20px;
}
.iGor_TextFieldGrey fieldset {
  border: none;
}

.iGor_codeField {
  background: #ffffff;
  border-radius: 50px;
  padding: 4px 0 4px 20px;
  height: 45px;
}
.iGor_codeField .iGorTitle {
  font-size: 8px;
  color: #C4C4C4;
}
.iGor_codeField .iGorCodeName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
  color: #C4C4C4;
}
.iGor_codeField .iGorCodeNameSelected {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  color: #000;
}

.iGoR_autoComSection_noCuntry {
  width: 190px;
  height: 35px;
}
.iGoR_autoComSection_noCuntry .iGor_Text_noCuntry {
  font-size: 0.7rem;
  font-weight: 500;
  height: 35px;
  display: flex;
  flex-direction: none;
  justify-content: center;
  align-items: center;
}

.iGoR_autoComSection {
  height: 245px;
  width: 235px;
  padding: 11px;
  padding-bottom: 0;
}
.iGoR_autoComSection .MuiSvgIcon-root {
  display: none;
}
.iGoR_autoComSection .MuiInputBase-root {
  background: #F1F5F9;
  border-radius: 50px;
}
.iGoR_autoComSection .MuiAutocomplete-listbox {
  height: 179px;
}
.iGoR_autoComSection .MuiAutocomplete-popper {
  max-height: 200px !important;
  width: 99% !important;
  transform: translate3d(0, 65px, 0px) !important;
  border-radius: 0 !important;
}

.iGor_inputFieldval {
  font-size: 14px;
  font-weight: 400 !important;
  color: #000000 !important;
}

.iGor-date_reset svg {
  color: #FF6B6B;
}

.iGor_forgetPass .inputIcons {
  font-size: 17px;
}
.iGor_forgetPass .MuiButton-root,
.iGor_forgetPass .InputField {
  max-width: 474px;
}
.iGor_forgetPass .MuiButton-root p,
.iGor_forgetPass .InputField p {
  position: absolute;
  bottom: -16px;
  left: -11px;
}
.iGor_forgetPass .MuiTypography-caption {
  margin-bottom: 15px;
}

.iGor__inbox {
  height: 300px;
  width: 300px;
  background: #F1F5F9;
  padding: 15px;
}
.iGor__inbox .iGor__InboxTabs {
  background: #fff;
  display: flex;
  justify-content: space-around;
  padding: 5px;
  border-radius: 50px;
  margin: 10px 0;
  height: 40px;
}
.iGor__inbox .iGor__tabBox {
  background: #ffffff;
  padding: 15px;
  border-radius: 15px;
  cursor: pointer;
}
.iGor__inbox .iGor-messageUnread {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.iGor__inbox .iGor-messageUnread svg {
  color: #EE5253;
  font-size: 0.5rem;
}
.iGor__inbox .iGor__inboxBtn {
  font-size: 10px;
  font-weight: 600;
  cursor: pointer;
  padding: 5px;
  width: 100%;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #C4C4C4;
  font-size: 10px;
  background: transparent;
}
.iGor__inbox .iGor__inboxActive {
  background: #EE5253 !important;
  color: #ffffff;
}
.iGor__inbox .iGor__inboxTitle {
  display: flex;
  cursor: pointer;
}
.iGor__inbox .iGor__inboxTitle svg {
  margin-right: 1rem;
}
.iGor__inbox .iGor_updatedCard {
  cursor: pointer;
}
.iGor__inbox .iGor_updatedCard .iGor__updatedImgBox {
  background: #C4C4C4;
  border-radius: 15px;
  height: 125px;
  width: 100%;
  margin: 8px 0;
}
.iGor__inbox .iGor_updatedCard .iGor__updatedImgBox .iGor__updatedImg {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  -o-object-fit: cover;
     object-fit: cover;
}

.iGoR_autoComBox .MuiAutocomplete-listbox {
  max-height: 145px !important;
}

.iGor-action_loader {
  display: flex;
  justify-content: center;
  align-items: center;
}
.iGor-action_loader svg {
  color: #ffffff;
  font-size: 1.3rem;
}

@media screen and (max-width: 1024px) {
  .iGor__RegisterAccount {
    max-width: 600px;
    margin: 0 auto;
  }
  .iGor__RegisterAccount .phoneError {
    left: 30px !important;
    width: 100%;
  }
  .iGor__ChildBox {
    justify-content: space-between;
    width: 100%;
  }
  .iGor__ChildBox .iGoRoom_CountInput {
    margin-left: 0px !important;
  }
  .iGor__ChildBox .iGoRoom_CountInput .iGor__childAge {
    background-color: #ffffff !important;
  }
  .iGor__updatedImgBox {
    height: 150px !important;
  }
  .iGr__RegAccount .iGor__RegField {
    top: 4px;
  }
  .iGor__inboxTitle {
    justify-content: center;
  }
  .iGor__InboxTabs {
    margin: 5px 0 25px 0px !important;
  }
  .iGor__inbox {
    width: 100%;
    height: 100% !important;
    max-width: 600px;
    margin: 0 auto;
  }
  .iGor__RegisterAccount .iGor_passIcon {
    width: 15px;
  }
  .iGor__RegisterAccount .InputField {
    padding-left: 22px;
    height: 45px;
  }
  .iGor__RegisterAccount .InputField .MuiFormLabel-root {
    font-size: 12px;
    color: #a2a2a2;
    transform: translate(0px, 10px) scale(0.75) !important;
  }
  .iGor__RegisterAccount .InputField [data-shrink=true] {
    color: #a2a2a2 !important;
    transform: translate(0px, 3px) scale(0.75) !important;
  }
  .rdrDayDisabled {
    background: #F1F5F9 !important;
  }
  .iGor_calendar {
    width: 93vw;
  }
  .iGor_calendar .rdrSelected {
    left: 12px !important;
  }
  .iGor_calendar .rdrMonth {
    width: 100%;
  }
  #DatePiker-popover .rdrSelected {
    left: 10px !important;
  }
  #DatePiker-popover .rdrMonthAndYearWrapper {
    padding-right: 12px;
  }
  .iGor_codeField {
    padding: 2px 0 2px 20px;
    height: 45px;
  }
  .iGor_codeField .iGorCodeName {
    font-size: 11px;
  }
}
@media screen and (max-width: 767px) {
  #LoginAccount-popover .MuiPaper-root {
    overflow: hidden;
  }
  .iGor__inbox .iGor__tabBox {
    background: transparent;
  }
  .phoneError {
    left: 40px !important;
    white-space: nowrap;
  }
  .iGor-ErrorMsg {
    left: 24px !important;
    bottom: 10px;
  }
  .iGor_passwordInput > .iGor-ErrorMsg {
    bottom: 2px !important;
  }
  .iGoRoom_login_pop .iGor-ErrorMsg {
    bottom: -14px;
    left: 48px !important;
  }
  .iGoRoom_login_pop .iGor_loginPass .iGor-ErrorMsg {
    left: 48px !important;
  }
  .iGor__inboxTitle {
    justify-content: start;
    margin: 0 !important;
  }
}
.iGor-Text-center {
  text-align: center !important;
}

.iGor_setFlex {
  display: flex;
}

.iGor_Text-decoration {
  text-decoration: none;
  cursor: pointer;
  color: black;
}

.iGor_txt_nowrap {
  white-space: nowrap;
}

.iGor_setRelative {
  position: relative !important;
}

.iGor_justifyBetween {
  justify-content: space-between;
}

.iGor_justifyCenter {
  justify-content: center;
}

.iGor_alignCenter {
  align-items: center;
}

.iGor_pointer {
  cursor: pointer !important;
}

.iGor-M-0 {
  margin: 0;
}

.iGor-ML-10 {
  margin-left: 0.625rem;
}

.iGor-MB-15 {
  margin-bottom: 0.9375rem;
}

.iGor_PT-0 {
  padding-top: 0 !important;
}

.iGor-PR-20 {
  padding-left: 1.25rem;
}

.iGor-Checkbox {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.iGor-Checkbox span {
  margin: 0.326rem 0 !important;
  color: #FF6B6B !important;
}
.iGor-Checkbox span svg {
  font-size: 1.2rem;
}

.iGor-width-100 {
  width: 100%;
}

.iGor-width-90 {
  width: 90%;
}

.iGor-width-80 {
  width: 80%;
}

.iGor-width-70 {
  width: 70%;
}

.iGor-width-60 {
  width: 60%;
}

.iGor-width-50 {
  width: 50%;
}

.iGor-width-40 {
  width: 40%;
}

.iGor-width-30 {
  width: 30%;
}

.iGor-width-25 {
  width: 25%;
}

.iGor-width-20 {
  width: 20%;
}

.iGor-width-15 {
  width: 15%;
}

.iGor-width-10 {
  width: 10%;
}

.iGor_bgWhite {
  background: #ffffff !important;
}

.iGOr_BorderRadius15 {
  border-radius: 15px;
}

.iGor_silder {
  color: #FF6B6B !important;
  height: 2px !important;
}
.iGor_silder .MuiSlider-thumb {
  width: 8px;
  height: 8px;
  transform: rotate(45deg) !important;
  background: #FF6B6B;
  border-radius: 0% !important;
  top: 31% !important;
}

.iGor_DestAutoTitBtn {
  display: flex;
  padding: 0.9375rem 1.0625rem;
  width: 23.3125rem;
  margin: 0;
  cursor: pointer;
}
.iGor_DestAutoTitBtn .iGor_destSearchTitle {
  font-size: 0.875rem;
  color: #C4C4C4;
  margin-left: 0.3125rem;
}
.iGor_DestAutoTitBtn input {
  padding-left: 12px;
}

.iGor__DesSearchBar {
  position: fixed;
  bottom: 10px;
  width: 100%;
}
.iGor__DesSearchBar .iGoR_NeedHelpBtn {
  margin-top: 8px;
}
.iGor__DesSearchBar::after {
  content: "";
  width: 100vw;
  background: linear-gradient(180deg, transparent, #000);
  position: absolute;
  top: 0;
  height: 305px;
  z-index: -1;
  margin-top: -100px;
}

.iGor__DestImAlone .MuiPaper-root {
  padding: 0 !important;
}
.iGor__DestImAlone .iGor_Desti_header {
  padding: 20px 20px 9px 20px;
  cursor: pointer;
}
.iGor__DestImAlone .iGor__ImAloneBox {
  padding: 0px 20px 20px 20px;
}
.iGor__DestImAlone .iGor__ImConentBox {
  position: relative;
  padding: 45px;
  margin-bottom: 15px;
}

.iGor__ImAloneBtn {
  height: 48px !important;
  width: 71px !important;
}
.iGor__ImAloneBtn .MuiSwitch-switchBase {
  top: 3px !important;
}
.iGor__ImAloneBtn .MuiSwitch-track {
  border-radius: 100px !important;
  background-color: #D8D8D8;
}
.iGor__ImAloneBtn .MuiSwitch-thumb {
  margin: 2px 0px 0 6px;
}
.iGor__ImAloneBtn .Mui-checked + .MuiSwitch-track {
  background-color: linear-gradient(100deg, #EE5253, #FF6B6B) !important;
}

.iGor__roomAddSeciton .rdrMonthsVertical {
  margin: 0 -10px;
}

.iGor_DestiListSection {
  height: 200px;
  overflow: hidden;
  overflow-y: auto;
}
.iGor_DestiListSection::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #fff;
  background: #ffffff !important;
}
.iGor_DestiListSection::-webkit-scrollbar-thumb {
  background: #D9D9D9 !important;
}
.iGor_DestiListSection .Igo_Nomatch_destinations {
  padding: 30px 0 0 10px;
  font-size: 14px;
  color: #a8b1b9;
}
.iGor_DestiListSection li {
  margin: 0 4px 10px 0px;
  padding-left: 5px;
}
.iGor_DestiListSection li:hover {
  background-color: #F1F5F9;
  border-radius: 50px;
}
.iGor_DestiListSection img {
  margin-right: 12px;
  margin-left: 10px;
}

.iGor_d_roomBtnDel {
  color: #f20707;
  width: 20px;
  margin-top: 25px;
  cursor: pointer;
}

.iGor_DestAutoMain {
  padding-bottom: 0.3125rem;
  width: 23.3125rem;
}
.iGor_DestAutoMain .iGor__RoomTitle {
  margin-top: 20px;
}
.iGor_DestAutoMain .iGor_d_roomBtnSectiom {
  max-height: 31vh;
  overflow: hidden;
  overflow-y: auto;
  padding: 10px;
}
.iGor_DestAutoMain .MuiPaper-root {
  border-radius: 0.9375rem;
}
.iGor_DestAutoMain .MuiPaper-root .iGor_room-Exceed {
  font-size: 0.7rem !important;
  color: red !important;
  display: flex;
  margin: 10px 0 10px 0;
  top: 45vh;
  /* position: absolute; */
  justify-content: center;
  align-items: center;
  width: 100%;
}
.iGor_DestAutoMain .MuiPaper-root .iGor_room-Exceed_mb {
  font-size: 0.8rem;
  color: red;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 30px;
}
.iGor_DestAutoMain .aloneIcon {
  position: absolute;
  top: -11%;
  left: 20%;
}
.iGor_DestAutoMain .igoRoomBtn {
  max-width: 20.0625rem;
}
.iGor_DestAutoMain .iGor_Desti_border:after {
  content: "";
  width: 112%;
  height: 0.5px;
  background: #C4C4C4;
  position: absolute;
  left: -20px;
  bottom: -10px;
}
.iGor_DestAutoMain .iGor_Desti_header {
  display: flex;
  align-items: flex-end;
  margin-bottom: 15px;
  position: relative;
  cursor: pointer;
}
.iGor_DestAutoMain .iGor_Desti_header .iGor_item {
  width: 29%;
  text-align: left;
}
.iGor_DestAutoMain .iGor_Desti_header .iGor_sub {
  font-size: 0.625rem;
  color: #FF6B6B;
  font-weight: 600;
}
.iGor_DestAutoMain .iGor_Desti_header .iGor_title {
  font-size: 0.875rem;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.iGor_DestAutoMain .iGor_Desti_header .iGor_rightIcon {
  width: 0.9375rem;
  color: #FF6B6B;
  margin: 5px 15px;
}
.iGor_DestAutoMain .iGor_Desti_header .iGor_rightIcon2 {
  margin: 5px 10px 5px 18px;
}
.iGor_DestAutoMain .iGor_DestiTitle {
  color: #FF6B6B;
  padding: 0;
  margin-left: 21px;
  margin-bottom: 10px;
}
.iGor_DestAutoMain .iGor_DestiList {
  padding: 0;
}
.iGor_DestAutoMain .iGor_DestiList li {
  font-size: 0.875rem;
  border-radius: 0.9375rem;
  width: 100% !important;
}
.iGor_DestAutoMain .iGor_DestiList li img {
  margin-right: 0.875rem;
}

.iGor_IframeTitle {
  color: #ffffff;
  cursor: pointer;
}

.iGor_IframeCity {
  color: #ffffff;
}

.iGOr_DestiMB {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #F1F5F9;
  display: flex;
  padding: 20px 0;
  opacity: 1 !important;
  z-index: 2;
  transition: opacity 0.6s linear;
  flex-direction: column;
}
.iGOr_DestiMB .iGor_DestAutoMain,
.iGOr_DestiMB .iGor_DestAutoTitBtn {
  visibility: visible !important;
}
.iGOr_DestiMB .iGor_DestiMb_arrow {
  background: #BFCCD9;
  width: 25px;
  height: 25px;
  border-radius: 10px;
  color: #ffffff;
  padding: 4px;
  margin: 10px 14px 10px 25px;
}
.iGOr_DestiMB .iGor_DestiMb_arrow svg {
  font-size: 15px;
}
.iGOr_DestiMB .iGor_DestAutoMain {
  height: 100%;
  width: 100%;
}
.iGOr_DestiMB .iGor_DestAutoMain .MuiPaper-root {
  background: #F1F5F9;
  box-shadow: none;
}
.iGOr_DestiMB .iGor_DestAutoMain .MuiPaper-root .iGor_mb_Btn {
  position: fixed;
  bottom: 15%;
  left: 50%;
  width: 100%;
  max-width: 330px;
  font-size: 14px;
  transform: translate(-50%, 10px);
}
.iGOr_DestiMB .iGor_DestAutoMain .MuiPaper-root .iGor_mb_BtnAddSection {
  flex-direction: column !important;
}
.iGOr_DestiMB .iGor_DestAutoMain .MuiPaper-root .iGor_mb_BtnAdd {
  width: 275px;
  margin: 1rem auto;
  position: relative;
}
.iGOr_DestiMB .iGor_DestAutoMain .iGoRoom_CountInput {
  background: #ffffff;
}
.iGOr_DestiMB .iGor_DestAutoMain .rdrCalendarWrapper {
  background: #F1F5F9;
}
.iGOr_DestiMB .iGor_DestAutoMain .rdrMonth {
  font-size: 0.875rem !important;
}
.iGOr_DestiMB .iGor_Desti_header {
  display: none;
}
.iGOr_DestiMB .iGor__ImAloneBox {
  position: relative;
}

.iGor_DropDownItem li {
  font-size: 0.875rem;
  border-radius: 0.9375rem;
}

.iGor_NightCount {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  align-items: center;
  width: 100%;
}

.iGor_animation_1 {
  padding: 20px 5px 20px 9px;
}

.iGor_animation_3 .iGor_DatesBox {
  width: 98px !important;
}
.iGor_animation_3 .iGor_DatesBox .iGor_title {
  overflow: visible !important;
}

.iGor_animation_2 {
  padding: 20px;
  height: 480px;
}
.iGor_animation_2 .iGoRoom_CountInput {
  min-width: 194px !important;
}

.iGor_verSion {
  display: flex;
  justify-content: center;
  font-size: 0.6rem !important;
  font-weight: 600;
  color: whitesmoke;
}

.iGor__footerBox {
  display: flex;
  align-items: center;
  margin-top: 44px;
  margin-bottom: 10px;
}
.iGor__footerBox .iGOr_mb-footer-link {
  text-decoration: none;
  cursor: cursor;
  color: white;
}
.iGor__footerBox .iGor_footer-link {
  font-size: 0.75rem !important;
  color: #ffffff;
  font-weight: 600 !important;
  margin-right: 10px;
}
.iGor__footerBox a {
  color: #ffffff !important;
  text-decoration: underline;
  position: relative;
  font-size: 0.75rem;
  margin-right: 10px;
}
.iGor__footerBox a:last-child {
  margin: 0;
}
.iGor__footerBox a:before {
  content: "";
  width: 2px;
  height: 2px;
  background: #fff;
  position: absolute;
  left: -6px;
  top: 50%;
  border-radius: 50px;
}

@media (min-width: 1536px) {
  .iGor_animation_2 {
    height: 480px !important;
  }
  .iGor_animation_2 .iGor__ImAloneBox {
    margin-top: 20px !important;
  }
  .iGor_animation_2 .iGor_Desti_header {
    margin-bottom: 30px !important;
  }
}
@media screen and (min-width: 992px) {
  .iGor_animation {
    transition: height 1s;
    overflow: hidden;
  }
  .iGor_animation_1 {
    height: 275px;
  }
  .iGor_animation_2 {
    height: 450px;
    transition: height 1s;
  }
  .iGor_animation_2 .iGor__ImAloneBox {
    margin-top: 4px;
  }
  .iGor_animation_2 .iGor__conBtn {
    position: absolute;
    left: 3%;
    bottom: 17px;
    z-index: 1;
    width: 95%;
  }
  .iGor_animation_3 {
    height: 331px;
  }
  .iGor_DestAutoMain .iGor_Desti_header {
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 1024px) {
  .iGor_IframeCity {
    justify-content: center;
    margin-bottom: 0 !important;
    font-size: 10px !important;
    font-weight: 400 !important;
  }
  .iGor_DestiListSection {
    height: 84vh;
    margin-top: 37px;
  }
  .iGor_animation {
    padding: 0 25px !important;
  }
  .iGor_animation .rdrDay {
    margin-bottom: 14px;
  }
  .iGor_animation_2 {
    padding: 0px;
    height: auto;
  }
  .iGor_animation_2 .iGoRoom_CountInput {
    height: 45px;
    min-width: 182px !important;
  }
  .iGor_animation_2 .iGoRoom_CountInput .MuiButtonGroup-middleButton {
    font-size: 14px;
  }
  .iGor_animation_2 .iGoRoom_CountInput .MuiButton-root {
    min-width: 40px !important;
  }
  .iGor_animation_2 .iGoRoom_CountInput svg {
    color: #C4C4C4;
    width: 18px;
    height: 18px;
  }
  .iGor_animation_2 .rdrDay {
    margin-bottom: 14px;
  }
  .iGor_animation_2 .rdrMonthAndYearPickers {
    font-size: 18px;
  }
  .iGor_animation_3 .iGoRoom_CountInput {
    height: 45px;
    min-width: 182px !important;
  }
  .iGor_animation_3 .iGoRoom_CountInput .MuiButtonGroup-middleButton,
  .iGor_animation_3 .iGoRoom_CountInput .MuiButtonBase-root {
    font-size: 14px !important;
  }
  .iGor_animation_3 .iGoRoom_CountInput .MuiButton-root,
  .iGor_animation_3 .iGoRoom_CountInput .MuiButtonBase-root {
    min-width: 40px !important;
  }
  .iGor_animation_3 .iGoRoom_CountInput svg {
    color: #C4C4C4;
    width: 18px;
    height: 18px;
  }
  .iGor_animation_3 .rdrDay {
    margin-bottom: 14px;
  }
  .iGOr_DestiMB .rdrNextPrevButton {
    margin-left: 14px !important;
  }
  .iGor__textFieldMb {
    background: transparent !important;
  }
  .iGor__textFieldMb img {
    display: none;
  }
  .iGor__textFieldMb input {
    color: #FF6B6B;
    text-align: center;
  }
  .iGor_NightCount {
    border-bottom: 1px solid #C4C4C4;
    padding-bottom: 21px;
    margin-bottom: 21px;
    font-weight: 600;
  }
  .iGor_DestAutoMain .iGor__RoomTitle,
  .iGor_DestAutoMain .iGor_d_roomBtnDel {
    margin-top: 11px;
    margin-bottom: 12px;
  }
  .iGor_DestAutoMain .iGor_mb_BtnAdd {
    width: 100% !important;
    max-width: 330px;
    font-size: 14px;
  }
  .iGor_DestAutoMain .iGor_mb_BtnAdd svg {
    color: #FF6B6B !important;
  }
  .iGor_DestAutoMain .rdrCalendarWrapper,
  .iGor_DestAutoMain .iGor__roomAddSeciton {
    height: calc(100vh - 160px);
  }
  .iGor_DestAutoMain .rdrDayNumber span {
    font-size: 1.5rem;
  }
  .iGor_DestAutoMain .rdrWeekDay {
    font-size: 13px;
    color: rgba(60, 60, 67, 0.3019607843);
    font-weight: 600;
  }
  .iGor_DestAutoMain .iGor_DestiTitle {
    display: none;
  }
  .iGor_DestAutoMain .iGor_DestiListSection img {
    width: 16px;
    height: 16px;
  }
  .iGor_DestAutoMain .iGor_DestiListSection li {
    margin-bottom: 0;
    padding-left: 5px;
  }
  .iGor__SearchCopy {
    position: fixed;
    bottom: 60px;
    z-index: 1;
    transition: all 0.7s ease-in;
  }
  .iGor__SearchCopytop {
    bottom: calc(100vh - 125px);
  }
  .iGor__destinMBshow {
    opacity: 0;
  }
  .iGor__destinMBshow .iGor_DestAutoMain,
  .iGor__destinMBshow .iGor_DestAutoTitBtn {
    visibility: hidden;
  }
  .iGor__destinMBshow .iGor_destiMBSection {
    max-width: 600px;
    margin: 0 auto;
    display: flex;
  }
  .iGor__destinMBshow .iGor_DestAutoMain .MuiPaper-root {
    max-width: 420px;
    margin: 0 auto;
  }
  .iGor__destinMBshow .iGor_DestAutoTitBtn {
    margin: 0 15px 0 0;
  }
  .iGor__destinMBshow .iGor__MbSerInput {
    background: transparent;
    margin: 0;
  }
  .iGor__destinMBshow .iGor__MbSerInput .MuiTypography-root {
    text-align: center;
    width: 80%;
    margin: 0;
  }
  .iGo__ExploreSearch {
    opacity: 1 !important;
    width: 315px;
    height: 45px !important;
  }
  .iGor__DestImAlone .MuiPaper-root {
    padding: 2px 0px !important;
  }
  .iGor__DestImAlone .iGor__ImAloneBox {
    padding: 0 !important;
  }
  .iGor__DestImAlone .iGor__ImAloneBox .iGo__titleImalone {
    font-size: 12px;
    margin-top: 10px;
  }
  .iGor__DestImAlone .iGor__ImAloneBox .aloneIcon {
    top: -24%;
    left: 8%;
    width: 62px;
    height: 48px;
  }
  .iGor__DestImAlone .iGor__ImAloneBox .iGor__ImConentBox {
    padding: 30px 0;
  }
  .iGor__DestImAlone .iGor__ImAloneBox .iGor__ImAloneContent {
    font-size: 12px;
    color: #757575;
  }
  .iGor__DestImAlone .iGor_d_roomBtnSectiom {
    border-top: 1px solid #C4C4C4;
  }
  .IGor_Mb-Destination {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto 25px auto !important;
    width: 315px;
  }
  .iGor_verSion {
    display: flex;
    justify-content: center;
    font-size: 0.6rem;
    font-weight: 600;
    color: whitesmoke;
  }
  .iGor__footerBox {
    width: 100%;
    justify-content: space-evenly;
    margin-top: 25px;
    max-width: 455px;
  }
  .iGor__footerBox .iGor_footer-link {
    font-weight: 400 !important;
  }
  .iGor__footerBox a {
    font-weight: 600;
    text-decoration: underline !important;
  }
  .iGor__ImAloneBtn {
    height: 54px !important;
    width: 82px !important;
    padding-right: 0;
  }
  .iGor__ImAloneBtn .MuiSwitch-thumb {
    width: 26px;
    height: 26px;
  }
  .iGor__ImAloneBtn .MuiSwitch-switchBase.Mui-checked {
    transform: translateX(27px) !important;
  }
  .iGor_d_roomBtnSectiom {
    max-height: calc(100vh - 300px) !important;
  }
  .iGor_d_roomBtnSectiom .iGoRoom_CountInput {
    height: 45px;
    min-width: 162px !important;
    max-width: 162px !important;
  }
  .iGor_d_roomBtnSectiom .iGoRoom_CountInput .MuiButtonGroup-middleButton,
  .iGor_d_roomBtnSectiom .iGoRoom_CountInput .MuiButtonBase-root {
    font-size: 14px !important;
  }
  .iGor_d_roomBtnSectiom .iGoRoom_CountInput .MuiButton-root,
  .iGor_d_roomBtnSectiom .iGoRoom_CountInput .MuiButtonBase-root {
    min-width: 40px !important;
  }
  .iGor_d_roomBtnSectiom .iGoRoom_CountInput svg {
    color: #C4C4C4;
    width: 18px;
    height: 18px;
  }
  .iGor_d_roomBtnSectiom p {
    font-size: 12px;
    font-weight: 600;
    width: 75px;
  }
  .iGor_d_roomBtnSectiom .iGor__RoomsubTitle {
    text-align: left;
    padding-left: 19px;
    padding-top: 5px;
  }
  .iGor_d_roomBtnSectiom .iGor_childAgeDe {
    border-top: 1px solid #DCDCDC;
    padding: 17px 0px 0 0px;
    margin-bottom: 16px;
    align-items: center;
    margin-top: 0 !important;
  }
  .iGor_d_roomBtnSectiom .iGor_MB_addRoomBox {
    align-items: center;
    padding: 0 !important;
    margin-bottom: 16px;
    justify-content: space-between;
  }
  .iGor__roomAddSeciton .iGor_mb_BtnAddSection {
    position: fixed;
    bottom: 15%;
    left: 15%;
    width: 100%;
    max-width: 150px;
    font-size: 14px;
    transform: translate(-15%, 10px);
  }
  .iGor__roomAddSeciton .iGor_mb_BtnAddSection .iGor_mb_BtnAdd {
    margin-bottom: 0 !important;
  }
  .iGor_animation .iGor_mb_Btn {
    max-width: 150px !important;
    left: 85%;
    transform: translate(-85%, 10px);
  }
  .iGor_animation .iGor__conBtnwithAddRoomBtn {
    max-width: 320px !important;
    left: 50% !important;
    transform: translate(-50%, 10px) !important;
  }
}
.iGoR_SearchResult .iGor-No_Result_messages {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  height: 30%;
  top: 65%;
}
.iGoR_SearchResult .iGor-No_Result_messages .iGor-No_Result_image {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 60%;
}
.iGoR_SearchResult .iGor-No_Result_messages .iGor-No_Result_image img {
  width: 50px;
  height: 50px;
}
.iGoR_SearchResult .iGor-No_Result_messages .iGor-No_Result_image P {
  font-size: 1.3rem;
  font-weight: 500;
  font-family: "Poppins";
}
.iGoR_SearchResult .subtitle2 {
  color: #C4C4C4;
  display: flex;
}
.iGoR_SearchResult .subtitle2 img {
  margin-right: 5px;
}
.iGoR_SearchResult .priceRange {
  margin-top: 11px;
}
.iGoR_SearchResult .iGor_priceRange {
  min-width: 260px;
  text-align: center;
  height: 60px;
}
.iGoR_SearchResult .iGor_priceRange img {
  width: 100%;
  height: 100%;
}

.iGor-action_loader {
  display: flex;
  justify-content: center;
  align-items: center;
}
.iGor-action_loader svg {
  color: #ffffff;
  font-size: 1.4rem;
}

.iGor_favCardWrapper {
  padding-left: 8px !important;
  margin: 0 !important;
}

.iGor_favouriteCard {
  padding-top: 0 !important;
  padding-left: 10px !important;
}
.iGor_favouriteCard .iGoR_roomCard {
  margin: 15px 0 0 0 !important;
}
.iGor_favouriteCard .iGor_cardBtn {
  width: 100% !important;
}
.iGor_favouriteCard .iGor-CardLeft {
  width: 179px;
  height: 158px;
}
.iGor_favouriteCard .iGor-CardLeft .iGor_cardImg {
  width: 100% !important;
  height: 100% !important;
}

.iGor-latestOfferSection {
  height: 75vh;
  overflow: hidden;
  overflow-y: scroll;
}
.iGor-latestOfferSection .iGor-NoDataOffersSection {
  font-size: 20px;
  font-weight: 500;
  color: darkgray;
  margin: 100px 0 0 0px;
}

.iGor-resultSection {
  height: 499px;
  overflow: hidden;
  overflow-y: scroll;
  margin-bottom: 1rem;
}
.iGor-resultSection .iGor-NoDataOffersSection {
  font-size: 20px;
  font-weight: 500;
  color: darkgray;
  margin: 100px 0 0 0px;
}

.iGor_fliterSectionMb {
  background: #ffffff;
  border-radius: 50px;
  display: flex;
  justify-content: space-between;
  width: 305px;
  align-items: center;
  height: 45px;
}
.iGor_fliterSectionMb .iGor_PT-0 {
  padding: 0 !important;
}
.iGor_fliterSectionMb .iGo__SearchIcon {
  color: #C4C4C4;
  margin-left: 23px;
  margin-right: 20px;
}
.iGor_fliterSectionMb .IGor_FilterIcon {
  margin-right: 14px;
}

.iGor_serR_arrow {
  background: #BFCCD9;
  width: 25px !important;
  height: 25px !important;
  border-radius: 10px;
  color: #ffffff;
  padding: 6px;
  margin: 0 10px;
}

.iGor_OurRecBtn {
  font-size: 14px !important;
  background: #E2EBF3 !important;
  border-radius: 50px !important;
  padding: 7px 10px 7px 25px !important;
  margin-right: 15px !important;
  height: 45px;
  width: 250px;
  justify-content: space-between !important;
}
.iGor_OurRecBtn svg {
  margin-left: 5px;
  color: #73889D;
}

.iGor_FlightDistance,
.iGor_AF-viewBy {
  border-radius: 15px;
  background-color: #F1F5F9;
  padding: 15px;
  justify-content: space-between;
  margin-bottom: 10px;
}
.iGor_FlightDistance .iGor_EastIcon,
.iGor_AF-viewBy .iGor_EastIcon {
  font-size: 17px;
}
.iGor_FlightDistance .iGor_FDtitle,
.iGor_AF-viewBy .iGor_FDtitle {
  font-size: 12px;
  font-weight: 500;
}
.iGor_FlightDistance .iGor_FDsub,
.iGor_AF-viewBy .iGor_FDsub {
  font-size: 8px;
}
.iGor_FlightDistance .iGor_FDsub span,
.iGor_AF-viewBy .iGor_FDsub span {
  color: #FF6B6B;
}

.iGoR-SearchRFilter {
  background-color: #ffffff;
  border-radius: 15px;
  padding: 30px 30px 0;
  margin-top: 15px;
}
.iGoR-SearchRFilter .iGoR-SrFilterTopBtn {
  display: flex;
  justify-content: flex-end;
}
.iGoR-SearchRFilter .iGoR-SrFilterTopBtn button {
  font-size: 14px;
  height: 40px;
}
.iGoR-SearchRFilter .iGoR-SrFilterTop .iGor-width-20 {
  position: relative;
}
.iGoR-SearchRFilter .iGoR-SrFilterTop .iGor-result_dates {
  width: 22vw;
}
.iGoR-SearchRFilter .iGoR-SrFilterTop .iGoR-Sr_line::after {
  content: "";
  position: absolute;
  width: 1px;
  height: 26px;
  top: 0;
  background: #C4C4C4;
  right: 25px;
}

.iGOrBtn__disable {
  background: #979797 !important;
  color: #fff !important;
}

.Igor_MapSectionOffers {
  overflow: hidden;
  position: relative;
  border-radius: 15px;
  margin-top: 22px;
  background-color: #e6e6e6;
  border: 1px solid #ffffff;
  height: 80vh;
}
.Igor_MapSectionOffers [role=dialog] {
  padding: 0 !important;
}
.Igor_MapSectionOffers [role=dialog] div {
  overflow: unset !important;
  background-color: transparent !important;
}
.Igor_MapSectionOffers [role=dialog] button {
  z-index: 2;
  opacity: 1 !important;
}
.Igor_MapSectionOffers [role=dialog] button span {
  background-color: #ffffff !important;
}
.Igor_MapSectionOffers .gm-style-iw-chr {
  position: absolute;
  right: 0;
}
.Igor_MapSectionOffers .iGor-rating {
  position: absolute;
  z-index: 1;
  bottom: 11%;
  left: 6%;
}
.Igor_MapSectionOffers .iGor-rating .iGor_starIcon {
  font-size: 11px;
  color: #E26721;
  margin-right: 2px;
}
.Igor_MapSectionOffers .iGor-MarkerHotelCard {
  width: 221px;
  height: 133px;
  border-radius: 8px;
  z-index: 1000;
  overflow: hidden;
  cursor: pointer;
}
.Igor_MapSectionOffers .iGor-MarkerHotelCard::before {
  content: "";
  position: absolute;
  height: 133px;
  background: #000;
  background: linear-gradient(90deg, #000, rgba(0, 0, 0, 0.3088585776) 50%);
  opacity: 0.8;
  z-index: 1;
  width: 221px;
  border-radius: 0px;
}
.Igor_MapSectionOffers .iGor-MarkerHotelCard img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background-color: #979797;
}
.Igor_MapSectionOffers .iGor-MarkerHotelCard h3 {
  position: absolute;
  color: white;
  font-size: 10px;
  letter-spacing: 0px;
  font-weight: bold;
  top: 85%;
  left: 7%;
  z-index: 2;
}

.iGor_ZoomableImage {
  overflow: hidden;
  position: relative;
  border-radius: 15px;
  margin-top: 10px;
  border: 6px solid #ffffff;
  height: 550px;
  line-height: 12px;
}
.iGor_ZoomableImage iframe {
  border: none !important;
}
.iGor_ZoomableImage .iGor_boxTop,
.iGor_ZoomableImage .iGor_boxBottom {
  position: absolute;
  z-index: 1;
  width: 100%;
  color: #ffffff;
}
.iGor_ZoomableImage .iGor_boxTop_location {
  display: flex;
  flex-direction: row;
  justify-content: center;
  word-spacing: 2px;
  align-items: center;
  font-size: 10px;
}
.iGor_ZoomableImage .iGor_boxTop_hotelName {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 10px;
  word-spacing: 1px;
}
.iGor_ZoomableImage .iGor_boxTop {
  top: 15px;
  text-align: center;
}
.iGor_ZoomableImage .iGor_boxBottom {
  display: flex;
  justify-content: space-between;
  bottom: 35px;
  padding: 0 25px;
}
.iGor_ZoomableImage .iGor_zoomBtn {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50px;
  color: #ffffff;
  margin-right: 15px;
}

.iGor-hotel_disable {
  opacity: 30%;
}

.iGoR_roomCard {
  background: #ffffff;
  padding: 15px;
  margin: 15px 15px 15px 0;
  border-radius: 15px;
}
.iGoR_roomCard .iGor__HotelName {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.iGoR_roomCard .iGor_BookNowSection {
  background: #F1F5F9;
  padding: 15px;
  border-radius: 15px;
}
.iGoR_roomCard .iGor_BookNowSection .iGor_BookPrice {
  color: #FF6B6B;
}
.iGoR_roomCard .iGor_BookNowSection .iGor_BookPrice span {
  color: #C4C4C4;
  font-size: 12px;
}
.iGoR_roomCard .iGor_BookNowBtn {
  width: 155px;
}
.iGoR_roomCard .iGoR_roomCardInner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  height: 151px;
}
.iGoR_roomCard .iGor-CardLeft {
  width: 42%;
  height: 100%;
  position: relative;
}
.iGoR_roomCard .iGor-CardLeft .iGor_cardBtnSection {
  position: absolute;
  top: 5%;
  z-index: 1;
  width: 90%;
}
.iGoR_roomCard .iGor-CardLeft .iGor_cardBtnSection .iGor_vrViewIcon {
  cursor: pointer;
}
.iGoR_roomCard .iGor-CardLeft .iGor_cardBtnSection .iGor_cardLeftBtn {
  background: rgba(0, 0, 0, 0.5);
  color: #ffffff;
  width: 30px;
  height: 30px;
}
.iGoR_roomCard .iGor-CardLeft .iGor_cardBtnSection .iGor_cardLeftBtn svg {
  font-size: 20px;
}
.iGoR_roomCard .iGor-CardLeft .iGor_cardImg {
  border-radius: 15px;
  width: 221px;
  height: 155px;
  -o-object-fit: cover;
     object-fit: cover;
  background-color: #b7b4b2;
}
.iGoR_roomCard .iGor-CardRight {
  width: 55%;
  height: 100%;
}
.iGoR_roomCard .iGor-CardRight .iGor_mainTitleFev {
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
}
.iGoR_roomCard .iGor-CardRight .iGor_Travel {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 11px 0 19px 0;
}
.iGoR_roomCard .iGor-CardRight .iGor_Travel img {
  margin-right: 3px;
}
.iGoR_roomCard .iGor-CardRight .iGor_villasCard {
  background-color: #F1F5F9;
  padding: 15px;
  border-radius: 15px;
  margin: 15px 0;
}
.iGoR_roomCard .iGor-CardRight .iGor_villasCard .iGor_ArrowRight {
  background: #FF6B6B;
  border-radius: 15px;
  font-size: 15px;
  color: #ffffff;
  padding: 2px;
}
.iGoR_roomCard .iGor-CardRight .iGor_villasCard .iGor_villasTitle {
  font-weight: 600;
  display: flex;
  margin-right: 10px;
}
.iGoR_roomCard .iGor-CardRight .iGor_villasCard .iGor_villasTitle span {
  font-size: 8px;
  color: #C4C4C4;
}
.iGoR_roomCard .iGor-CardRight .iGor_CardBtnSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.iGoR_roomCard .iGor-CardRight .iGor_CardBtnSection .iGor_cardPrice {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.iGoR_roomCard .iGor-CardRight .iGor_CardBtnSection .iGor_cardBtn {
  width: 144px;
  height: 40px;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
}
.iGoR_roomCard .iGor-CardRight .iGor-ratingSection {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.iGoR_roomCard .iGor-CardRight .iGor-ratingSection .iGor-rating {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0 5px 0 9px;
}
.iGoR_roomCard .iGor-CardRight .iGor-ratingSection .iGor_ratingNum {
  background: #FF6B6B;
  width: 22px;
  height: 12px;
  font-size: 8px;
  color: #ffffff;
  border-radius: 50px;
  text-align: center;
  margin-right: 5px;
}
.iGoR_roomCard .iGor_starIcon {
  color: #E26721;
  width: 8px;
}
.iGoR_roomCard .iGor_starIcon_not_rating {
  color: #83807e;
  width: 6px;
}
.iGoR_roomCard .iGor_cardBtn {
  font-size: 12px;
}
.iGoR_roomCard .iGor_travel-until-mb {
  margin-top: 25px;
}
.iGoR_roomCard .iGor_subTitle {
  color: #C4C4C4;
}
.iGoR_roomCard .iGor_flex {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.iGor_typeBtn {
  font-size: 14px !important;
  font-weight: 400;
  background: #F1F5F9 !important;
  border-radius: 20px !important;
  padding: 6px 0px !important;
  margin-top: 27px !important;
  min-width: 130px;
  color: #000000 !important;
  font-family: "Poppins", "sans-serif";
}

.iGor_listPop {
  flex-direction: column;
}
.iGor_listPop .MuiTypography-subtitle2,
.iGor_listPop .MuiButtonBase-root {
  width: 100%;
  font-weight: 500;
}

.IGor_FilterIcon {
  color: #E26721;
}

.iGor_AdvFilterInput {
  background: #F1F5F9;
  padding: 0 10px;
  border-radius: 50px;
  font-size: 14px;
}
.iGor_AdvFilterInput fieldset {
  border: none;
}
.iGor_AdvFilterInput input {
  font-size: 14px;
}

.iGor_flighthours {
  color: #FF6B6B;
  font-weight: 500;
  font-size: 12px;
  display: flex;
  justify-content: center;
  margin: 10px;
}
.iGor_flighthours img {
  margin-right: 10px;
}

.iGOr_DropDown {
  background: #F1F5F9;
  padding: 7px 15px 0 15px;
  border-radius: 15px;
  margin-bottom: 10px;
}
.iGOr_DropDown .MuiListItemButton-root,
.iGOr_DropDown .MuiSvgIcon-root,
.iGOr_DropDown .MuiButtonBase-root {
  padding: 0 !important;
}
.iGOr_DropDown .iGor_SortPrice {
  padding: 10px 0;
  border-bottom: 1px solid #E5E5E5;
  margin-bottom: 15px;
}

.iGor_filterResetBtn {
  font-size: 12px !important;
  border-radius: 50px !important;
  color: #000000 !important;
  padding: 0 !important;
}

.iGor_filterBtnSection {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  background: #ffffff;
  height: 81px;
  border-radius: 15px 15px 0px 0px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1490196078);
  align-items: center;
}
.iGor_filterBtnSection .iGor_filterResetBtn {
  font-size: 12px !important;
  border-radius: 50px !important;
  background: #C4C4C4;
  color: #ffffff !important;
  padding: 0 !important;
}
.iGor_filterBtnSection button {
  height: 40px;
  width: 158px;
}

.iGor__filterPriceRange h6 {
  font-size: 12px;
  font-weight: 600 !important;
}

.iGor_FiltercheckBox svg {
  fill: #FF6B6B;
}

.iGor_FiltercheckText {
  margin-left: -10px;
}
.iGor_FiltercheckText .MuiListItemText-primary {
  font-size: 14px;
  font-weight: 500;
}

.iGor_PopularDesti {
  width: 100%;
  padding: 10px;
}
.iGor_PopularDesti li {
  padding-left: 0 !important;
}

.iGoR_roomCardSkeleton .iGoR_ImgSkeleton {
  width: 100%;
  height: 100%;
}

.iGor_cardPrice .iGor_price {
  margin-right: 3px;
}

.iGor_room-Exceed {
  font-size: 0.7rem !important;
  color: red !important;
  display: flex;
  margin: 10px 0 10px 0 !important;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.iGor__latestImgBox {
  display: flex;
  height: calc(100vh - 200px);
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.iGor__latestImgBox .iGor__latestLogoImg {
  margin-top: 20px;
  width: 300px;
  height: 200px;
}

.iGoR_LatestOffers .iGor_ZoomableImage {
  height: 80vh;
  margin-top: 20px;
}
.iGoR_LatestOffers .iGor_ZoomableImage div {
  height: 80vh;
}

@media screen and (max-width: 1024px) {
  .iGor_fliterMbMain {
    justify-content: center;
  }
  .iGoR_LatestOffers {
    padding: 0 !important;
  }
  .iGoR_LatestOffers .iGor__mbHeight100 {
    height: 97vh !important;
  }
  .iGoR_LatestOffers .iGor__mbHeight40 {
    height: 42vh !important;
  }
  .iGoR_LatestOffers .iGor__mbHeight60 {
    height: 60vh !important;
  }
  .iGoR_LatestOffers .iGor__mbHeight5 {
    height: 55px !important;
  }
  .iGoR_LatestOffers .iGor__mbHeight5 .iGor__LatestSwipe {
    transition: all ease-in-out 1s;
  }
  .iGoR_LatestOffers .iGor-hotel_disable {
    opacity: 30%;
  }
  .iGoR_LatestOffers .iGoR_roomCard .iGor-CardRight {
    position: relative;
  }
  .iGoR_LatestOffers .iGoR_roomCard .iGoR_roomCardInner {
    height: 110px;
  }
  .iGoR_LatestOffers .iGor_CardBtnSection {
    position: absolute;
    bottom: 0;
  }
  .iGoR_LatestOffers .iGoR_roomCardSkeleton {
    width: 90vw !important;
  }
  .iGoR_LatestOffers .iGor_ZoomableImage {
    display: none;
  }
  .iGoR_LatestOffers .Igor_MapSectionOffers {
    width: 100%;
    height: 50vh;
    transition: height 1s ease-in-out;
    margin: 0;
    border-radius: 0;
  }
  .iGoR_LatestOffers .iGor-latestOfferBox {
    height: 50vh;
    position: fixed;
    z-index: 1;
    background: #fff;
    bottom: 0;
    border-radius: 30px 30px 0 0;
    left: 0;
    width: 100vw;
    transition: height 1s ease-in-out;
    overflow: hidden;
  }
  .iGoR_LatestOffers .iGor-latestOfferBox .iGor__LatestSwipe {
    padding: 22px 0 14px 0;
  }
  .iGoR_LatestOffers .iGor-latestOfferBox .iGor__LatestSwipe::after {
    content: "";
    position: absolute;
    width: 69px;
    height: 5px;
    background: #C4C4C4;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 50px;
  }
  .iGoR_LatestOffers .iGor-latestOfferBox .iGor-latestOfferSection {
    width: 100vw;
    display: flex;
    flex-wrap: wrap;
    height: 80%;
  }
  .iGoR_LatestOffers .iGor-latestOfferBox .iGor-latestOfferSection .iGoR_roomCard {
    margin-bottom: 14px;
    padding: 0 10px;
    margin-right: 0;
  }
  .iGoR_LatestOffers .iGor-latestOfferBox .iGor-latestOfferSection .iGor-CardRight {
    margin: 5px 0;
  }
  .iGoR_LatestOffers .iGor-latestOfferBox .iGor-latestOfferSection .iGor_Travel {
    display: flex;
    margin: 0;
  }
  .iGoR_LatestOffers .iGor-latestOfferBox .iGor-latestOfferSection .iGor-ratingSection {
    margin-left: 3px;
  }
  .iGoR_LatestOffers .iGor-latestOfferBox .iGor__LatestFilter {
    position: absolute;
    z-index: 5;
    top: 18px;
    right: 22px;
  }
  .iGoR_LatestOffers .iGor-latestOfferBox .iGor__LatestFilter .iGo__filter {
    border: 2px solid #000000;
    width: 30px;
    height: 30px;
    border-radius: 50px;
    padding: 5px;
    display: none;
  }
  .iGoR_LatestOffers .iGor-latestOfferBox .iGo__titltMb {
    font-size: 14px !important;
    font-weight: 600 !important;
    text-align: center;
  }
  .iGor-resultSection {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-content: flex-start;
  }
  .iGoR_SearchResult .iGor-No_Result_messages {
    top: 40%;
  }
  .iGoR_SearchResult .iGor-No_Result_messages .iGor-No_Result_image {
    height: 40%;
  }
  .iGoR_SearchResult .iGoR_roomCard {
    position: relative;
    width: 49%;
  }
  .iGoR_SearchResult .iGoR_roomCard .iGoR_roomCardInner {
    height: 110px;
  }
  .iGoR_SearchResult .iGoR_roomCard .iGor_CardBtnSection {
    position: absolute;
    bottom: 10px;
  }
  .iGoR_SearchResult .iGoR_roomCard .iGor__HotelName {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 10px;
    margin-left: 2px;
  }
  .iGoR_SearchResult .iGoR_roomCard .iGor_subTitle,
  .iGoR_SearchResult .iGoR_roomCard .iGor_Location {
    font-size: 8px;
  }
  .iGoR_SearchResult .iGoR_roomCard .iGor_Location {
    margin-left: 2px;
  }
  .iGoR_SearchResult .iGoR_roomCard .iGor_cardPrice .iGor_price {
    font-size: 12px;
    margin-right: 0px;
  }
  .iGor__SearchSwipe .iGor__inputflied {
    background: #F1F5F9;
    border-radius: 50px;
    display: flex;
    align-items: center;
    height: 45px;
    margin-bottom: 8px;
  }
  .iGor__SearchSwipe .iGor__inputflied .iGor__input {
    font-size: 12px;
  }
  .iGor__SearchSwipe .iGor__inputflied .iGor__icon {
    font-size: 16px;
    color: #FF6B6B;
    margin: 0 15px 0 25px;
  }
  .iGor__SearchSwipe .MuiPaper-root {
    border-radius: 20px 20px 0 0;
  }
  .iGor__SearchSwipe .iGor_Travel {
    margin-top: 0;
  }
}
@media screen and (max-width: 767px) {
  .iGor_favouriteCard {
    padding-left: 0px !important;
  }
  .iGor_favCardWrapper {
    width: 100vw;
    margin-left: 5px !important;
  }
  .iGor_favCardWrapper .iGoR_roomCardInner {
    height: 135px;
  }
  .iGor_favCardWrapper .iGoR_roomCard {
    margin: 15px 10px 0 0 !important;
  }
  .iGor_favCardWrapper .iGoR_roomCard .iGor-CardLeft {
    width: 45%;
  }
  .iGor_favCardWrapper .iGoR_roomCard .iGor-CardRight {
    width: 48%;
  }
  .iGoR_SearchResult .iGor-No_Result_msg {
    font-size: 15px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center;
    height: 50vh;
    font-weight: 600;
  }
  .iGoR_SearchResult .iGor-hotel_disable {
    opacity: 30%;
  }
  .iGoR_SearchResult .iGoR_roomCard {
    position: relative;
    height: 130px;
    width: 100%;
  }
}
.iGor_verCodeinput {
  width: 3.25rem;
  height: 2.5rem;
  border-radius: 3.125rem;
  background: #F1F5F9;
  border: none;
  margin: 0.9375rem 0.9375rem 0.9375rem 0;
  text-align: center;
}

.iGor_verCodeinputEmail {
  width: 3.25rem;
  height: 2.5rem;
  border-radius: 3.125rem;
  background: #F1F5F9;
  border: none;
  margin: 0.9375rem 0.9375rem 0.9375rem 0;
  text-align: center;
}
.iGor_verCodeinputEmail:last-child {
  margin-right: 0;
}

.iGor_verCodeinput:last-child {
  margin-right: 0;
}

.iGor_verCodeinputEmail :last-child {
  margin-right: 0;
}

.iGor_userProfile {
  width: 9.1875rem;
  background: #F1F5F9;
  height: 9.1875rem;
  margin: 34px auto 23px auto;
  border-radius: 50%;
  padding: 1.875rem;
}

.iGor_userProfileBox {
  padding: 20px;
}
.iGor_userProfileBox .iGor__userMsg {
  font-weight: 500;
  line-height: 18px;
}
.iGor_userProfileBox .iGor__userName {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 18px;
}
.iGor_userProfileBox .iGor__userInfo {
  margin-bottom: 14px;
  line-height: 18px;
  font-weight: 600;
}
.iGor_userProfileBox .iGor__closeBtn {
  margin-top: 22px;
}

.iGor_verifiImg {
  display: flex;
  flex-wrap: wrap;
  padding: 15px 0px 5px;
  justify-content: center;
  overflow-y: scroll;
  height: 331px;
}
.iGor_verifiImg .iGor_verifCardImg {
  position: relative;
  margin: 0.3125rem;
  border-radius: 0.9375rem;
  width: 10.375rem;
  height: 9.5rem;
  cursor: pointer;
}
.iGor_verifiImg .iGor_verifiTextOver,
.iGor_verifiImg .circleCheckIcon {
  position: absolute;
  color: #fff;
  left: 50%;
  top: 80%;
  transform: translate(-50%, -80%);
}
.iGor_verifiImg .iGor_verifiText {
  font-weight: 600;
}
.iGor_verifiImg .circleCheckIcon {
  display: none;
  width: 2.5rem;
  height: 2.5rem;
}
.iGor_verifiImg .iGor_SelImg .circleCheckIcon {
  display: block;
  top: 50%;
  transform: translate(-50%, -50%);
}
.iGor_verifiImg .iGor_SelImg::before {
  content: "";
  width: 100%;
  height: 100%;
  background: #FF6B6B;
  position: absolute;
  opacity: 0.7;
  border-radius: 0.9375rem;
}
.iGor_verifiImg img {
  width: 100%;
  height: 100%;
  border-radius: 0.9375rem;
  -o-object-fit: cover;
     object-fit: cover;
}

.iGor_verifCardImgTwo.iGor_SelImg::before {
  border-radius: 100%;
}

.iGor_verifCardImgTwo {
  width: 10.375rem !important;
  height: 10.375rem !important;
}
.iGor_verifCardImgTwo img {
  border-radius: 100% !important;
  -o-object-fit: cover;
     object-fit: cover;
}

.MuiPopover-root .MuiPaper-root {
  border-radius: 0.625rem !important;
}

.iGor__logingUserProfile {
  padding-left: 0 !important;
}

.iGor_userLoginProBtn {
  background: #ffffff !important;
  border-radius: 3.125rem !important;
  margin: 0.5rem !important;
  margin-right: 0 !important;
  padding: 0.3125rem !important;
  color: #000000 !important;
  height: 40px !important;
}
.iGor_userLoginProBtn .iGor_userLoginProImg {
  background: #FF6B6B;
  border-radius: 0.9375rem;
  width: 1.8125rem;
  padding: 0.3125rem;
  height: 30px;
}

.increase_height {
  margin-bottom: 10px;
}

.iGor-resendOTP_box {
  display: flex;
  justify-content: center;
}
.iGor-resendOTP_box p {
  font-size: 8px;
  color: #757575;
  font-weight: 500;
}

.iGor-OTP_timer {
  display: flex;
  justify-Content: center;
}

.iGor-OTP_timer_hide {
  visibility: hidden;
}

.iGor__VerificationBg .iGor__arrowBackBtn {
  position: absolute;
  top: 22px;
  left: 15px;
  cursor: pointer;
}

.iGor__addPadding {
  padding: 20px;
}

@media screen and (max-width: 1024px) {
  .iGor_verifiImg {
    width: 100%;
    margin: 0 auto;
  }
}
@media screen and (max-width: 600px) {
  .iGor_verifiImg {
    width: 360px;
    margin: 0 auto;
  }
}
.iGo_mb-menuTop {
  padding: 35px 0;
  text-align: center;
}
.iGo_mb-menuTop .iGo_m-menuClear {
  width: 29.39px;
  height: 29.39px;
  position: absolute;
  top: 42px;
  right: 5%;
}

.iGor_Verified {
  font-size: 15px !important;
  margin-bottom: -3px;
}

.iGo_mb-UserLoggedIn {
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.15);
}

.iGo_mb-UserMenu {
  width: 100vw;
  height: 100%;
  padding: 25px;
  overflow-y: auto;
  padding-bottom: 160px;
}
.iGo_mb-UserMenu .iGor_UserIcon {
  min-width: 30px;
}

.iGor_m-menuBtn {
  position: absolute !important;
  right: 11%;
  top: 20%;
}
.iGor_m-menuBtn .iGo_m-menuIcon {
  color: #ffffff;
  font-size: 25px;
}

.iGor_menuArrow {
  color: #FF6B6B;
  font-size: 30px !important;
}

.igor_mobileList {
  min-width: 30px;
}
.igor_mobileList .iGor_UserTitle span {
  font-size: 14px;
  font-weight: 500;
}
.igor_mobileList .iGoRoom_login_pop .igoRoomBtn {
  height: 45px;
}

.iGo_mb-menuBox .MuiPaper-root {
  overflow: hidden;
}

.iGo_m-Menu {
  display: none;
}

@media screen and (max-width: 1024px) {
  .iGor_mb_UserDetails {
    max-width: 600px;
    margin: 0 auto;
  }
  .iGr__RegAccountPopup .MuiBackdrop-root {
    background: #F1F5F9 !important;
  }
  .iGr__VerifictionPopup .MuiBackdrop-root {
    background: #ffffff !important;
  }
  .iGo_m-Menu {
    display: block;
  }
  .iGor__SecondMenuMB .iGo_m-Menu {
    position: relative;
    top: 46px;
    left: -20px;
  }
  .iGor__inboxMenuMB .iGo_m-Menu {
    position: static;
  }
  .iGor__inboxMenuMB .iGor_m-menuBtn {
    margin-top: 0 !important;
  }
  .iGor__UserLogoutBtn {
    position: fixed;
    bottom: 0;
    padding: 0px 25px;
    width: 100%;
    z-index: 1 !important;
    left: 0;
    background: white;
    height: 85px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .iGoRoom_LayoutMain {
    position: fixed;
    width: 100%;
  }
  .iGor__TeramAndConLink {
    color: #757575 !important;
    text-decoration: none !important;
  }
  .iGor__TeramAndConLinkTow {
    color: #000000 !important;
    text-decoration: none !important;
  }
  .iGr__RegAccount {
    overflow: hidden;
    overflow-y: auto;
    padding-bottom: 100px;
  }
  .iGOr_MB-listLogin {
    width: 100%;
    font-size: 14px !important;
  }
  .iGoRoom_login_pop {
    height: calc(100vh - 120px);
    overflow-y: auto;
    max-width: 100% !important;
    padding: 0 0.9375rem !important;
    text-align: center;
    padding-bottom: 150px !important;
  }
  .iGoRoom_login_pop .iGor__CreateAccountBtn {
    display: block;
    margin: 0 auto;
  }
  .iGoRoom_login_pop .InputField {
    width: 100%;
    max-width: 340px;
    margin: 0 auto 16px auto;
    height: 45px !important;
  }
  .iGoRoom_login_pop .iGor_mailIcon {
    color: #000000;
    width: 17px !important;
  }
  .iGoRoom_login_pop .inputIcons {
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(209deg) brightness(0%) contrast(97%);
    width: 19px !important;
  }
  .iGoRoom_login_pop .iGor__JoinNowBtn {
    font-size: 12px;
  }
  .iGoRoom_login_pop .iGor__linkBox {
    border-bottom: 1px solid #C4C4C4;
    padding: 24px 0 35px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .iGoRoom_login_pop .iGor__linkBox span {
    margin: 0 3px;
  }
  .iGoRoom_login_pop .iGor__linkBox .iGor__link {
    font-size: 10px;
    color: #000000;
    font-weight: 600;
  }
  .iGoRoom_login_pop .MuiListItem-root {
    border-bottom: 1px solid #E5E5E5;
    width: 100%;
    padding: 16px 0 19px 0;
  }
  .iGoRoom_login_pop .MuiListItem-root .MuiSvgIcon-root {
    font-size: 28px;
  }
  .iGoRoom_login_pop .MuiList-root {
    padding: 0 18px;
    margin-bottom: 20px;
  }
  .iGor_MB_loginBtn,
  .iGor__CreateAccountBtn,
  .iGOr_MB-primaryBtn {
    border-radius: 50px !important;
    color: #ffffff !important;
    font-size: 14px !important;
    height: 45px !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    width: 100% !important;
    max-width: 330px !important;
  }
  .iGor__loginBtnSocial {
    align-items: center;
  }
  .iGor_MB_loginBtn-Email {
    background: #c4c4c4 !important;
  }
  .iGor_addmb {
    margin-bottom: 10px !important;
  }
  .iGor_MB_loginBtn-Google {
    background: #2D3748 !important;
    margin-bottom: 10px !important;
    margin-top: 0 !important;
  }
  .iGor_MB_loginBtn-Facebook {
    background: #2057B4 !important;
    margin-bottom: 10px !important;
  }
  .iGor_MB_loginBtn-Apple {
    background: #000000 !important;
  }
}
@media screen and (max-width: 767px) {
  .iGor__SecondMenuMB .iGo_m-Menu {
    position: static;
  }
}
.iGor_CurrenciBtn {
  position: absolute !important;
  top: 3%;
  right: 1%;
  font-size: 0.625rem !important;
  color: #ffffff !important;
  font-weight: 600;
}
.iGor_CurrenciBtn .iGor_Curr_DollerIcon {
  color: #0AA83E;
  font-size: 1rem;
  margin-right: 0.25rem;
}
.iGor_CurrenciBtn .iGor_Curr_Arrow {
  font-size: 1.1875rem;
}

.iGor_currColor {
  color: #000000 !important;
}

.iGoR_curr_Modal {
  min-width: 40rem;
  padding: 1.5rem;
}
.iGoR_curr_Modal .modal-close {
  top: 20px;
  right: 20px;
  font-size: 24px;
}
.iGoR_curr_Modal .iGor_CurrSearch {
  display: flex;
  align-items: center;
  background: #F0F2F5;
  border-radius: 1rem;
  border: 0.0625rem solid #D0D6E3;
  margin: 0.625rem 0;
}
.iGoR_curr_Modal .iGor_CurrSearch .MuiInputBase-root {
  border-radius: 1rem;
}
.iGoR_curr_Modal .iGor_CurrSearch input {
  padding: 10px 20px !important;
}
.iGoR_curr_Modal .iGor_CurrSearch .iGoR_autoCom {
  background: #F0F2F5;
  width: 100%;
}
.iGoR_curr_Modal .iGor_CurrSearch .iGoR_autoCom .MuiOutlinedInput-root {
  padding: 0 1.875rem 0 0 !important;
}
.iGoR_curr_Modal .iGor_CurrSearch .iGoR_autoCom input {
  padding-left: 0 !important;
}
.iGoR_curr_Modal .iGor_CurrSearch .iGor_CurrSearchIcon {
  color: #C4C4C4;
  margin: 5px 0 5px 0.625rem;
}
.iGoR_curr_Modal .iGor_Curr-subTitle {
  color: #C4C4C4;
  margin-left: 0.9375rem !important;
}
.iGoR_curr_Modal .iGor_Curr_DollerVal {
  display: block;
}
.iGoR_curr_Modal .iGor_CurrSelect {
  background: #F0F2F5;
}
.iGoR_curr_Modal .iGor_CurrCard {
  display: flex;
  border-radius: 0.625rem;
  padding: 0.625rem 0.9375rem;
  align-items: center;
  justify-content: flex-start;
  width: 12.5rem;
  cursor: pointer;
}
.iGoR_curr_Modal .iGor_CurrCard .iGor_Curr_DollerIcon {
  color: #0AA83E;
  font-size: 1.625rem;
  padding: 0 !important;
  width: 20px;
  height: 20px;
  margin-right: 15px;
}
.iGoR_curr_Modal .iGor_CurrCard .iGor_Check {
  color: #0AA83E;
  font-size: 1.25rem;
}
.iGoR_curr_Modal .iGor_Curr-Scroll {
  overflow: hidden;
  overflow-y: auto;
  max-height: 130px;
}

.currency-flag {
  width: 100% !important;
  height: 100% !important;
  -o-object-fit: cover;
     object-fit: cover;
  filter: drop-shadow(0 0 1px rgba(0, 0, 0, 0.2));
}

@media screen and (max-width: 1024px) {
  .iGoR_curr_Modal {
    min-width: 100%;
  }
  .iGoR_curr_Modal .iGor_Curr-Scroll {
    max-height: 55vh;
    padding-bottom: 50px;
  }
}
.iGor_ProDetHeader {
  position: absolute !important;
  top: 0;
  z-index: 1;
}

.iGor-action_loader {
  display: flex;
  justify-content: center;
  align-items: center;
}
.iGor-action_loader svg {
  color: #ffffff;
  font-size: 1.2rem;
}

.iGor-facilities_default {
  display: flex;
  height: 100%;
}
.iGor-facilities_default svg {
  font-size: 0.7rem;
  margin-top: 5px;
}

.iGor__ProBackArrow {
  display: none !important;
}

.iGor__ProDetailsCardS {
  margin-top: 80px;
}

.iGor-Cancellation_policies_list {
  border-bottom: 0.0885rem solid #E6E6E6;
  margin: 15px 0 15px 0;
  width: 100%;
  padding: 0 !important;
}

.iGor-Cancellation_policies_room {
  display: flex;
  flex-direction: column !important;
  width: 100%;
}

.iGor-room_policies {
  text-align: justify;
}
.iGor-room_policies span {
  font-weight: 600;
}
.iGor-room_policies p {
  font-size: 0.65rem;
}

.iGor_Sold {
  font-weight: 600;
  color: #000;
  line-height: 1rem;
  font-size: 0.8rem;
}

.iGor_button_disable_room {
  color: #fff !important;
  background: #979797 !important;
}

.iGor_Recommendations_sold {
  display: flex;
  font-size: 1rem;
  font-weight: 600;
  margin-top: 5px;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.05rem;
  color: #ff4242;
}

.iGor__ProRoomBox {
  width: 420px;
  padding: 20px;
}
.iGor__ProRoomBox .iGor_d_roomBtnSectiom {
  text-align: center;
  padding-left: 1rem;
  margin-bottom: 1rem;
}
.iGor__ProRoomBox .iGor_d_roomBtnSectiom .iGor_alignCenter {
  padding-top: 31px;
}

.iGor_proDetWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
}
.iGor_proDetWrapper .rdrMonthsVertical {
  margin-bottom: 1rem;
}

.iGor_PropertyDetails {
  position: relative;
  height: 80vh;
  width: 100%;
  border-radius: 0 0 50px 50px;
  margin-bottom: 80px;
}
.iGor_PropertyDetails .iGor_topLocTitel {
  display: flex;
  align-items: center;
  align-content: center;
  font-size: 0.625rem;
}
.iGor_PropertyDetails .iGor_topLocTitel img {
  width: 10px;
  margin: 0 4px 0 10px;
}
.iGor_PropertyDetails .iGor_ProDetIframe {
  width: 100%;
  height: 100%;
  border: none !important;
}
.iGor_PropertyDetails .iGor_ProDetImage {
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.iGor_PropertyDetails:before {
  content: "";
  position: absolute;
  bottom: 0;
  height: 12.8125rem;
  background: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.8));
  left: 0;
  width: 100%;
  z-index: 1;
  border-radius: 0 0 50px 50px;
}
.iGor_PropertyDetails .iGor_iFrameimg {
  border-radius: 0 0 50px 50px;
  overflow: hidden;
  height: 100%;
  margin-bottom: 1.5625rem;
}
.iGor_PropertyDetails .iGor__prodetTop {
  position: relative;
}
.iGor_PropertyDetails .iGor_ProDetDescription {
  position: absolute;
  z-index: 1;
  bottom: -50px;
  width: 100%;
  display: flex;
}
.iGor_PropertyDetails .iGor_ProDetDescription .title-wrapper {
  display: flex;
  flex-direction: column;
  margin-left: 0.8125rem;
}
.iGor_PropertyDetails .iGor_ProDetDescription .title-wrapper .subtitle {
  display: flex;
}
.iGor_PropertyDetails .iGor_ProDetDescription .title-wrapper .subtitle span {
  color: #fff;
  font-size: 0.625rem;
  line-height: 1.125rem;
  margin-left: 0.25rem;
}
.iGor_PropertyDetails .iGor_ProDetDescription .title-wrapper .title {
  display: flex;
  margin-bottom: 1.25rem;
  color: #ffffff;
  align-items: baseline;
}
.iGor_PropertyDetails .iGor_ProDetDescription .btn-wrapper {
  display: flex;
  margin-bottom: 0.3125rem;
}
.iGor_PropertyDetails .iGor_ProDetDescription .btn-wrapper .iGor_wishListDetailsScreen {
  display: flex;
  align-items: center;
}
.iGor_PropertyDetails .iGor_ProDetDescription .btn-wrapper .iGor_btnIcon {
  width: 15px;
  height: 13px;
  margin-right: 0.3125rem;
  color: #ff6b6b;
}
.iGor_PropertyDetails .iGor_ProDetDescription .btn-wrapper img.iGor_btnIcon {
  width: 12px;
  height: 12px;
}
.iGor_PropertyDetails .iGor_ProDetDescription .btn-wrapper .btn-outline {
  display: flex;
  align-items: center;
  background-color: #fff !important;
  transition: background 0.3s ease;
  border: 0.0625rem solid #c4c4c4 !important;
  border-radius: 3.125rem !important;
  text-align: center;
  font-size: 0.625rem;
  color: #000;
  margin-left: 0.625rem;
  text-transform: capitalize;
  height: 40px;
}
.iGor_PropertyDetails .iGor_ProDetDescription .btn-wrapper .btn-outline .img-before {
  display: flex;
}
.iGor_PropertyDetails .box-review,
.iGor_PropertyDetails .box-review .value {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.iGor_PropertyDetails .box-review .text-value {
  border-bottom: 0.0625rem solid #000;
}
.iGor_PropertyDetails .box-review .text-value span {
  font-size: 0.625rem;
  line-height: 1.125rem;
  font-weight: 600;
  color: #000;
}
.iGor_PropertyDetails .box-review .value {
  background-color: #FF6B6B;
  border-radius: 0.9375rem;
  justify-content: center;
  width: 4.8125rem;
  height: 4.8125rem;
}
.iGor_PropertyDetails .box-review .value span {
  font-size: 1.875rem;
  font-weight: 600;
  margin-bottom: 0;
  line-height: 1.875rem;
  color: #fff;
}
.iGor_PropertyDetails .description .title-wrapper {
  display: flex;
  flex-direction: column;
  margin-left: 0.8125rem;
}

.iGor_ProDList {
  padding-top: 0px !important;
}
.iGor_ProDList span {
  font-weight: 600;
  line-height: 18px;
}
.iGor_ProDList li {
  padding-top: 0px;
}
.iGor_ProDList p {
  font-size: 0.625rem;
  line-height: 18px;
}
.iGor_ProDList .boxIcon {
  background: #E5E5E5;
  border-radius: 3.125rem;
  position: relative;
  width: 3.125rem;
  height: 3.125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 13px;
}
.iGor_ProDList .boxIcon .iGor_ProDetCheck {
  position: absolute;
  top: 1%;
  right: 1%;
  background: #00CEC9;
  border-radius: 3.125rem;
  color: #fff;
  padding: 0.125rem;
  width: 0.9375rem;
  height: 0.9375rem;
}

.iGor_ProDet-hotel-inf {
  padding-bottom: 30px;
  width: 100%;
}
.iGor_ProDet-hotel-inf .iGor__accoText {
  margin-left: 16px;
}
.iGor_ProDet-hotel-inf .iGor_ProDet-hotel-discription {
  margin: 0;
  font-family: Poppins, sans-serif;
  font-weight: 400;
  font-size: 10px;
  line-height: 18px;
  margin-top: 8px;
  margin-bottom: 8px;
  width: 100%;
}

.iGor_ProDet-hotel-info-list .list-icon {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.iGor_ProDet-hotel-info-list .list-icon .image.circle-grey {
  background-color: #e5e5e5;
  width: 2.9375rem;
  height: 2.9375rem;
  border-radius: 3.125rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.iGor_ProDet-hotel-info-list .list-icon .iGor_ProDetCheck {
  position: absolute;
  top: 0;
  right: 0;
  width: 0.9375rem;
  height: 0.9375rem;
  color: #00cec9;
  font-size: 0.5rem;
}
.iGor_ProDet-hotel-info-list .list-icon li {
  display: flex;
  margin-bottom: 1.25rem;
}

.iGor_ProDet-footer {
  padding: 1.5rem;
  background: #ffffff;
  margin-top: 1rem;
}
.iGor_ProDet-footer ul {
  list-style: none;
  display: flex;
  gap: 2.625rem;
  padding: 0;
  margin: 0;
  max-width: 100%;
  width: 100%;
}
.iGor_ProDet-footer ul .iGor-footer-links {
  text-decoration: none;
  cursor: pointer;
  color: black;
}
.iGor_ProDet-footer .igor_footerleft {
  justify-content: flex-end;
}
.iGor_ProDet-footer .igor_Test_credentials {
  margin-top: 20px;
  justify-content: flex-end;
}

.iGor_bar_mb {
  position: relative;
  margin-right: 0.6rem;
}

.iGor_searchbar-innerpage {
  box-shadow: 0 1px 1px 1px #e5e5e5;
  font-size: 0.75rem;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
  background: #ffffff;
  border-radius: 0 0 0.9375rem 0.9375rem;
  padding: 0.9375rem;
  display: flex;
  align-items: center;
}
.iGor_searchbar-innerpage .iGor_bar {
  position: relative;
  margin-right: 1.5rem;
}
.iGor_searchbar-innerpage .iGor_bar::after {
  content: "";
  content: "";
  width: 0.0625rem;
  height: 1.875rem;
  background: #E5E5E5;
  position: absolute;
  right: -1.5rem;
  top: 0rem;
}
.iGor_searchbar-innerpage .iGor_Ser_Title {
  font-size: 0.625rem;
  font-weight: 600;
}
.iGor_searchbar-innerpage .iGor_ser_SubTitle {
  font-size: 0.75rem;
  color: #C4C4C4;
  white-space: nowrap;
}
.iGor_searchbar-innerpage .iGor_setFlex {
  align-items: center;
}
.iGor_searchbar-innerpage .iGor_SerBtn {
  width: 105px;
  height: 40px;
  margin-left: 1rem;
  color: #ffffff;
}
.iGor_searchbar-innerpage .iGoe_ser_calIcon {
  margin-right: 1rem;
}
.iGor_searchbar-innerpage .iGoe_ser_UserIcon {
  margin: 0 1rem;
}

.iGor_noRooms-Available {
  padding-left: 20px;
}

.iGOr_card-listing {
  overflow: hidden;
  background-color: #fff;
  border-radius: 0.9375rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.25rem;
  position: relative;
}
.iGOr_card-listing .iGor_cardshear {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}
.iGOr_card-listing .top {
  display: flex;
  width: 100%;
}
.iGOr_card-listing .top .image {
  width: 734px;
  position: relative;
  cursor: pointer;
  margin-bottom: 0;
  height: 350px;
}
.iGOr_card-listing .top .image .img {
  height: 100%;
}
.iGOr_card-listing .top .image .img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 0.9375rem 0 0.9375rem 0.9375rem;
  background-color: #cac8c7;
}
.iGOr_card-listing .top .image .icon-wrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 5.625rem;
  height: 5.625rem;
  border-radius: 50%;
}
.iGOr_card-listing .top .image .icon-wrapper img {
  width: 5.625rem;
  height: 5.625rem;
}
.iGOr_card-listing .top .image .iGor_iconZoom {
  position: absolute;
  top: 5%;
  right: 2%;
  cursor: pointer;
}
.iGOr_card-listing .top .description {
  display: flex;
  flex-direction: column;
  padding: 1.625rem 3.625rem 0.9375rem 2.25rem;
  width: 40%;
  justify-content: space-between;
}
.iGOr_card-listing .top .description .iGor_RecommSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}
.iGOr_card-listing .top .description .iGor_RecommSection .iGor_Cancellation-Policies {
  width: 20%;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.iGOr_card-listing .top .description .iGor_RecommSection .iGor_Cancellation-Policies svg {
  font-size: 1.2rem;
  color: black;
}
.iGOr_card-listing .top .description .iGor_RecommSection .iGor_Ractive {
  font-size: 18px;
  color: #FF6B6B;
  margin-right: 5px;
  width: 5%;
}
.iGOr_card-listing .top .description .iGor_RecommSection .iGor_RDactive {
  font-size: 18px;
  color: #E5E5E5;
  margin-right: 5px;
  width: 5%;
}
.iGOr_card-listing .top .description .iGor_RecommSection .iGor_PriceReco {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.iGOr_card-listing .top .description .iGor_RecommSection .iGor_PriceReco p {
  font-size: 0.85rem;
}
.iGOr_card-listing .top .description .iGor_RecommSection .iGor_PriceReco span {
  padding-left: 2px;
  padding-top: 2px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 0.55rem;
  color: #c4c4c4;
}
.iGOr_card-listing .top .description .title h4 {
  color: #000;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
}
.iGOr_card-listing .top .description .head {
  display: flex;
  justify-content: space-between;
}
.iGOr_card-listing .top .description .head .title {
  margin-bottom: 1.125rem;
}
.iGOr_card-listing .top .description .head .title p:first-child {
  margin-bottom: 0.1875rem;
  font-size: 0.5rem;
  color: #C4C4C4;
}
.iGOr_card-listing .top .description .head .title img {
  margin-right: 0.3125rem;
}
.iGOr_card-listing .top .description .head .price {
  margin-bottom: 1.0625rem;
}
.iGOr_card-listing .top .description .head .price .price-wrapper {
  display: flex;
  margin-bottom: 0;
  align-items: center;
}
.iGOr_card-listing .top .description .head .price .price-wrapper .price-value {
  font-weight: 600;
  font-size: 1rem;
  color: #000;
  line-height: 1rem;
  margin-left: 3px;
}
.iGOr_card-listing .top .description .head .price .price-wrapper .currency {
  font-size: 0.75rem;
  margin-left: 0.25rem;
}
.iGOr_card-listing .top .description .head .price .price-wrapper .room {
  font-weight: 400;
  font-size: 0.5rem;
  color: #c4c4c4;
  margin-left: 0.25rem;
}
.iGOr_card-listing .top .description .head .price .price-info {
  font-weight: 400;
  font-size: 0.5rem;
  color: #c4c4c4;
}
.iGOr_card-listing .top .description .body {
  margin-bottom: 0.8125rem;
  border-bottom: 0.03125rem solid #e5e5e5;
  padding-bottom: 0.5rem;
  min-height: 160px;
}
.iGOr_card-listing .top .description .body .text-wrapper {
  margin-bottom: 0.625rem;
  min-height: 55px;
  text-align: justify;
}
.iGOr_card-listing .top .description .body .icon-wrapper .icon-room {
  font-size: 0.625rem;
  display: flex;
  margin-bottom: 11px;
}
.iGOr_card-listing .top .description .body .icon-wrapper .icon-room .title {
  font-weight: 600;
  margin-right: 0.3125rem;
}
.iGOr_card-listing .top .description .body .icon-wrapper .icon-room .value {
  margin-right: 3px;
}
.iGOr_card-listing .top .description .body .icon-wrapper .icon-room img {
  margin-right: 0.3125rem;
}
.iGOr_card-listing .top .btn-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.iGOr_card-listing .top .btn-wrapper .accordion-head {
  text-align: center;
  color: #c4c4c4;
  font-size: 0.75rem;
  font-weight: 600;
  margin-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  border-radius: 0.9375rem;
  overflow: hidden;
  background-color: #cac8c7;
}
.iframe iframe {
  width: 100%;
  height: 100%;
  border: none !important;
}

.iGor_accordion-body {
  width: 100%;
  border-radius: 0.9375rem;
  display: flex;
  height: 0px;
  padding: 0;
  overflow: hidden;
}
.iGor_accordion-body .inclusion ul {
  margin-top: 0.75rem;
  margin-left: 13px;
}
.iGor_accordion-body .inclusion ul li {
  padding-top: 8px;
}
.iGor_accordion-body ul {
  font-size: 0.625rem;
}
.iGor_accordion-body > div {
  display: block;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}
.iGor_accordion-body .facilities .btn-facilities {
  width: 170px;
  color: #000;
  border: 0.0625rem solid;
  border-radius: 0.6375rem;
  margin: 1rem 0;
  display: flex;
  justify-content: space-around;
  height: 35px;
  padding: 0 33px;
}
.iGor_accordion-body .facilities .btn-facilities p {
  color: #000;
  font-size: 0.75rem;
}
.iGor_accordion-body .iGor_Faci_listMain li {
  width: 100px;
  padding-right: 0 !important;
  align-items: flex-start;
}
.iGor_accordion-body .iGor_Faci_list {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 12.5rem;
}
.iGor_accordion-body .iGor_Faci_list .MuiListItemIcon-root {
  min-width: 25px;
}
.iGor_accordion-body .iGor_Faci_list li {
  padding-bottom: 0rem;
  padding-left: 0rem;
}
.iGor_accordion-body .iGor_Faci_list li svg {
  margin-right: 0.3125rem;
}
.iGor_accordion-body .iGor_Recommendations_sold {
  font-size: 10px;
}

.iGor_accordion_slide-left {
  animation: slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  height: auto;
  padding: 1.0625rem 0 0.9375rem 1.4115rem;
  overflow: hidden;
}
@keyframes slide-left {
  0% {
    position: relative;
    right: -70%;
  }
  100% {
    position: relative;
    right: 0%;
  }
}
.iGor_accordion_slide-right {
  animation: slide-right 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  position: relative;
  height: 0;
  overflow: hidden;
  animation-fill-mode: forwards;
}
@keyframes slide-right {
  0% {
    right: 0%;
  }
  96% {
    padding: 0;
  }
  100% {
    right: -60%;
    visibility: hidden;
    display: none;
  }
}
.iGor__Pro_Facility {
  margin-bottom: 60px !important;
}

.iGor_FacilAmen {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
}
.iGor_FacilAmen .iGor_FacilAmen_Icon {
  border: 0.0625rem solid #DCDCDC;
  padding: 0.9375rem;
  border-radius: 0.9375rem;
  width: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.iGor_FacilAmen .iGor_FacilAmen_Icon img {
  width: 2.5rem;
  height: 2.5rem;
}
.iGor_FacilAmen .iGor_FacilAmen_Icon p {
  margin-top: 0.3125rem;
}

.iGor_PropertyDAccordion .MuiPaper-root:first-child {
  border-radius: 1.25rem 1.25rem 0 0;
}
.iGor_PropertyDAccordion .MuiPaper-root:last-child {
  border-radius: 0 0 1.25rem 1.25rem;
}
.iGor_PropertyDAccordion .MuiSvgIcon-root {
  font-size: 0.75rem;
}
.iGor_PropertyDAccordion .ArrowRightIcon {
  color: #000000;
  font-size: 1.5rem;
}

.iGor_box-review {
  background-color: #ff6b6b;
  border-radius: 0.9375rem;
  justify-content: center;
  width: 4.8125rem;
  height: 4.8125rem;
  margin-bottom: 0.3125rem;
}
.iGor_box-review h6 {
  font-size: 1.875rem;
  font-weight: 600;
  margin-bottom: 0;
  line-height: 4.375rem;
  color: #fff;
  text-align: center;
}

.iGor_reviewCardSection {
  margin: 24px 0 !important;
}

.iGor_reviewCard {
  display: flex;
  flex-wrap: wrap;
}
.iGor_reviewCard .iGor_reviewCards {
  width: 50%;
  margin-bottom: 25px;
}
.iGor_reviewCard .iGor_reviewProfile {
  display: flex;
}
.iGor_reviewCard .iGor_reviewProfile img {
  width: 2.25rem;
  height: 2.25rem;
  margin: 0 0.4375rem 0.625rem 0;
}
.iGor_reviewCard .iGor_reviewContent {
  padding-right: 15%;
  cursor: pointer;
}

.iGor_ReviewBtn {
  width: 347px !important;
  margin: 1rem 0 !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  height: 44px !important;
}

.iGor_ProFacili_scroll {
  overflow-y: auto;
  max-height: 400px;
}

.iGor_ProFaciliList {
  padding: 0 !important;
  margin-bottom: 0.6rem !important;
}
.iGor_ProFaciliList li {
  border-bottom: 0.0625rem solid #E6E6E6;
  padding: 0.5rem 0;
}
.iGor_ProFaciliList .MuiListItemIcon-root {
  min-width: 1.875rem;
}
.iGor_ProFaciliList .MuiTypography-root {
  font-size: 0.75rem;
}

.iGor_proShareModal {
  width: 570px;
  height: 408px;
}
.iGor_proShareModal .close-button {
  left: -10px;
  top: 10px;
}
.iGor_proShareModal .close-button svg {
  font-size: 28px;
  color: #000000;
}

.iGor_proShareMain {
  margin-top: 3.125rem;
  padding: 0 0.5rem;
}
.iGor_proShareMain .iGor_sharebtnBox {
  display: flex;
  justify-content: space-between;
}
.iGor_proShareMain .iGor_imgSection {
  display: flex;
  align-items: center;
  margin: 1rem 0;
  margin-top: 1.563rem;
}
.iGor_proShareMain .iGor_imgSection .iGor_proimg {
  width: 5rem;
  height: 5rem;
  border-radius: 0.9375rem;
  margin-right: 1.5rem;
}
.iGor_proShareMain .iGor_sharebtn {
  border: 0.0625rem solid #D9D9D9 !important;
  border-radius: 0.625rem !important;
  padding: 0.625rem 0.9375rem !important;
  width: 15.688rem;
  height: 4.125rem;
  justify-content: flex-start !important;
  color: #000000 !important;
}
.iGor_proShareMain .iGor_sharebtn svg {
  margin-right: 1rem;
}
.iGor_proShareMain .iGor_sharebtn .iGor_shareFb {
  color: #4285F4;
}
.iGor_proShareMain .iGor_sharebtn .iGor_shareWhat {
  color: #ffffff;
  background: #34A853;
  padding: 0.1875rem;
  border-radius: 0.1875rem;
}

.iGor__ReadMoreBtn {
  font-size: 11px !important;
  color: #000 !important;
  background: #fff !important;
  padding: 0 !important;
  text-transform: capitalize !important;
}

.iGor__moreOption {
  height: 30px;
  overflow: hidden;
  cursor: pointer;
}

.iGOr__ProHotelFacAme {
  margin-top: 80px;
  background: #fff;
  padding: 1rem 0;
}

.iGor__SeeAllBtnPro {
  width: auto !important;
  padding: 0 5.57rem !important;
  font-size: 12px !important;
}

.wishListMod .wishList_header {
  display: flex;
  height: 62px;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #E5E5E5;
}
.wishListMod .iGor-wishListLoader {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: gray;
}
.wishListMod .iGor-wishListLoader svg {
  width: 22px;
  height: 22px;
}
.wishListMod .wishList_btnActive {
  border-bottom: 1px solid #E5E5E5;
  display: flex;
  align-items: center;
  padding: 1rem 0;
  cursor: pointer;
}
.wishListMod .wishList_btnTrip {
  border-bottom: 1px solid #E5E5E5;
  padding: 1rem 0;
  cursor: pointer;
}

@media screen and (max-width: 1024px) {
  .iGor__ProRoomBox .iGor_d_roomBtnSectiom {
    margin-bottom: 0;
  }
  .iGor_ProDet-hotel-inf .iGor_ProDet-hotel-discription {
    text-align: justify;
  }
  .iGor_ProFacili_scroll {
    max-height: 92%;
  }
  .iGor_proShareMain {
    max-width: 435px;
    margin: 60px auto;
  }
  .iGor_Faci_list {
    justify-content: space-between;
  }
  .iGOr_card-listing {
    border-radius: 0;
  }
  .iGOr_card-listing .top .image {
    height: 365px;
    width: 55% !important;
  }
  .iGOr_card-listing .description {
    width: 44% !important;
    padding-right: 20px !important;
  }
  .iGor__ProBackArrow {
    position: absolute !important;
    z-index: 1;
    min-width: 40px !important;
    height: 40px !important;
    top: 70px;
    left: 25px;
    opacity: 0.5px;
    background: rgba(0, 0, 0, 0.5019607843) !important;
    border-radius: 50px !important;
    display: block !important;
  }
  .iGor__ProBackArrow svg {
    width: 25px !important;
    height: 25px !important;
    color: #fff;
  }
  .iGor_reviewCard .iGor_reviewContent {
    padding: 0 !important;
    height: 59% !important;
    overflow: auto;
    padding-right: 18px !important;
    text-align: justify;
  }
  .iGor_reviewCard .iGor_reviewCards {
    width: 48%;
    margin-bottom: 25px;
    border: 0.5px solid #C4C4C4;
    border-radius: 10px;
    padding: 11px 0 18px 18px;
    margin-right: 6px;
    max-height: 108px;
    overflow-y: hidden;
  }
  .iGor_reviewCard .iGor_reviewCards100 {
    max-height: 100% !important;
  }
  .iGor_reviewCard .iGor_reviewCards100 .iGor_reviewContent {
    height: 100% !important;
  }
  .iGor_reviewSection {
    display: flex;
  }
  .iGor_reviewSection .d-flex {
    display: flex;
  }
  .iGor_reviewSection .iGor_box-review {
    width: 30px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .iGor_reviewSection .iGor_box-review h6 {
    font-size: 11px;
    line-height: normal;
  }
  .iGor_ProDetHeader img {
    display: none;
  }
  .iGor_ProDetHeader .iGor_m-menuBtn {
    position: absolute !important;
    min-width: 40px !important;
    height: 40px !important;
    top: 70px !important;
    left: 25px;
    right: auto !important;
    opacity: 0.5px;
    background: rgba(0, 0, 0, 0.5019607843) !important;
    border-radius: 50px !important;
  }
}
@media screen and (max-width: 600px) {
  .iGor_proShareMain .iGor_sharebtn {
    width: 12.688rem;
  }
  .iGor_reviewCardSection {
    margin-bottom: 0 !important;
  }
  .iGor__ProRoomBox {
    width: 100% !important;
    padding: 20px;
  }
  .iGOr__ProHotelFacAme {
    margin-top: -8px;
    position: relative;
  }
  .iGOr__ProHotelFacAme .iGor__SeeAllBtnPro {
    position: absolute;
    top: -7px;
    background: transparent;
    color: #FF6B6B;
    font-size: 8px;
    font-weight: 400;
    right: 16px;
    padding: 0 !important;
  }
  .iGOr__ProHotelFacAme .iGor__SeeAllBtnPro:active, .iGOr__ProHotelFacAme .iGor__SeeAllBtnPro:hover {
    background: transparent;
    color: #FF6B6B;
  }
  .iGor_ProDet-hotel-inf {
    padding-bottom: 14px !important;
    padding-top: 0 !important;
  }
  .iGor_ProDet-hotel-inf .iGor__accoText {
    margin-left: 0px;
  }
  .iGor_ProDet-hotel-inf .iGor_ProDet-hotel-info-des {
    padding-top: 0 !important;
  }
  .iGor_headerPro {
    padding: 0 !important;
  }
  .iGor__ProBackArrow {
    position: absolute !important;
    z-index: 1;
    min-width: 40px !important;
    height: 40px !important;
    top: 23px;
    left: 25px;
    opacity: 0.5px;
    background: rgba(0, 0, 0, 0.5019607843) !important;
    border-radius: 50px !important;
    display: block !important;
  }
  .iGor__ProBackArrow svg {
    width: 25px !important;
    height: 25px !important;
    color: #fff;
  }
  .iGor_accordion-body {
    padding: 0 10px 10px 25px;
    flex-direction: column;
  }
  .iGor_accordion-body .facilities {
    text-align: center;
  }
  .iGor_accordion-body .facilities .MuiTypography-subtitle1 {
    text-align: left;
  }
  .iGor_accordion-body .facilities .btn-facilities {
    margin: 0;
    width: 100% !important;
    max-width: 150px !important;
  }
  .iGor_accordion-body .inclusion,
  .iGor_accordion-body .details,
  .iGor_accordion-body .facilities {
    max-width: 100%;
    flex: 100%;
  }
  .iGor_accordion-body .inclusion ul {
    display: flex;
    flex-wrap: wrap;
    margin-top: 5px;
    margin-left: 10px;
    margin-bottom: 10px;
  }
  .iGor_accordion-body .inclusion ul li {
    width: 150px;
    padding-right: 20px;
  }
  .iGor_accordion-body .iGor_Faci_list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    height: 100%;
  }
  .iGor_accordion-body .iGor_Faci_list li {
    width: 108px;
    padding: 0 0 8px 0;
  }
  .iGor_PropertyDetails {
    height: 75vh;
    border-radius: 0 0 15px 15px !important;
  }
  .iGor_PropertyDetails .subtitle {
    display: flex;
  }
  .iGor_PropertyDetails:before {
    border-radius: 0 0 15px 15px !important;
  }
  .iGor_PropertyDetails .iGor_iFrameimg {
    border-radius: 0 0 15px 15px;
  }
  .iGor_PropertyDetails .iGor_ProDetDescription {
    bottom: -49px;
  }
  .iGor_PropertyDetails .iGor_ProDetDescription .title h2 {
    line-height: 1.5;
  }
  .iGor_PropertyDetails .iGor_ProDetDescription .box-review .value {
    width: 55px;
    height: 55px;
  }
  .iGor_PropertyDetails .iGor_ProDetDescription .box-review .value span {
    font-size: 22px !important;
  }
  .iGor_PropertyDetails .iGor_ProDetDescription .box-review .value .text-value span {
    font-size: 8px !important;
  }
  .iGor_PropertyDetails .iGor_ProDetDescription .title-wrapper .title {
    flex-direction: column;
    margin-bottom: 12px;
  }
  .iGor_PropertyDetails .iGor_ProDetDescription .title-wrapper .title h2 {
    font-size: 12px !important;
    font-weight: 600 !important;
  }
  .iGor_PropertyDetails .iGor_ProDetDescription .btn-wrapper .btn-outline {
    margin-left: 0 !important;
  }
  .iGor_PropertyDetails .iGor_ProDetDescription .btn-wrapper .btn-outline:last-child {
    margin-left: 10px !important;
  }
  .iGor_PropertyDetails .iGor_ProDetDescription .iGor_topLocTitel {
    margin-left: -3px;
  }
  .iGor_PropertyDetails .iGor_ProDetDescription .iGor_topLocTitel img {
    margin: 0;
    display: flex;
  }
  .iGOr_card-listing {
    border-radius: 0;
  }
  .iGOr_card-listing .iframe {
    position: relative;
    border-radius: 0;
  }
  .iGOr_card-listing .top {
    flex-direction: column;
  }
  .iGOr_card-listing .top .description {
    width: 100% !important;
    padding: 10px 25px !important;
  }
  .iGOr_card-listing .top .description .body {
    min-height: auto;
  }
  .iGOr_card-listing .top .description .head .price,
  .iGOr_card-listing .top .description .head .title {
    margin-bottom: 0 !important;
  }
  .iGOr_card-listing .top .description .title h4 {
    font-size: 12px;
  }
  .iGOr_card-listing .top .description .text-wrapper {
    min-height: auto !important;
  }
  .iGOr_card-listing .top .description .price-value {
    font-size: 12px;
  }
  .iGOr_card-listing .top .description .iGor__ReadMoreBtn {
    font-size: 8px !important;
  }
  .iGOr_card-listing .top .image {
    width: 100% !important;
    height: 164px;
  }
  .iGOr_card-listing .top .image .img {
    height: 100%;
  }
  .iGOr_card-listing .top .image .img img {
    border-radius: 0 !important;
  }
  .iGOr_card-listing .iGor_cardshear,
  .iGOr_card-listing .iGor_iconZoom {
    display: none;
  }
  .iGOr_card-listing .description .icon-wrapper {
    display: flex;
  }
  .iGOr_card-listing .description .icon-wrapper .icon-room {
    margin-right: 1.5rem;
  }
  .iGor__Pro_Facility {
    margin-bottom: 0px !important;
  }
  .iGor_FacilAmen {
    max-width: 95vw;
    overflow-x: auto;
    margin: 0 !important;
  }
  .iGor_FacilAmen .iGor_FacilAmen_Icon {
    margin: 0 0.3125rem 8px 0.3125rem;
    padding: 8px 16px;
  }
  .iGor_searchbar-innerpage .iGor_bar::after {
    display: none;
  }
  .iGor__ListSection {
    max-width: 96vw;
    overflow-x: scroll;
  }
  .iGor__ListSection .iGor_ProDList {
    display: flex;
    align-items: flex-start;
    margin-top: 12px;
    padding-top: 0;
    padding-bottom: 5px;
  }
  .iGor__ListSection .iGor_ProDList:first-child {
    margin-left: 2px;
  }
  .iGor__ListSection .iGor_ProDList .MuiListItem-root {
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2509803922);
    border-radius: 10px;
    margin-right: 10px;
    padding: 7px 9px;
  }
  .iGor__ListSection .iGor_ProDList .MuiListItem-root:last-child {
    margin-right: 20px;
  }
  .iGor__ListSection .iGor_ProDList .MuiListItemAvatar-root {
    min-width: auto;
  }
  .iGor__ListSection .iGor_ProDList .MuiListItemAvatar-root .boxIcon {
    margin-right: 9px;
  }
  .iGor__ListSection .iGor_ProDList .MuiListItemText-root {
    width: 90px;
  }
  .iGor__ListSection .iGor_ProDList p {
    display: none;
  }
  .iGor_searchbar-innerpage {
    margin-top: 20px;
    border-radius: 50px !important;
    width: 90% !important;
    height: 45px;
    align-items: center;
  }
  .iGor_searchbar-innerpage .iGor_Ser_Title {
    display: none;
  }
  .iGor_searchbar-innerpage .iGor_ser_SubTitle {
    font-size: 10px;
    color: #000000;
  }
  .iGor__HotelFacHead,
  .iGor_reviewSection {
    background: #f1f5f9;
    margin: -16px !important;
    padding: 10px 16px;
    margin-bottom: 20px !important;
    height: 40px;
  }
  .iGor_reviewSection {
    width: 110%;
    padding: 12px 16px !important;
    margin-left: -16px !important;
    margin-top: 10px !important;
    margin-bottom: 0 !important;
  }
  .iGor__Pro_ThtoKnow {
    margin-top: 13px !important;
  }
  .iGor__Pro_ThtoKnow > .MuiTypography-root {
    padding-left: 3px;
    background: #f1f5f9;
    margin: -16px;
    padding: 10px 16px;
    margin-bottom: 10px;
    height: 40px;
    margin-top: 10px;
  }
  .iGor__Pro_ThtoKnow > .MuiBox-root {
    padding: 0rem 0px 5px 0px !important;
  }
  .iGOr__ProDeMap {
    margin-bottom: 100px;
  }
  .iGor__ProDetailsCardS {
    padding: 0 0px 15px 0px !important;
    margin-top: 65px;
  }
  .iGor__ProDetailsCardS .iGor_procardAvil {
    padding: 13px 0 8px 16px !important;
  }
  #iGor_propertyDateP .rdrMonthAndYearWrapper {
    margin: 0 10px !important;
  }
}
.iGor_Ex-Destination {
  display: flex;
  padding: 0.9375rem 1.0625rem;
  max-width: 713px;
  cursor: pointer;
  margin: 0 !important;
  height: 63px;
}

.iGo__ExploreSearch {
  height: 63px;
  width: 100%;
}

.IGor_ExploerTitle {
  font-size: 1.75rem !important;
  color: #ffffff;
  font-weight: 600 !important;
}

.IGor_ExploerSub {
  font-size: 1rem !important;
  color: #ffffff;
}

.iGo_Explore-close {
  position: absolute;
  top: 5%;
  right: 0%;
}
.iGo_Explore-close .close-button {
  color: #fff;
  border: 0.0625rem solid #ffffff;
  border-radius: 25px;
  font-size: 12px;
  padding: 0.3125rem 0.6875rem;
  width: 111px;
  height: 40px;
}
.iGo_Explore-close .close-button svg {
  font-size: 17px;
  margin-right: 0.375rem;
}

.iGor_card-explorer {
  position: relative;
  max-width: 14.6875rem !important;
  height: 20.8125rem;
  width: 100%;
  overflow: hidden;
  margin-top: 0.0625rem;
  cursor: pointer;
}
.iGor_card-explorer:hover .iGor_image {
  max-height: 5.625rem;
}
.iGor_card-explorer .iGor_image {
  position: relative;
  overflow: hidden;
  border-radius: 0.9375rem;
  max-height: 100%;
  height: 100%;
  transition: max-height 0.5s cubic-bezier(0.16, 1, 0.3, 1);
}
.iGor_card-explorer .iGor_image .img {
  transform: translateY(0);
  transition: transform 0.5s cubic-bezier(0.16, 1, 0.3, 1);
  height: 100%;
}
.iGor_card-explorer .iGor_image .img img {
  width: 100% !important;
  min-height: 20.625rem !important;
  -o-object-fit: cover;
  object-fit: cover;
}
.iGor_card-explorer .iGor_text {
  position: absolute;
  bottom: 1.125rem;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  width: 100%;
}
.iGor_card-explorer .iGor_text h3 {
  color: #fff;
  text-shadow: 0 0 0.25rem #000;
  margin-bottom: 0;
  line-height: 1.5rem;
  font-size: 16px;
}
.iGor_card-explorer .iGor_body {
  max-height: 0;
  position: relative;
  padding: 1.4375rem 1.0625rem 1.125rem;
  transition: max-height 0.5s cubic-bezier(0.16, 1, 0.3, 1);
}
.iGor_card-explorer .iGor_body .iGor_title {
  font-weight: 600;
  font-size: 0.75rem;
  color: #fff;
  margin-bottom: 0.25rem;
}
.iGor_card-explorer .iGor_body .iGor_description {
  height: 9.0625rem;
  overflow: hidden;
  text-overflow: clip;
  color: #fff;
}
.iGor_card-explorer:hover .body {
  max-height: 100%;
}

.iGo__ExploreSilder {
  margin-top: 2rem;
}
.iGo__ExploreSilder .slick-prev,
.iGo__ExploreSilder .slick-next {
  top: 115% !important;
}
.iGo__ExploreSilder .slick-prev {
  left: 0% !important;
}
.iGo__ExploreSilder .slick-next {
  left: 3% !important;
}

.favTripCard {
  height: 262px;
  border-radius: 15px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: normal;
  align-items: stretch;
  padding: 13px;
  margin-top: 14px;
  margin-left: 10px;
}
.favTripCard .favTrip_ImgSctionRight {
  display: flex;
  flex-direction: column;
  justify-content: normal;
  align-items: stretch;
}
.favTripCard .favTrip_imgSection {
  display: flex;
  flex-direction: row;
  justify-content: normal;
  align-items: stretch;
}
.favTripCard .favTrip_imgSection .favTrip_leftImg,
.favTripCard .favTrip_imgSection .favTrip_rightImg {
  margin: 2px;
  cursor: pointer;
}
.favTripCard .favTrip_leftImg {
  width: 214px;
  height: 194px;
  border-radius: 10px;
}
.favTripCard .favTrip_rightImg {
  width: 120px;
  height: 96px;
  border-radius: 0px 10px 10px 0px;
}
.favTripCard .favTrip_title {
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  color: #000;
  margin-top: 8px;
}
.favTripCard .favTrip_date {
  font-size: 10px;
  font-weight: 400;
  line-height: 18px;
  color: #757575;
}

.iGorFav_ArticalCard {
  display: flex;
  flex-wrap: wrap;
}

.articalCard {
  width: 220px;
  height: 261px;
  padding: 15px 20px;
  border-radius: 15px;
  background: #ffffff;
  margin-top: 14px;
  margin-left: 10px;
  cursor: pointer;
}
.articalCard .artical_img {
  width: 179px;
  height: 171px;
  border-radius: 10px;
}
.articalCard .artcal_title {
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  margin-bottom: 5px;
}
.articalCard .artcal_subtitle {
  font-size: 8px;
  font-style: italic;
  font-weight: 300;
  line-height: 14px;
  color: #757575;
}

.iGor_WhishListTab {
  background: #ffffff !important;
  color: #000 !important;
  font-size: 10px !important;
  margin-right: 8px !important;
  text-transform: capitalize !important;
  border-radius: 5px !important;
}

.iGor_WhishListTab.iGor_tabActive {
  background: #757575 !important;
  color: #ffffff !important;
}

.iGor__ExpMoreSidelerBox .iGor__ExpMoreSidelerTitle {
  margin-bottom: 14px;
}
.iGor__ExpMoreSidelerBox .iGOr_ExBtnFilterWrapper {
  margin-bottom: 16px;
}
.iGor__ExpMoreSidelerBox .iGOr_ExBtnFilterWrapper .iGor__ExBtnFilterActive {
  background: #FF6B6B !important;
  color: #ffffff !important;
}
.iGor__ExpMoreSidelerBox .iGOr_ExBtnFilterWrapper .iGor__ExBtnFilter {
  margin-bottom: 4px;
  min-width: auto;
  padding: 6px 10px;
}

@media screen and (max-width: 1024px) {
  .iGo_Explore-Model {
    padding: 0 !important;
  }
  .iGo__ExploreSilder {
    width: 100vw;
  }
  .iGo__ExploreSilder .slick-slide {
    padding: 0 5px;
  }
  .iGo__ExploreSilder .iGor_body {
    padding: 10px 0 0 0;
    text-align: center;
  }
  .iGo__ExploreSilder .iGor__ExploreBtn {
    padding: 0;
    height: 25px;
    margin-top: 16px;
  }
  .iGo__ExploreSilder .slick-prev {
    left: 35% !important;
    font-size: 35px;
  }
  .iGo__ExploreSilder .slick-next {
    left: 50% !important;
    font-size: 35px;
  }
  .iGo_Explore-close {
    top: -20%;
    left: 50%;
    transform: translate(-50%, 10px);
  }
  .iGo__Exp-closeSection {
    text-align: center;
  }
  .iGo__Exp-closeSection .close-button {
    padding: 0;
    border: none;
  }
  .iGo__Exp-closeSection .close-button svg {
    font-size: 35px;
    margin: 0;
  }
  .iGorFav_ArticalCard {
    flex-wrap: nowrap !important;
    overflow-x: auto;
    width: 95vw;
  }
  .iGorFav_ArticalCard .articalCard {
    margin-left: 0 !important;
    margin-right: 10px !important;
  }
  .favTripCard {
    flex-direction: row;
    height: 165px;
    width: 100% !important;
  }
  .favTripCard .favTrip_skeletonContent,
  .favTripCard .favTrip_imgSection {
    width: 50%;
    margin-right: 1rem;
  }
  .favTripCard .favTrip_skeletonContent .favTrip_leftImg,
  .favTripCard .favTrip_imgSection .favTrip_leftImg {
    width: 100%;
    height: 100%;
  }
  .favTripCard .favTrip_date {
    padding-top: 90px;
    font-size: 10px;
    font-weight: 400;
    line-height: 18px;
    color: #757575;
  }
  .favTripCard .favTrip_date span {
    font-weight: 600;
    color: #000;
  }
  .iGor__ExpMoreSidelerBox .iGor__ExpMoreSidelerTitle {
    margin-bottom: 11px;
  }
}
.iGor_orderSumHeader {
  padding: 1.875rem 0;
  background: #FFFFFF;
  margin-bottom: 1.875rem;
}
.iGor_orderSumHeader .iGor_orderBack {
  font-size: 0.875rem;
  font-weight: 600;
  color: #000000 !important;
  border-radius: 0.625rem !important;
}
.iGor_orderSumHeader .iGor_orderBack .iGor_orderBackIcon {
  background: #F1F5F9;
  border-radius: 15px;
  margin-right: 10px;
  padding: 0.1875rem;
  width: 35px;
  height: 35px;
}
.iGor_orderSumHeader .iGor_OrderBtn {
  font-size: 0.875rem;
  font-weight: 600;
  color: #D8D8D8;
  border-radius: 0.625rem !important;
  padding: 0;
}
.iGor_orderSumHeader .iGor_OrderBtn:hover {
  background: transparent !important;
  cursor: none;
}
.iGor_orderSumHeader .iGor_OrderBtn .iGor_orderArrow {
  margin: 30px;
}
.iGor_orderSumHeader .iGor_OrderBtnActive {
  color: #000000;
  pointer-events: none;
}
.iGor_orderSumHeader .iGor_OrderBtnActive .iGor_OrderBtnIcon {
  width: 2.5rem;
  height: 2.5rem;
  background: linear-gradient(#FF6B6B, #EE5253);
  border-radius: 3.125rem;
  color: #ffffff;
  padding: 0.625rem;
  margin-right: 0.75rem;
}
.iGor_orderSumHeader .iGor_OrderBtnActive:hover {
  background: transparent !important;
}

.iGor_mainTitle {
  font-size: 3rem;
}

.iGor_Guestdetails .iGor__listGuest th {
  padding: 0 15px 15px 15px;
}
.iGor_Guestdetails .iGor__listGuest td {
  padding: 15px;
  border-left: 1px solid #e0e0e0;
}
.iGor_Guestdetails .iGor__listGuest td:first-child {
  border-left: 0;
}
.iGor_Guestdetails .MuiAccordionSummary-content {
  align-items: center;
}
.iGor_Guestdetails .MuiAccordionSummary-content svg {
  color: #FF6B6B;
  margin-right: 5px;
}
.iGor_Guestdetails .Mui-expanded {
  margin: 0 !important;
}
.iGor_Guestdetails .MuiAccordionDetails-root {
  padding: 0 !important;
}
.iGor_Guestdetails .iGor_GuestRadioSection {
  margin-left: 1.5rem;
}
.iGor_Guestdetails .iGor_userIcon {
  margin-right: 1rem;
}
.iGor_Guestdetails .iGor_orderAnotherper {
  padding: 0 1.5rem;
}
.iGor_Guestdetails .iGor_orderGuestTitles {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 20px 0 10px 0;
}
.iGor_Guestdetails .iGor_orderGuestTitles .iGor_titles {
  display: flex;
  width: 50%;
  flex-direction: column;
}
.iGor_Guestdetails .iGor_orderGuestTitles p {
  display: flex;
  flex-direction: row;
}
.iGor_Guestdetails .iGor__OrderSymList {
  overflow: auto;
}

.iGor_OrderRegBtn {
  padding: 1.25rem 1.875rem;
  margin-bottom: 1rem;
}
.iGor_OrderRegBtn .iGor__OrderJoinNowBtn {
  height: 40px;
  width: 164px;
  font-size: 12px;
}

.iGor_RoomName-Clamped {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  /* Number of lines to display */
  -webkit-line-clamp: 2;
  /* Limit to 2 lines */
  margin-top: 0px !important;
}

.iGoi_OrderSumLeftCrad {
  padding: 1.25rem 1.875rem;
}
.iGoi_OrderSumLeftCrad .iGoi_OrderSumTopTitle {
  margin-bottom: 17px;
}
.iGoi_OrderSumLeftCrad .iGor_taxandfees p {
  font-size: 0.75rem;
  font-weight: 600;
}
.iGoi_OrderSumLeftCrad .iGor_orderBedType .iGor_orderBedTyTilte {
  margin-top: 0.5rem;
  font-weight: 600;
}
.iGoi_OrderSumLeftCrad .iGoi_OrderSumImgCard {
  width: 235px;
  height: 126px;
  margin-bottom: 24px;
}
.iGoi_OrderSumLeftCrad .iGoi_OrderSumImgCard img {
  width: 100%;
  height: 100%;
}
.iGoi_OrderSumLeftCrad .iGor__ShowTitle {
  margin-bottom: 16px;
}
.iGoi_OrderSumLeftCrad .iGor__checkInOut {
  padding-bottom: 21px;
  border-bottom: 1px solid #D4D4D4;
  margin-bottom: 17px;
  display: flex;
  align-items: flex-end;
}
.iGoi_OrderSumLeftCrad .iGor-Policies {
  padding-bottom: 21px;
}
.iGoi_OrderSumLeftCrad .iGor_OrderSumList {
  padding-left: 22px;
  margin-bottom: 15px;
}
.iGoi_OrderSumLeftCrad .iGor_OrderSumList li P {
  font-size: 10px;
}
.iGoi_OrderSumLeftCrad .iGor_OrderSumList li::marker {
  font-size: 10px;
}
.iGoi_OrderSumLeftCrad .iGor_Icon_moon {
  text-align: center;
}

.iGor_OrderInfo {
  background: #FEFADC;
  border-left: 8px solid #F9DD4A;
  padding: 16px;
  border-radius: 0.625rem;
}
.iGor_OrderInfo svg {
  margin-right: 0.625rem;
}
.iGor_OrderInfo .iGor_Section {
  padding: 0 1.25rem 1.25rem 0;
}

.iGor_OrderCouponcode {
  margin-top: 1rem;
  padding: 1.25rem 1.875rem;
}
.iGor_OrderCouponcode .iGor_orderPromBtn {
  font-size: 0.75rem !important;
  text-transform: lowercase !important;
  color: #FF6B6B !important;
  text-decoration: underline !important;
}
.iGor_OrderCouponcode .iGor_OrderS_PromCode {
  border-radius: 3.125rem;
  background: #F1F5F9;
  width: 100%;
}
.iGor_OrderCouponcode .iGor_OrderS_PromCode fieldset {
  border: none;
}

.iGor_OrderSumDelBtn {
  position: absolute;
  bottom: 15%;
  cursor: pointer;
  right: -1.25rem;
}

.iGor_OrderAge {
  margin-top: 2rem !important;
}

.iGor_OrderAmount {
  font-size: 1.75rem !important;
  font-weight: 600 !important;
}

.iGor_paymentSection .iGor_Credit {
  background: #fff;
  border-radius: 3.125rem;
  font-size: 0.75rem;
  padding: 0.625rem 0.9375rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 10.9375rem;
  margin: 0 0.625rem;
}
.iGor_paymentSection .iGor_cardNumber {
  border-radius: 0.625rem;
  background: #fff;
  margin: 0.625rem 0;
}
.iGor_paymentSection .iGor_cardNumber input {
  font-size: 0.875rem !important;
}
.iGor_paymentSection .iGor_CvvNumber input,
.iGor_paymentSection .iGor_CardYear input {
  text-align: center;
}

.iGor-redirectBox {
  display: flex;
  flex-direction: column;
  height: 20vh;
  align-items: center;
}
.iGor-redirectBox span {
  width: 24px !important;
  height: 24px !important;
  margin-bottom: 10px;
  color: gray;
}
.iGor-redirectBox p {
  font-size: 12px;
  font-weight: 300;
  line-height: 15px;
}

.iGor_finishCard {
  background: #fff;
  border-radius: 0.9375rem;
  text-align: center;
  padding: 1.25rem;
  max-width: 21.375rem;
  margin: 0 auto;
  margin-top: 8%;
  margin-bottom: 2%;
}
.iGor_finishCard .iGor-finishText {
  display: flex;
  justify-content: center;
}
.iGor_finishCard .iGor-finishText p {
  width: 65%;
}
.iGor_finishCard .iGor-finishShareTrip {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.iGor_finishCard .iGor-finishShareTrip img {
  margin: 0 4px;
}
.iGor_finishCard .iGor_backToHome {
  cursor: pointer;
}

.iGor_amountBold {
  font-size: 0.75rem;
  font-weight: 600;
  color: #000000;
}

.iGor_radioBtn_Tags {
  padding-left: 200px;
}

.iGor_radioBtn .MuiSvgIcon-root:first-child {
  color: #E5E5E5 !important;
}
.iGor_radioBtn .MuiSvgIcon-root:last-child {
  color: #FF6B6B !important;
}

.iGor_selectOrder {
  border-radius: 25px !important;
  background: #f1f5f9;
}

.iGoi_OrderPayBox {
  margin-bottom: 200px;
}
.iGoi_OrderPayBox button {
  font-size: 12px;
}

.iGo__OrderSumPaybox {
  padding: 0 17px 4px 26px;
  text-align: center;
}

.iGor__OrderSymList {
  margin-left: 10px;
}
.iGor__OrderSymList .MuiListItemAvatar-root {
  min-width: 30px;
}

.iGor__orderSumSticky {
  position: sticky;
  top: 25px;
}

.iGor__UpGradeMyRoom .image,
.iGor__UpGradeMyRoom .description {
  width: 70% !important;
}
.iGor__UpGradeMyRoom .description {
  padding: 22px 22px 15px 50px;
}
.iGor__UpGradeMyRoom .description .body .icon-wrapper {
  display: flex;
  justify-content: space-between;
}

.iGor-noService-Available {
  font-size: 0.8rem;
  font-weight: 500;
  font-family: "Poppins";
}

.iGor__GuestdetailsTilte {
  margin: 32px 0 16px 0 !important;
}

.iGor__OrdSumContainer {
  padding-bottom: 65px;
  min-height: calc(100vh - 250px);
  position: relative;
}

@media screen and (max-width: 1024px) {
  .iGor_orderSumJoinMod {
    padding: 0 !important;
  }
  .iGor_orderSumJoinMod .iGo_mb-menuTop {
    background: #ffffff;
  }
  .iGor_Guestdetails {
    border-radius: 10px !important;
    padding: 13px 20px !important;
  }
  .iGor_Guestdetails .iGor_userIcon {
    width: 17px;
    height: 17px;
  }
  .iGor__GuestdetailsTilte {
    margin: 15px 0 10px 0 !important;
  }
  .iGor__orderSumSticky {
    position: static !important;
  }
  .iGor__orderSumSection {
    margin-bottom: 200px;
  }
  .iGoi_OrderPayBox {
    margin-bottom: 0px;
    position: fixed;
    width: 100%;
    bottom: 0;
    background: #fff;
    left: 0;
    border-radius: 15px 15px 0 0;
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .iGoi_OrderPayBox button {
    width: 341px;
  }
  .iGor_orderSumHeader {
    background-color: transparent;
    margin-bottom: 0 !important;
    padding-bottom: 0;
  }
  .iGor_orderSumHeader .iGor_orderBack {
    padding: 8px 0;
  }
  .iGor_orderSumHeader .iGor_orderBackIcon {
    background: #BFCCD9 !important;
    color: #fff;
    width: 25px !important;
    height: 25px !important;
    border-radius: 10px !important;
  }
  .iGor__orderSumSection {
    height: 100% !important;
  }
  .iGor_Guestdetails .iGor_GuestRadioSection {
    margin-left: 0rem;
  }
  .iGor_Guestdetails .iGor_orderAnotherper {
    padding: 0;
  }
  .iGor_Guestdetails .iGor_orderAnotherper .MuiSelect-select {
    padding: 10px !important;
  }
  .iGor_OrderInfo .iGor_Section {
    padding: 0;
  }
  .iGor_OrderInfo svg {
    display: none;
  }
  .iGoi_OrderSumLeftCrad {
    margin-bottom: 15px;
  }
  .iGoi_OrderSumLeftCrad .iGor_RoomName-Clamped {
    line-height: 1.5;
  }
  .iGoi_OrderSumLeftCrad .iGor_orderBedType {
    display: flex;
    align-content: center;
    margin-top: 5px;
    margin-bottom: 10px;
  }
  .iGoi_OrderSumLeftCrad .iGor_orderBedType .iGor_orderBedTyTilte {
    margin-top: 0;
    margin-right: 24px;
  }
  .iGoi_OrderSumLeftCrad .iGoi_OrderSumTopTitle {
    margin: 0;
  }
  .iGoi_OrderSumLeftCrad .iGoi_OrderSumImgCard {
    width: 137px;
    height: 87px;
    margin-bottom: 10px;
  }
  .iGoi_OrderSumLeftCrad .iGor__checkInOut {
    padding-bottom: 0px;
    border-bottom: none;
    margin-bottom: 0px;
  }
  .iGoi_OrderSumLeftCrad .iGor-Policies {
    padding-bottom: 0;
  }
  .iGoi_OrderSumMainTitle {
    position: relative;
    left: 0%;
    transform: translate(-50%, -2px);
    font-size: 12px !important;
  }
  .iGor_OrderRegBtn {
    padding: 18px 16px 18px 20px;
    display: flex;
    align-items: center;
    border-radius: 10px;
    margin-top: 15px;
  }
  .iGor_OrderRegBtn .MuiTypography-subtitle1 {
    font-size: 8px;
  }
  .iGor_OrderRegBtn .iGor__OrderJoinNowBtn {
    height: 25px;
    width: 75px;
    font-size: 8px !important;
    font-weight: 600 !important;
    letter-spacing: 0.005em;
    border-radius: 50px !important;
  }
  .iGor_paymentSection {
    height: auto !important;
    padding: 0 !important;
  }
  .iGoi_OrderPaypl {
    position: fixed;
    text-align: center;
    bottom: 0;
    width: 100%;
    background: #ffffff;
    padding: 25px;
    border-radius: 15px 15px 0 0;
  }
  .iGoi_OrderPaypl .iGo__OrderSumPaybox {
    margin: 0;
    padding: 0;
  }
  .iGor_orderAnotherper .iGor__orderField {
    justify-content: flex-end;
  }
  .iGor_orderAnotherper .iGor__orderField input {
    padding: 10px 15px;
    font-size: 11px;
  }
  .iGor_OrderSumRoomDetails {
    padding: 0;
  }
  .iGor_OrderSumRoomDetails .iGoi_OrderSumImgHeader {
    padding: 1.25rem 1.875rem 0;
  }
  .iGor_OrderSumRoomDetails .iGor_PropertyDAccordion .MuiPaper-root:first-child {
    border-radius: 0;
    border: 0;
    border-bottom: 1px solid #C4C4C4;
  }
  .iGor_OrderSumRoomDetails .iGor_PropertyDAccordion .MuiPaper-root:last-child {
    border: 0;
  }
}
.iGoR_AccountCard {
  padding: 20px 30px;
}

.iGoR_SideCard {
  padding: 20px 30px;
}
.iGoR_SideCard .iGor_otherInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 15px;
}
.iGoR_SideCard .iGor_otherInfo a {
  text-decoration: none;
  color: #000000;
  margin-bottom: 16px;
}
.iGoR_SideCard .iGor_otherInfo a p {
  cursor: pointer;
  font-weight: 500;
  font-size: 0.875rem;
}
.iGoR_SideCard .iGor_Accountlist .iGor_UserIcon {
  min-width: 30px;
}
.iGoR_SideCard .iGor_Accountlist .iGor_UserTitle span {
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
}
.iGoR_SideCard .iGor_checkpoint {
  color: #FF6B6B;
  text-decoration: underline;
}

.iGor-identityBox p {
  font-size: 12px;
  color: #757575;
  line-height: 18px;
}

.iGor_Number {
  color: #FF6B6B;
  border: 1px solid #FF6B6B;
  padding: 5px;
  border-radius: 50px;
  width: 35px;
  height: 35px;
  text-align: center;
  margin-top: 1.5rem;
}

.iGor_UploadSection {
  background-color: #F1F5F9;
  border-radius: 10px;
  padding: 20px;
  align-items: center;
}
.iGor_UploadSection .iGor_UploadImg {
  width: 53px;
  height: 53px;
  border-radius: 50%;
}
.iGor_UploadSection .iGor_UploadBtn {
  color: #FF6B6B;
  font-size: 10px;
  margin-left: 0.625rem;
  white-space: nowrap;
}
.iGor_UploadSection .iGor_UploadBtn .iGor_UploadTxt {
  border-bottom: 1px solid #FF6B6B;
}

.iGoR_AccGoogleBtn {
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #ffffff !important;
  background: #4285F4 !important;
  border-radius: 50px !important;
  height: 40px;
  text-transform: unset !important;
  width: 40%;
}
.iGoR_AccGoogleBtn .svg1 {
  width: 19px;
  margin-right: 5px;
}
.iGoR_AccGoogleBtn .svg2 {
  width: 20px;
  height: 11.4px;
  margin-left: 2px;
}

.iGor_AccountEditBtn {
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #000000 !important;
  text-transform: capitalize !important;
  text-decoration: underline !important;
}

.iGor_currencyEdit .iGoR_autoCom {
  width: 100%;
  border: 1px solid #c4c4c4;
  margin-top: 10px;
}
.iGor_currencyEdit .iGoR_autoCom .MuiInputBase-root {
  padding: 0 !important;
}

.iGo__AccountTextField {
  margin-top: 10px !important;
  margin-right: 5px !important;
}
.iGo__AccountTextField input {
  padding: 8px;
}

@media screen and (max-width: 1024px) {
  .iGoR_AccountCard {
    padding: 1px 16px;
  }
  .iGor_AccountTitleMB {
    text-align: center;
  }
}
.iGor__banner-explorer {
  position: relative;
  margin-bottom: 2.0625rem;
}
.iGor__banner-explorer .iGor__header-explorer {
  position: absolute;
  z-index: 1;
}
.iGor__banner-explorer .iGor__image {
  max-height: 29.125rem;
  border-radius: 0 0 1.5625rem 1.5625rem;
  overflow: hidden;
  height: 470px;
  position: relative;
}
.iGor__banner-explorer .iGor__image::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.35);
}
.iGor__banner-explorer .iGor__image .iframe {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: 50% 50%;
     object-position: 50% 50%;
  height: 100%;
}
.iGor__banner-explorer .iGor__image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.iGor__banner-explorer .iGor__image .noImageFound {
  -o-object-fit: scale-down;
     object-fit: scale-down;
}
.iGor__banner-explorer .iGor__description {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.iGor__banner-explorer .iGor__description .iGor__title {
  font-size: 3.625rem;
  font-weight: 600;
  line-height: 5.4375rem;
  margin-bottom: 0;
  color: #ffffff;
}
.iGor__banner-explorer .iGor__description .iGor__subtitle {
  font-size: 20px;
  color: #fff;
  font-weight: 400;
  line-height: 1.875rem;
  text-transform: uppercase;
}

.iGor_ExMore_cards {
  max-width: 18.625rem;
  position: relative;
  margin-right: 0.625rem;
}
.iGor_ExMore_cards .iGor_Icon_InfoPro {
  position: absolute;
  right: 10px;
  top: 10px;
}
.iGor_ExMore_cards .iGor_ExMore_images .iGor_ExMore_img img {
  border-radius: 0.625rem;
  width: 100%;
  height: 13.125rem;
  -o-object-fit: cover;
     object-fit: cover;
  background-color: #dcdcdc;
}
.iGor_ExMore_cards .iGor_ExMore_description {
  padding: 0.75rem 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.iGor_ExMore_cards .iGor_ExMore_description .iGor_ExMore_btn {
  text-transform: uppercase;
  padding: 0 0.75rem;
  background-color: #fff;
  color: #ff6b6b;
  transition: background 0.3s ease;
  border: 0.03125rem solid #c4c4c4;
  padding: 0.25rem 0.75rem;
  border-radius: 0.1875rem;
}
.iGor_ExMore_cards .iGor_ExMore_description .iGor_ExMore_btn span {
  font-weight: 400;
  line-height: 0.5rem;
  font-size: 0.5rem;
}
.iGor_ExMore_cards .iGor_ExMore_description .iGor_ExMore_title {
  font-size: 0.75rem;
  font-weight: 600;
  color: #000;
  line-height: 0.875rem;
  margin-bottom: 0.3125rem;
  margin-top: 0.5rem;
  text-decoration: none;
  max-height: 1.875rem;
}

.iGor__findThigSlider .slick-prev,
.iGor__findThigSlider .slick-next,
.iGor__regionsSlider .slick-prev,
.iGor__regionsSlider .slick-next {
  color: #000 !important;
  font-size: 1.25rem;
}
.iGor__findThigSlider .slick-disabled,
.iGor__regionsSlider .slick-disabled {
  color: #C4C4C4 !important;
}
.iGor__findThigSlider .slick-prev,
.iGor__regionsSlider .slick-prev {
  left: 88.5% !important;
}
.iGor__findThigSlider .slick-next,
.iGor__regionsSlider .slick-next {
  right: 3% !important;
}

.iGor__findThigSlider .slick-prev,
.iGor__findThigSlider .slick-next {
  top: -13% !important;
}

.iGor__regionsSlider .slick-prev,
.iGor__regionsSlider .slick-next {
  top: -7% !important;
}
.iGor__regionsSlider .iGor__portrait {
  width: 11.375rem;
}
.iGor__regionsSlider .iGor__portrait .iGor_ExMore_images {
  height: 16.25rem;
  border-radius: 0.9375rem;
}
.iGor__regionsSlider .iGor__portrait .iGor_ExMore_images img {
  height: 260px;
}

.iGor__ExBtnFilter {
  background: #F1F5F9 !important;
  font-size: 0.625rem !important;
  border-radius: 0.3125rem !important;
  color: #000000 !important;
  margin-right: 0.3125rem !important;
  text-transform: none !important;
}

.iGor__card-offers {
  background-color: #fff;
  display: flex;
  max-width: 34.6875rem;
  border-radius: 0.9375rem;
  padding: 1.1875rem 0.9375rem 1.1875rem 1.125rem;
  margin-bottom: 0.875rem;
}
.iGor__card-offers .iGor__image {
  width: 13.8125rem;
  position: relative;
  cursor: pointer;
  margin-bottom: 0;
}
.iGor__card-offers .iGor__image .iGor__img {
  border-radius: 0.625rem;
  height: 9.375rem;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  background-color: #cac8c7;
}
.iGor__card-offers .iGor__image .iGor__icon-wishlist {
  right: 1.5rem;
  left: unset;
  top: 0.875rem;
  position: absolute;
}
.iGor__card-offers .iGor__description {
  padding-left: 1.0625rem;
  display: flex;
  flex-direction: column;
  width: 20.6875rem;
  justify-content: space-between;
  max-height: 12.9375rem;
}
.iGor__card-offers .iGor__description .iGor__head > p {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 8px;
  color: #C4C4C4;
  display: flex;
}
.iGor__card-offers .iGor__description h2 {
  font-weight: 600;
  font-size: 0.75rem;
  color: #000;
}
.iGor__card-offers .iGor__description .iGor__icon-location {
  color: #000000 !important;
  align-items: center;
}
.iGor__card-offers .iGor__description .iGor__icon-location svg {
  color: #FF6B6B;
  width: 10px;
  height: 10px;
}
.iGor__card-offers .iGor__description .iGor__body .iGor__price {
  margin-bottom: 0.25rem;
}
.iGor__card-offers .iGor__description .iGor__body .iGor__price p {
  font-weight: 400;
  font-size: 0.5rem;
  line-height: 0.75rem;
}
.iGor__card-offers .iGor__description .iGor__body .iGor__price .iGor__price-wrapper {
  display: flex;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 0.75rem;
  color: #000;
  line-height: 1.125rem;
}
.iGor__card-offers .iGor__description .iGor__body .iGor__price .iGor__price-wrapper .iGor__room {
  font-weight: 400;
  font-size: 0.5rem;
  line-height: 0.75rem;
  color: #c4c4c4;
  display: flex;
  align-items: center;
}
.iGor__card-offers .iGor__description .iGor__body .iGor__review {
  display: flex;
  align-items: center;
}
.iGor__card-offers .iGor__description .iGor__body .iGor__review .iGor__stars {
  line-height: 1rem;
  margin-right: 0.3125rem;
  display: flex;
  align-items: center;
}
.iGor__card-offers .iGor__description .iGor__body .iGor__review .iGor__stars .iGor_starIcon {
  color: #E26721;
  width: 8px;
}
.iGor__card-offers .iGor__description .iGor__body .iGor__review .iGor__stars .iGor_starIcon_not_rating {
  color: #83807e;
  width: 8px;
}
.iGor__card-offers .iGor__description .iGor__body .iGor__review .iGor__stars svg {
  color: #ff6b6b;
  font-size: 0.5rem;
}
.iGor__card-offers .iGor__description .iGor__body .iGor__review .iGor__ratings-value {
  background-color: #ff6b6b;
  margin-right: 0.3125rem;
  padding: 0 0.3125rem;
  border-radius: 1.25rem;
  height: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.iGor__card-offers .iGor__description .iGor__body .iGor__review .iGor__ratings-value span {
  font-size: 0.5rem;
  line-height: 0.5rem;
  font-weight: 600;
  color: #fff;
}

.iGor-NoDataOffersSection {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 10vh;
  font-size: 1.2rem;
  font-weight: 500;
  color: darkgray;
}

.iGor__card-offers.iGor__portrait {
  display: flex;
  flex-direction: column;
  max-width: 24.375rem;
  padding: 0;
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.15);
  border-radius: 0.625rem;
  margin-bottom: 2px;
}
.iGor__card-offers.iGor__portrait .iGor__body {
  display: flex;
  align-items: flex-end;
}
.iGor__card-offers.iGor__portrait .iGor__image {
  width: 100%;
}
.iGor__card-offers.iGor__portrait .iGor__description {
  padding: 0.5rem 1.5625rem 0.8125rem;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.iGor__ExptabSection {
  margin: 24px 0;
}

.iGor__ExploreTab {
  background: #F1F5F9;
  border-radius: 3.125rem;
  padding: 0.4375rem 0.3125rem;
  display: flex;
  justify-content: space-between;
  max-width: 21rem;
  text-transform: capitalize !important;
  margin: 0 auto;
}
.iGor__ExploreTab .iGor_ExploreTabs {
  background: transparent;
  color: #C4C4C4 !important;
  font-size: 0.625rem;
  border-radius: 3.125rem;
  padding: 0.4375rem 1.0625rem;
  margin: 0 auto;
  max-width: 21rem;
  text-transform: capitalize !important;
}
.iGor__ExploreTab .iGor_ExploreTabs:hover {
  background: linear-gradient(360deg, #FF6B6B, #EE5253);
  color: #fff !important;
}
.iGor__ExploreTab .iGor_ExTabsActive {
  background: linear-gradient(360deg, #FF6B6B, #EE5253);
  color: #fff !important;
}

.iGor__FilterBtnMain {
  color: #000 !important;
  border: 0.0625rem solid #C4C4C4 !important;
  border-radius: 3.125rem !important;
  font-size: 0.625rem !important;
  padding: 0.625rem 1.25rem !important;
  text-transform: capitalize !important;
}

.iGor-Explore-TabGrid {
  display: flex;
  flex-direction: column !important;
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .iGor__ExptabSection {
    margin-bottom: 10px;
  }
  .iGor__ExptabSection .iGor__ExploreTab {
    margin-top: 15px;
  }
  .iGor__ExpMoreSidelerBox {
    flex-direction: column-reverse !important;
  }
  .iGor__ExploreTab {
    margin-top: 10px;
  }
  .iGor__header-explorer {
    width: auto !important;
    right: 30px;
  }
  .iGor__header-explorer img {
    display: none;
  }
  .iGor__ExploreCardOff .iGor__card-offers {
    max-width: 100%;
  }
  .iGor__regionsSlider,
  .iGor_ExMore_cards {
    position: relative;
  }
  .iGor__regionsSlider .iGor_ExMore_description,
  .iGor_ExMore_cards .iGor_ExMore_description {
    position: absolute;
    bottom: 0;
  }
  .iGor__regionsSlider .iGor_ExMore_description .MuiTypography-root,
  .iGor__regionsSlider .iGor_ExMore_description .iGor_ExMore_title,
  .iGor__regionsSlider .iGor_ExMore_description .subtitle,
  .iGor_ExMore_cards .iGor_ExMore_description .MuiTypography-root,
  .iGor_ExMore_cards .iGor_ExMore_description .iGor_ExMore_title,
  .iGor_ExMore_cards .iGor_ExMore_description .subtitle {
    color: #ffffff;
  }
  .iGor__regionsSlider .iGor__portrait {
    width: 155px !important;
  }
  .iGor__regionsSlider .iGor__portrait .iGor_ExMore_images img {
    height: 211px;
  }
  .iGor__regionsSlider .slick-prev,
  .iGor__regionsSlider .slick-next {
    top: -10% !important;
  }
  .iGor__findThigSlider .slick-prev,
  .iGor__regionsSlider .slick-prev {
    left: 85.5% !important;
  }
  .iGor__findThigSlider .slick-prev,
  .iGor__findThigSlider .slick-next {
    top: -35% !important;
  }
  .iGor__banner-explorer .iGor__description .iGor__title {
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
  }
  .iGor__banner-explorer .iGor__description .iGor__subtitle {
    font-size: 16px;
    text-transform: uppercase;
    margin-top: 15px;
  }
  .iGor__card-offers .iGor__image .iGor__img {
    height: 139px;
    -o-object-fit: cover;
       object-fit: cover;
    background-color: #83807e;
  }
  .iGor__card-offers .iGor__description {
    border-radius: 25px 25px 0 0 !important;
  }
  .iGor__card-offers .iGor__description h2 {
    margin: 0.1rem 0;
  }
}
@media screen and (max-width: 767px) {
  .section-article-detail {
    width: 100vw;
  }
  .iGor-article_wrapper {
    padding: 0px !important;
  }
  .iGor__banner-article-details {
    padding-bottom: 0.5rem !important;
  }
  .iGor__banner-article-details .iGor__btn-icon {
    background-color: rgba(0, 0, 0, 0.5) !important;
  }
  .iGor__banner-article-details .iGor__btn-icon .share {
    color: #ffffff !important;
  }
  .iGor__banner-article-details .wishlist {
    background-color: #FF6B6B !important;
  }
  .iGor__banner-article-details .iGor__images {
    height: 100% !important;
  }
  .iGor__banner-article-details .iGor__images .iGor__img {
    height: 442px;
    border-radius: 0 !important;
  }
  .iGor__banner-article-details .iGor__description {
    padding: 20px !important;
    padding-bottom: 0px !important;
    box-shadow: none !important;
    left: 0 !important;
    border-radius: 25px 25px 0 0 !important;
    bottom: 30px !important;
    position: relative !important;
  }
  .iGor__banner-article-details .iGor__description .iGor__info {
    flex-direction: column;
    margin: 0 !important;
  }
  .iGor__banner-article-details .iGor__description .iGor__readBy img {
    width: 24px !important;
    margin-right: 5px;
  }
  .iGor__banner-article-details .iGor__description .iGor__readBy .iGo__subtitle {
    margin-top: -10px !important;
    font-size: 12px !important;
  }
  .iGor__banner-article-details .iGor__description .iGor__readTime img {
    display: none;
  }
  .iGor__banner-article-details .iGor__description .iGor__readTime .iGo__subtitle {
    margin-left: 34px;
    margin-top: -7px;
  }
  .iGor__banner-article-details .iGor__description .iGor__date img {
    display: none;
  }
  .iGor__banner-article-details .iGor__description .iGor__date .iGo__subtitle {
    margin-left: 85px;
    margin-top: -13px;
  }
  .iGor__banner-article-details .iGor__description .iGor__title {
    position: absolute;
    top: -80px;
    color: #fff;
    padding: 10px;
  }
  .iGor__banner-article-details .iGor__description .iGor__type {
    line-height: 20px;
    position: absolute;
    top: -95px;
    color: #fff;
    padding: 10px;
  }
  .iGor__banner-article-details .iGor__description .iGor__btn-wrapper {
    position: absolute;
    top: -30px;
    flex-direction: row-reverse;
    right: 15px;
    margin-top: 15px !important;
  }
  .iGor__articleCards {
    background: #ffffff;
    min-height: 60vh;
    padding: 15px 20px;
    margin-top: -50px;
    width: 100vw;
  }
  .iGor__articleCards body {
    background: #ffffff !important;
  }
  .iGor__articleCards img {
    width: 100% !important;
  }
}
.iGor__banner-article-details {
  position: relative;
  padding-bottom: 72px;
  margin-bottom: 33px;
}
.iGor__banner-article-details .iGor__images {
  width: 100%;
  height: 442px;
}
.iGor__banner-article-details .iGor__images .iGor__img {
  max-height: 442px;
  overflow: hidden;
  border-radius: 0 0 50px 0;
}
.iGor__banner-article-details .iGor__images .iGor__img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  background-color: #83807e;
}
.iGor__banner-article-details .iGor__description {
  background-color: #fff;
  max-width: 434px;
  position: absolute;
  bottom: 0;
  left: 57px;
  padding: 33px 65px 25px 37px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0 50px 0 0;
}
.iGor__banner-article-details .iGor__description .iGor__type {
  line-height: 20px;
}
.iGor__banner-article-details .iGor__description .iGor__title {
  font-size: 32px;
  font-weight: 600;
  line-height: 34px;
}
.iGor__banner-article-details .iGor__description .iGor__info {
  display: flex;
  margin-bottom: 18px;
  margin-top: 8px;
}
.iGor__banner-article-details .iGor__description .iGor__info .iGor__read {
  margin-right: 22px;
}
.iGor__banner-article-details .iGor__description .iGor__info .iGo__subtitle {
  font-size: 8px;
}
.iGor__banner-article-details .iGor__description .iGor__info img {
  width: 14px;
  margin-right: 5px;
}
.iGor__banner-article-details .iGor__description .iGor__btn-wrapper {
  display: flex;
  gap: 9px;
  margin-top: 20px;
}
.iGor__banner-article-details .iGor__description .iGor__btn-wrapper .iGor__btn-icon {
  width: 36px;
  height: 36px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));
  cursor: pointer;
}
.iGor__banner-article-details .iGor__description .iGor__btn-wrapper .iGor__btn-icon .share {
  color: #FF6B6B;
  width: 20px;
}

.iGor-article_body {
  margin: 3% 0 5% 0;
}

.iGor__articleCards .iGor__ArtTitle {
  font-size: 20px;
}
.iGor__articleCards .iGor__ArtImgCard {
  margin: 20px 0;
  max-height: 375px;
}
.iGor__articleCards .iGor__ArtImgCard img {
  width: 100%;
}
.iGor__articleCards .iGor__ArtSubTitle {
  font-size: 10px;
}

.iGor_RegionsTop {
  text-align: center;
  color: #ffffff;
  position: fixed;
  top: 7%;
}
.iGor_RegionsTop .iGor_backBtn {
  background: rgba(0, 0, 0, 0.5019607843);
  font-size: 28px;
  padding: 7px;
  border-radius: 10px;
  cursor: pointer;
}

.iGor__RegionIframe {
  width: 100%;
  height: 100dvh;
  min-height: -webkit-fill-available;
  max-height: -webkit-fill-available;
  overflow: hidden;
  border: none !important;
}

.iGor__MytripCards {
  background: #fff;
  border-radius: 0.9375rem;
  margin-top: 15px;
  padding: 1.25rem;
  position: relative;
  cursor: pointer;
}
.iGor__MytripCards .iGor__MytripImgBox {
  max-width: 14.6875rem;
  height: 8.5rem;
}
.iGor__MytripCards .iGor__MytripImgBox .iGor__MytripImg {
  width: 100%;
  height: 100%;
  border-radius: 0.9375rem;
}
.iGor__MytripCards .iGor__orderId {
  color: #C4C4C4;
}
.iGor__MytripCards .iGor__check {
  color: #C4C4C4;
  font-size: 0.5rem;
}
.iGor__MytripCards .iGor__Date {
  font-weight: 600;
}
.iGor__MytripCards .iGor__subTilte {
  color: #C4C4C4;
}
.iGor__MytripCards .iGor__BtnBox {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.iGor__MytripCards .iGor__BtnBox button {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 0.625rem;
  font-weight: 600;
  color: #fff;
  border-radius: 3.125rem;
  font-size: 0.625rem;
  margin-left: 0.1875rem;
  padding: 5px 15px;
  text-transform: capitalize;
}
.iGor__MytripCards .iGor__BtnBox button .MuiSvgIcon-root {
  margin-right: 4px;
  width: 12px;
  height: 12px;
}
.iGor__MytripCards .iGor__BtnBox button p {
  font-weight: 600;
}
.iGor__MytripCards .iGor__BtnBox .iGor__Paid {
  background: #00CEC9 !important;
}
.iGor__MytripCards .iGor__BtnBox .iGor__Paid svg {
  font-size: 0.9375rem;
  margin-right: 0.1875rem;
}
.iGor__MytripCards .iGor__BtnBox .iGor__Cancelled {
  background: #FF3D00 !important;
}
.iGor__MytripCards .iGor__BtnBox .iGor__Pending {
  background: #F5C16E !important;
}
.iGor__MytripCards .iGor__BtnBox .iGor__Paid {
  background: #00CEC9 !important;
}
.iGor__MytripCards .iGor__ModelBtn {
  position: absolute;
  right: 5px;
  top: 5px;
  color: #D9D9D9;
  cursor: pointer;
}

.iGor__MytripList {
  padding-left: 0.9375rem;
  margin-bottom: 10px;
}
.iGor__MytripList ul li {
  font-size: 0.625rem;
}

.iGor__MytripPopover .MuiPaper-root {
  left: 86% !important;
}

.iGor_cancellationText {
  width: 100%;
  resize: none;
  border: none;
  background: #fff;
  border-radius: 0.9375rem;
  padding: 0.9375rem;
}

.iGor__mytripCanBtn {
  font-size: 0.625rem !important;
  color: #EE5253 !important;
  font-weight: 600 !important;
}

.iGor_mytripPopoList .MuiListItemIcon-root {
  min-width: 1.875rem;
}
.iGor_mytripPopoList .iGor_mytripBtnFbList {
  margin-right: -20px;
}
.iGor_mytripPopoList .MuiTypography-root {
  font-size: 0.625rem !important;
  font-weight: 600 !important;
}
.iGor_mytripPopoList .iGor__maps_pin {
  margin-left: 0.3125rem;
}

.iGor__loginCard {
  background: #ffffff;
  border-radius: 31.25rem;
  margin: 2.5rem;
}

.iGor-backToFaviourate_list {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: #BFCCD9;
  color: #fff;
  margin-right: 10px;
  cursor: pointer;
  border-radius: 10px;
}
.iGor-backToFaviourate_list svg {
  font-size: 0.7rem;
  margin-right: -4px;
}

.iGOr__MyFavCardlist .iGor-CardRight p {
  font-family: "Poppins";
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  margin-bottom: 2px;
}
.iGOr__MyFavCardlist .iGor-location_box {
  display: flex;
  align-items: baseline !important;
  margin: 25px 0 5px 3px;
}
.iGOr__MyFavCardlist .iGor-location_box img {
  width: 10px;
  height: 10px;
}
.iGOr__MyFavCardlist .iGor-ratingSection .iGor-rating {
  margin: 0 5px 0 5px !important;
}
.iGOr__MyFavCardlist .iGor-ratingSection span {
  font-size: 8px;
  line-height: 18px;
}
.iGOr__MyFavCardlist .iGor-ratingSection .iGor_ratingNum {
  font-size: 8px;
}
.iGOr__MyFavCardlist .iGor_subTitle {
  width: 100%;
  color: #C4C4C4;
}
.iGOr__MyFavCardlist .iGor_Location {
  font-size: 8px !important;
  margin-left: 5px !important;
  font-weight: 400 !important;
}
.iGOr__MyFavCardlist .iGoR_roomCardSkeleton {
  width: 100%;
}
.iGOr__MyFavCardlist .iGor_Travel_fv {
  display: flex;
  margin: 0 0 10px 0;
}
.iGOr__MyFavCardlist .iGor_Travel_fv span {
  margin-left: 3px;
  font-size: 8px;
}
.iGOr__MyFavCardlist .iGor_travel-until-mb {
  margin-top: 10px;
}
.iGOr__MyFavCardlist .iGor_cardBtnSection {
  position: absolute;
  top: 18px;
  left: 14px;
  z-index: 1;
  width: 90%;
}
.iGOr__MyFavCardlist .iGor_cardBtnSection .iGor_cardBtn {
  margin-left: 5px !important;
}
.iGOr__MyFavCardlist .iGor_cardBtnSection .iGor_vrViewIcon {
  cursor: pointer;
}
.iGOr__MyFavCardlist .iGor_cardBtnSection .iGor_cardLeftBtn {
  background: transparent;
  color: #ffffff;
  width: 25px;
  height: 25px;
}
.iGOr__MyFavCardlist .iGor_cardBtnSection .iGor_cardLeftBtn svg {
  font-size: 16px;
}

.iGor_cancelationPop {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0 !important;
  width: 514px !important;
  align-items: center;
  min-height: 270px !important;
}
.iGor_cancelationPop .modal-close {
  position: absolute;
  top: 5%;
  right: 0;
  left: 90%;
}
.iGor_cancelationPop .iGor-cancellation_box {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.iGor_cancelationPop .iGor-cancellation_box .iGor-cancellation-policies {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
}
.iGor_cancelationPop .iGor-cancellation_box .iGor_policy_title {
  padding: 16px;
  border-bottom: 0.5px solid #C0C0C0;
  font-size: 14px;
  width: 100%;
  text-align: center;
  font-weight: 600;
}
.iGor_cancelationPop .iGor-Cancelltrip_button {
  display: flex;
  height: 13%;
  align-items: center;
  padding: 0 0 10px 0;
}
.iGor_cancelationPop .iGor-Cancelltrip_button button {
  width: 474px;
  height: 45px;
}

.iGor_BookingCopyId {
  font-size: 10px !important;
  margin-left: 10px;
  cursor: pointer;
}

.iGor_bookingDetailsModal .iGor_ContentBody {
  padding: 25px 21px;
}
.iGor_bookingDetailsModal .iGor_BookingID {
  margin-bottom: 0.84rem;
}
.iGor_bookingDetailsModal .iGor_bookingDetHeader {
  border-bottom: 1px solid #E5E5E5;
  padding: 0.625rem 1rem;
  align-items: center;
}
.iGor_bookingDetailsModal .iGor_BookingRoomInfo {
  border-bottom: 1px solid #E5E5E5;
  padding: 1rem 0;
}
.iGor_bookingDetailsModal .iGOr_bookingDetlsTotal {
  margin-top: 5px;
}
.iGor_bookingDetailsModal .iGor_bookingfooter {
  border-bottom: 1px solid #E5E5E5;
  margin-top: 16px;
  padding-bottom: 16px;
  margin-bottom: 16px;
}
.iGor_bookingDetailsModal .iGor_bookingDetPrice {
  padding: 1rem 0;
}
.iGor_bookingDetailsModal .iGor_bookingDetPrice .iGor_bookingDetTitle {
  color: #C4C4C4;
}
.iGor_bookingDetailsModal .iGor_bookingDetTitle {
  margin-bottom: 5px;
}
.iGor_bookingDetailsModal .iGor__CancelledBtn,
.iGor_bookingDetailsModal .iGor_PendingBtn,
.iGor_bookingDetailsModal .iGor_ConfirmedBtn {
  color: #ffffff !important;
  text-transform: capitalize !important;
  border-radius: 100px;
  padding: 2px 10px;
  font-size: 0.75rem;
}
.iGor_bookingDetailsModal .iGor__CancelledBtn .MuiButton-startIcon,
.iGor_bookingDetailsModal .iGor_PendingBtn .MuiButton-startIcon,
.iGor_bookingDetailsModal .iGor_ConfirmedBtn .MuiButton-startIcon {
  margin-right: 3px;
}
.iGor_bookingDetailsModal .iGor__CancelledBtn .MuiButton-startIcon .MuiSvgIcon-root,
.iGor_bookingDetailsModal .iGor_PendingBtn .MuiButton-startIcon .MuiSvgIcon-root,
.iGor_bookingDetailsModal .iGor_ConfirmedBtn .MuiButton-startIcon .MuiSvgIcon-root {
  font-size: 1rem;
}
.iGor_bookingDetailsModal .iGor__CancelledBtn {
  background: #FF3D00 !important;
}
.iGor_bookingDetailsModal .iGor_ConfirmedBtn {
  background: #00CEC9 !important;
}
.iGor_bookingDetailsModal .iGor_PendingBtn {
  background: #F5C16E !important;
}

.iGorfavourites_registermsg {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@media screen and (max-width: 600px) {
  .iGor-Trip_cancel_modal-mB {
    width: 100vw !important;
    height: 100vh;
    border-radius: 0px !important;
  }
  .iGor__mytripCanBtn {
    font-size: 8px !important;
    left: 13vw !important;
    color: #C4C4C4 !important;
    font-weight: 400 !important;
  }
}
@media screen and (max-width: 1024px) {
  .iGor_Travel_fv {
    margin: 0 !important;
  }
  .iGor_travel-until-mb {
    margin-top: 0 !important;
  }
  .iGor_Location {
    margin-left: 3px !important;
  }
  .iGor-location_box {
    margin: 1px 0 15px 0px !important;
  }
  .iGor-backToFaviourate_list {
    width: 25px;
    height: 25px;
    position: absolute;
    margin-top: 8px;
  }
  .iGor-backToFaviourate_list svg {
    font-size: 0.8rem;
    margin-right: -4px;
  }
  .iGor-cancellation_box {
    height: 85%;
  }
  .iGor-cancellation_box .iGor_policy_title {
    padding: 8px !important;
  }
  .modal-close {
    top: 0 !important;
  }
  .iGor-Cancelltrip_button {
    height: 5% !important;
  }
  .iGor-Cancelltrip_button button {
    width: 360px !important;
  }
  .iGor__MytripCards {
    margin: 14px 0;
  }
  .iGor__MytripCards .IGor_mytripBtnWrapper {
    height: 44px;
  }
  .iGor__MytripCards .iGor__mytripCanBtn {
    position: relative;
    top: -4px;
    margin-left: 13px;
    text-transform: capitalize;
    text-decoration: underline;
  }
  .iGor__MytripCards .iGor__MytripCardContent {
    padding-left: 12px !important;
  }
  .iGor__MytripCards .iGor__Cancelled svg {
    font-size: 10px;
    margin-right: 4px;
  }
  .iGor__MytripCards::after {
    content: "";
    background: #f1f5f9;
    width: 50px;
    height: 25px;
    position: absolute;
    left: 50%;
    border-radius: 100%;
    bottom: -14px;
  }
  .iGor__MytripCards::before {
    content: "";
    background: #f1f5f9;
    width: 50px;
    height: 25px;
    position: absolute;
    left: 50%;
    border-radius: 100%;
    top: -14px;
  }
  .iGor__MytripCards .iGor__BtnBox button {
    margin-right: -10px;
    height: 22px;
    position: absolute;
    z-index: 8;
    top: 4px;
    text-transform: capitalize;
  }
  .iGor__MytripCards .iGor__subTilte,
  .iGor__MytripCards .iGor__ModelBtn {
    display: none;
  }
  .iGor__MytripCards .iGor__MytripImgBox {
    height: 145px;
    margin-top: -17px;
  }
  .iGor__MytripCards .iGor_mytripPopoList {
    display: flex;
  }
  .iGor__MytripCards .iGor_mytripPopoList .iGor_mytripBtnMap,
  .iGor__MytripCards .iGor_mytripPopoList .iGor_mytripBtnFb {
    border-radius: 50px;
    max-width: 83px;
    padding: 8px;
    height: 29px;
  }
  .iGor__MytripCards .iGor_mytripPopoList .iGor__maps_pin {
    margin: 0;
  }
  .iGor__MytripCards .iGor_mytripPopoList .iGor_mytripBtnMap {
    background: #F1F5F9;
  }
  .iGor__MytripCards .iGor_mytripPopoList .iGor_mytripBtnFb {
    background: #1A73E8;
    color: #fff;
  }
  .iGor_bookingDetailsModal {
    background: #F1F5F9 !important;
  }
  .iGor_bookingDetailsModal .iGor_bookingDetHeader {
    border-bottom: none;
  }
  .iGor_bookingDetailsModal .iGor_bookingDetBox {
    margin-bottom: 15px;
    background: #ffffff;
    border-radius: 10px;
    padding: 16px;
  }
  .iGor_bookingDetailsModal .iGor_BookingRoomInfo,
  .iGor_bookingDetailsModal .iGor_bookingfooter {
    border-bottom: none;
    padding: 0;
    margin: 0;
  }
  .iGor_bookingDetailsModal .iGor_BookingRoomInfo {
    margin-top: 1rem;
  }
  .iGor_bookingDetailsModal .iGOr_bookingDetlsTotal {
    padding: 0 1rem;
  }
  .iGor_bookingDetailsModal .iGor_bookingDetPrice {
    padding: 0 0 0.65rem 0;
    align-items: flex-end;
  }
  .iGor_bookingDetailsModal .iGor_bookingDetPrice .iGor_bookingDetTitle {
    color: #000000;
    font-weight: 600;
    font-size: 0.75rem;
    margin-bottom: 10px;
    margin-top: 0;
  }
  .iGor_bookingDetailsModal .iGor_bookingDetTitle {
    margin-top: 8px;
  }
  .iGorfavourites_registermsg .iGorfavourites_title {
    text-align: center;
  }
}
.iGor__side-error {
  width: 100%;
  height: 100vh;
}

.iGor__404 {
  font-size: 7.5em !important;
  font-weight: bold !important;
}

.iGor__bg-error {
  background: rgb(230, 105, 134);
  background: linear-gradient(346deg, rgb(230, 105, 134) 0%, rgb(238, 63, 164) 35%, rgb(228, 93, 134) 100%);
}

.iGor__pageNotRight {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 20px;
  align-items: center;
}

.iGor__loadingIcon {
  color: rgb(230, 105, 134);
  font-size: 3rem !important;
  margin-bottom: 1rem;
}

.iGor__errorText {
  font-size: 0.9rem !important;
  font-weight: 500 !important;
  font-family: "poppins" !important;
}

.iGorClose_alert_button {
  margin-top: 20px;
  width: 75px;
  height: 30px;
  border-radius: 9px;
  border: 1px transparent;
  color: white;
  background: linear-gradient(180deg, #FF6B6B 0%, #EE5253 100%);
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: 500;
  letter-spacing: 1.3px;
}

.iGor__NotFoundimg {
  width: 100%;
  height: 100%;
}

.iGor__loadingAnimation {
  animation: setAnimation 3s linear infinite;
}
@keyframes setAnimation {
  from {
    rotate: 0deg;
  }
  to {
    rotate: 365deg;
  }
}

.iGor__ProDetailsView {
  position: relative;
  height: 100vh;
  z-index: 1;
}
.iGor__ProDetailsView .iGor__proTitle {
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
}
.iGor__ProDetailsView .iGor__proResorts {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  margin-right: 5px;
}
.iGor__ProDetailsView .iGor__proRStar {
  width: 6px;
  height: 6.37px;
  color: #E26721;
  margin: 0 2px;
}
.iGor__ProDetailsView .iGor_starIcon_not_rating {
  width: 6px;
  height: 6.37px;
  color: #83807e;
  margin: 0 2px;
}
.iGor__ProDetailsView .iGor__proReviwe {
  width: 22px;
  height: 12px;
  border-radius: 100px;
  background: linear-gradient(180deg, #FF6B6B 0%, #EE5253 100%);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 8px;
  margin: 0 4px;
}
.iGor__ProDetailsView .iGor__proReviweBox {
  align-items: center;
}
.iGor__ProDetailsView .iGor__proReviewTitle {
  font-size: 12px;
  font-weight: 400;
  color: #C4C4C4;
}
.iGor__ProDetailsView .iGor__proPriceIcon {
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  margin: 0 4px;
}
.iGor__ProDetailsView .iGor__proPrice {
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
}
.iGor__ProDetailsView .iGor__prPriceInfo {
  font-size: 8px;
  font-weight: 400;
  line-height: 18px;
  color: #C4C4C4;
}
.iGor__ProDetailsView .iGor_proInfoIcon {
  width: 20px;
  margin-right: 10px;
  cursor: pointer;
}
.iGor__ProDetailsView .iGor_proPrice_Detls {
  margin: 20px 0 15px 0;
  align-items: center;
}
.iGor__ProDetailsView .iGor__proBackBtnBox {
  position: fixed;
  top: 56px;
  left: 86px;
}
.iGor__ProDetailsView .iGor__proBackBtnBox .iGor__proBackBtn {
  background: rgba(0, 0, 0, 0.5019607843);
  color: #fff;
  width: 106px;
  height: 35px;
  border-radius: 15px;
  font-size: 14px !important;
  text-transform: capitalize !important;
  font-weight: 600;
  justify-content: space-evenly;
  cursor: pointer;
}
.iGor__ProDetailsView .iGor__proBackBtnBox .iGor__proBackBtn svg {
  font-size: 17px;
}
.iGor__ProDetailsView .iGor__ProDetailsViewCard {
  position: fixed;
  bottom: 5%;
  left: 86px;
  width: 336px;
  min-height: 157px;
  border-radius: 10px;
  background: #fff;
  padding: 15px 20px 5px 20px;
}
.iGor__ProDetailsView .iGor__ProDetailsViewCard .iGor__ProDContentBox {
  padding: 15px 0;
  border-top: 1px solid #C4C4C4;
  text-align: justify;
}
.iGor__ProDetailsView .iGor__ProDetailsViewCard .iGor_proBookNow {
  width: 105px;
  height: 30px;
  font-size: 10px;
}
.iGor__ProDetailsView .iGor__ProDetailsViewCard .iGor_proBookNow_disable {
  width: 105px;
  height: 30px;
  font-size: 10px;
  color: #fff !important;
  background: #979797 !important;
}
.iGor__ProDetailsView .iGor__ProDetailsViewCard .iGor__proMoreLess {
  font-size: 10px;
  font-weight: 600;
  line-height: 18px;
}
.iGor__ProDetailsView .iGor__ProDetailsViewCard .iGor__ProDetailsContent {
  font-size: 8px;
  font-weight: 400;
  line-height: 12px;
  text-align: justified;
}
.iGor__ProDetailsView .iGor__ProDetailsViewCard .iGor__ProDetailsMain {
  font-size: 10px;
  font-weight: 600;
  line-height: 16px;
  margin: 0 5px;
}
.iGor__ProDetailsView .iGor__ProDetailsViewCard .iGor__ProDetailsSub {
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
}
.iGor__ProDetailsView .iGor__ProDetailsViewCard .iGor__ProDRoom {
  margin-bottom: 25px;
}
.iGor__ProDetailsView .iGor__proMoreLessBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.iGor__ProDetailsView .iGor__proMoreLessBox .iGor__proML_Icon {
  margin-top: -5px;
}

.iGor-inbox_newsrooms {
  overflow: hidden;
}
.iGor-inbox_newsrooms .iGorNews_ImgCard {
  position: relative;
  z-index: 0;
}
.iGor-inbox_newsrooms .iGorNews_ImgCard .iGorNews_Img {
  border-radius: 15px;
  height: 213px;
  width: 100%;
}
.iGor-inbox_newsrooms .iGorNews_ImgCard .iGorNews_content {
  position: absolute;
  bottom: 30px;
  left: 20px;
  color: #fff;
  width: 50%;
}
.iGor-inbox_newsrooms .iGorNews_ImgCard .iGorNews_content .iGorNews_Tilte {
  font-size: 20px;
  font-weight: 600;
  line-height: 22px;
  color: #fff;
}
.iGor-inbox_newsrooms .iGorNews_ImgCard .iGorNews_content .iGorNews_Auther {
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
}
.iGor-inbox_newsrooms .iGorNews_Topic {
  font-size: 12px !important;
  font-weight: 600 !important;
  margin-bottom: 9px !important;
  margin-top: 10px !important;
}
.iGor-inbox_newsrooms .iGorNews_letest {
  overflow: hidden;
  overflow-x: auto;
}
.iGor-inbox_newsrooms .iGorNews_letestCards {
  display: flex;
  flex-direction: row;
  width: 500px;
}
.iGor-inbox_newsrooms .iGorNews_letestCards a {
  text-decoration: none;
}
.iGor-inbox_newsrooms .iGorNews_letCard {
  background: #ffffff;
  width: 231px;
  height: 87px;
  border-radius: 10px;
  padding: 9px;
  display: flex;
  margin-right: 10px;
}
.iGor-inbox_newsrooms .iGorNews_letCard .iGorNews_letImgs {
  width: 70px;
  height: 70px;
  border-radius: 10px;
}
.iGor-inbox_newsrooms .iGorNews_letCard .iGorNews_letImgs .iGorNews_letImg {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.iGor-inbox_newsrooms .iGorNews_letCard .iGorNews_letContent {
  margin-left: 13px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.iGor-inbox_newsrooms .iGorNews_letCard .iGorNews_letContent .iGorNews_letTitle {
  font-size: 10px !important;
  font-weight: 600 !important;
  line-height: 15px !important;
  color: black;
}
.iGor-inbox_newsrooms .iGorNews_letCard .iGorNews_letContent .iGorNews_letDate,
.iGor-inbox_newsrooms .iGorNews_letCard .iGorNews_letContent .iGorNews_letAuther {
  font-size: 8px !important;
  font-weight: 400 !important;
  color: #757575;
}
.iGor-inbox_newsrooms .iGorNews_Travelcard {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.iGor-inbox_newsrooms .iGorNews_Travelcard .iGorNews_ImgTravelCard {
  width: 49%;
  height: 145px;
  border-radius: 10px;
  position: relative;
  z-index: 0;
  margin-bottom: 10px;
}
.iGor-inbox_newsrooms .iGorNews_Travelcard .iGorNews_ImgTravelCard .iGorNews_ImgTravel {
  width: 100%;
  height: 100%;
}
.iGor-inbox_newsrooms .iGorNews_Travelcard .iGorNews_ImgTravelCard .iGorNews_Traveltitle {
  font-size: 8px;
  font-weight: 600;
  line-height: 12px;
  text-align: center;
  color: #ffffff;
}
.iGor-inbox_newsrooms .iGorNews_Travelcard .iGorNews_ImgTravelCard .iGorNews_TravelDate {
  font-size: 4px;
  font-weight: 300;
  line-height: 6px;
  text-align: center;
  color: #ffffff;
}
.iGor-inbox_newsrooms .iGorNews_Travelcard .iGorNews_ImgTravelCard .iGorNews_TravelCon {
  position: absolute;
  bottom: 0;
  height: 40px;
  background: rgba(0, 0, 0, 0.1);
  width: 100%;
}
.iGor-inbox_newsrooms .iGorNews_Travelcard .iGorNews_ImgTravelCard .iGorNews_TravelCon .iGorNews_Traveltext {
  width: 70%;
  margin: 0 auto;
}

.iGoRoom_NotIfrome {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100vw;
  min-height: 100vh;
  max-height: 100%;
  position: absolute;
}

#landingPageIframe {
  position: absolute;
  width: 100%;
  height: 100vh;
  z-index: -1;
  border: none !important;
}

.container {
  position: relative;
}

.content {
  transition: all 0.5s;
  height: 12.5rem;
  width: 100%;
  background-color: lightblue;
  position: absolute;
  top: 0;
  bottom: 0;
}

.visible {
  transform: translateY(0%);
}

.hidden {
  transform: translateY(100%);
}

.iGor_IframeCity {
  display: flex;
  margin-top: -20px !important;
  margin-bottom: 5px !important;
  margin-left: -5px !important;
}

.IGor_Mb-Destination {
  margin-top: 10px;
}
.IGor_Mb-Destination button {
  padding: 3px;
}
.IGor_Mb-Destination button:first-child {
  padding-left: 0;
}
.IGor_Mb-Destination button:last-child svg {
  padding-left: 0.625rem !important;
}

.igo_facbookContent {
  padding: 2rem;
  border-radius: 15px;
  margin: 1rem 0;
  background: #ffffff;
}
.igo_facbookContent ul {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
}
.igo_facbookContent ul li {
  padding: 5px 0 0 0;
}
.igo_facbookContent body {
  background: #ffffff !important;
}

.iGor__pageslink {
  padding: 2rem;
  border-radius: 15px;
  margin: 1rem 0;
  background: #ffffff;
}
.iGor__pageslink body {
  background: #ffffff !important;
}
.iGor__pageslink h3 {
  margin: 5px 0;
}
.iGor__pageslink p,
.iGor__pageslink ol {
  font-size: 14px;
  text-align: justify;
}
.iGor__pageslink li[id] h5 {
  margin: 0.625rem 0;
}
.iGor__pageslink li[id] ol {
  padding-left: 1rem;
}

.iGor__TermAndcon {
  position: sticky;
  top: 20px;
}
.iGor__TermAndcon li {
  list-style: none;
}
.iGor__TermAndcon li a {
  text-decoration: none;
  font-size: 12px;
  font-weight: 600;
  color: #000;
}
.iGor__TermAndcon li a:active {
  color: #FF6B6B !important;
}
.iGor__TermAndcon li a:hover {
  color: #FF6B6B !important;
}

.iGOr__footer-Fix {
  position: fixed;
  bottom: 0;
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .IGor_Mb-Destination button {
    margin-top: -15px;
  }
}
.iGoRoom_login_pop {
  background: #F1F5F9 !important;
  padding: 0.9375rem;
}
.iGoRoom_login_pop .iGor__loginBtnSocial .MuiButtonBase-root {
  padding: 0 5px 0 0 !important;
}

.iGoRoomIcon {
  margin-right: 0.625rem;
  align-items: center !important;
}

.iGoRoomNav {
  font-size: 0.75rem !important;
  font-weight: 600 !important;
  color: #ffffff !important;
  border-radius: 10px !important;
}

.iGoRoomHeader .iGoRoomNav, .iGoRoomHeader .iGoRoomIcon {
  color: #000000 !important;
}

.iGor_userLoginProBtn {
  border: 1px solid #C6C6C6 !important;
}

.iGOr_Desk-D-none {
  display: none;
}

@media screen and (min-width: 1320px) {
  .MuiContainer-root {
    max-width: 1280px !important;
  }
  .IgoRoomIcon {
    cursor: pointer;
  }
}
@media screen and (max-width: 1024px) {
  html {
    font-size: 13px;
  }
  body,
  html {
    overflow-x: hidden !important;
    -ms-scroll-chaining: none !important;
    overscroll-behavior-x: none !important;
  }
  .IgoRoomLogo {
    text-align: center;
  }
  .iGOr_Desk-D-none {
    display: block;
  }
  .iGOr_mb-D-none {
    display: none !important;
  }
  .iGor_MB-social {
    color: #BFCCD9;
    margin: 21px 5px 0 5px;
  }
  .iGOr_MB-primaryBtn {
    border-radius: 50px !important;
    height: 45px !important;
  }
  .iGor-action_loader {
    height: 50%;
  }
  .iGor-action_loader svg {
    font-size: 1.8rem;
  }
  .iGor_Modal {
    width: 100% !important;
    height: 100%;
    border-radius: 0 !important;
  }
  .iGor_Modal .iGor__registerSubTitle {
    text-align: center;
  }
  .iGoR_NeedHelpBtn {
    border-radius: 5px !important;
    width: 105px !important;
    height: 30px;
  }
  .iGor_curr-MB {
    position: static !important;
    background: #E5E5E5 !important;
    border-radius: 5px !important;
    width: 100px !important;
    margin-right: 10px !important;
    font-size: 10px !important;
    height: 30px;
    width: 105px;
  }
  .iGor_curr-MB .MuiSvgIcon-root {
    margin: 0 !important;
  }
  .iGor_DestAutoTitBtn {
    margin: 0 auto;
    width: 315px;
    height: 45px;
  }
  .iGoR_roomCard {
    margin: 0;
    margin-bottom: 10px;
    padding: 10px;
  }
  .iGoR_roomCard .iGor_cardImg {
    width: 100% !important;
    height: 100% !important;
  }
  .iGor__filterPriceRange {
    text-align: center;
  }
  .iGor__AdvfilterSection {
    padding-top: 30px;
    height: 85%;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    z-index: 333;
  }
  .iGOr_DropDown .iGor_priceRange .MuiSlider-root {
    padding: 13px 0;
    width: 93% !important;
  }
  .iGOr_DropDown .iGor_checkBoxDrop .MuiListItemSecondaryAction-root {
    right: 12px !important;
  }
  .iGOr_DropDown .iGor_checkBoxDrop .MuiTypography-root {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
  }
  .iGOr_DropDown .MuiListItem-root {
    margin-bottom: 11px;
  }
  .iGOr_DropDown .iGOr_DropDown {
    min-height: 40px;
    padding: 10px 13px 10px 20px;
    border-radius: 10px;
  }
  .iGOr_DropDown .priceRange {
    width: 100%;
  }
  .iGor__AdvClose {
    left: 25px;
    right: auto !important;
    top: auto !important;
    width: 25px !important;
    height: 25px;
  }
  .iGor__AdvClose button {
    width: 25px;
    height: 25px !important;
    border-radius: 10px;
  }
  .iGor__AdvClose button svg {
    font-size: 32px;
    color: #757575;
  }
  .iGor-resultSection {
    height: calc(100vh - 201px);
  }
  .iGor_CustomTabs {
    background: #ffffff;
    padding: 5px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    margin-top: 0;
    margin-bottom: 10px;
    text-transform: none;
  }
  .iGor_CustomTabs .iGor_btnTab {
    padding: 0.25rem 0.625rem;
    font-size: 10px;
    height: 30px;
    color: #c4c4c4;
  }
  .iGor__SecondMenuMB {
    padding: 0 !important;
  }
  .iGor__SecondMenuMB .IgoRoomLogo {
    padding-top: 0 !important;
  }
  .iGor__SecondMenuMB img {
    display: none;
  }
  .iGor__SecondMenuMB .iGor_m-menuBtn {
    right: 90% !important;
    top: 90% !important;
    margin-top: 20px;
  }
  .iGor__SecondMenuMB .iGor_m-menuBtn .iGo_m-menuIcon {
    color: #000000;
  }
  .iGor__CardForCenter {
    display: flex;
    align-items: stretch;
    justify-content: center;
    align-content: center;
    height: 75vh;
  }
}/*# sourceMappingURL=main.css.map */