.iGor__MytripCards {
    background: #fff;
    border-radius: 0.9375rem;
    margin-top: 15px;
    padding: 1.25rem;
    position: relative;
    cursor: pointer;

    .iGor__MytripImgBox {
        max-width: 14.6875rem;
        height: 8.50rem;

        .iGor__MytripImg {
            width: 100%;
            height: 100%;
            border-radius: 0.9375rem;
        }
    }

    .iGor__orderId {
        color: #C4C4C4;
    }

    .iGor__check {
        color: #C4C4C4;
        font-size: 0.5rem;
    }

    .iGor__Date {
        font-weight: 600;
    }

    .iGor__subTilte {
        color: #C4C4C4;
    }

    .iGor__BtnBox {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;

        button {
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: 0.625rem;
            font-weight: 600;
            color: #fff;
            border-radius: 3.125rem;
            font-size: 0.625rem;
            margin-left: 0.1875rem;
            padding: 5px 15px;
            text-transform: capitalize;

            .MuiSvgIcon-root {
                margin-right: 4px;
                width: 12px;
                height: 12px;
            }

            p{
                font-weight: 600;
            }
        }

        .iGor__Paid {
            background: #00CEC9 !important;

            svg {
                font-size: 0.9375rem;
                margin-right: 0.1875rem;
            }
        }

        .iGor__Cancelled {
            background: #FF3D00 !important;
        }


        .iGor__Pending {
            background: #F5C16E !important;
        }

        .iGor__Paid {
            background: #00CEC9 !important;
        }
    }



    .iGor__ModelBtn {
        position: absolute;
        right: 5px;
        top: 5px;
        color: #D9D9D9;
        cursor: pointer;
    }
}

.iGor__MytripList {
    padding-left: 0.9375rem;
    margin-bottom: 10px;

    ul {
        li {
            font-size: 0.625rem;
        }
    }
}

.iGor__MytripPopover {
    .MuiPaper-root {
        left: 86% !important;
    }
}

.iGor_cancellationText {
    width: 100%;
    resize: none;
    border: none;
    background: #fff;
    border-radius: 0.9375rem;
    padding: 0.9375rem;
}

.iGor__mytripCanBtn {
    font-size: 0.625rem !important;
    color: #EE5253 !important;
    font-weight: 600 !important;
}

.iGor_mytripPopoList {
    .MuiListItemIcon-root {
        min-width: 1.875rem;
    }

    .iGor_mytripBtnFbList {
        margin-right: -20px;
    }

    .MuiTypography-root {
        font-size: 0.625rem !important;
        font-weight: 600 !important;
    }

    .iGor__maps_pin {
        margin-left: 0.3125rem;
    }
}

.iGor__loginCard {
    background: #ffffff;
    border-radius: 31.25rem;
    margin: 2.5rem;
}

.iGor-backToFaviourate_list {
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background: #BFCCD9;
    color: #fff;
    margin-right: 10px;
    cursor: pointer;
    border-radius: 10px;

    svg {
        font-size: 0.7rem;
        margin-right: -4px;
    }
}

.iGOr__MyFavCardlist {

    .iGor-CardRight p {

        font-family: 'Poppins';
        font-size: 14px;
        line-height: 18px;
        font-weight: 600;
        margin-bottom: 2px;
    }

    .iGor-location_box {
        display: flex;
        align-items: baseline !important;
        margin: 25px 0 5px 3px;

        img {
            width: 10px;
            height: 10px;
        }
    }

    .iGor-ratingSection {
        .iGor-rating {
            margin: 0 5px 0 5px !important;
        }

        span {
            font-size: 8px;
            line-height: 18px;
        }

        .iGor_ratingNum {
            font-size: 8px;
        }
    }

    .iGor_subTitle {
        width: 100%;
        color: $secondaryColor;

    }

    .iGor_Location {
        font-size: 8px !important;
        margin-left: 5px !important;
        font-weight: 400 !important;
    }

    .iGoR_roomCardSkeleton {
        width: 100%;
    }

    .iGor_Travel_fv {
        display: flex;
        margin: 0 0 10px 0;

        span {
            margin-left: 3px;
            font-size: 8px;
        }
    }

    .iGor_travel-until-mb {
        margin-top: 10px;
    }


    .iGor_cardBtnSection {
        position: absolute;
        top: 18px;
        left: 14px;
        z-index: 1;
        width: 90%;

        .iGor_cardBtn {
            margin-left: 5px !important;
        }

        .iGor_vrViewIcon {
            cursor: pointer;
        }

        .iGor_cardLeftBtn {
            // background: rgba(0, 0, 0, 50%);
            background: transparent;
            color: $whiteColor;
            width: 25px;
            height: 25px;

            svg {
                font-size: 16px;
            }
        }
    }


}

.iGor_cancelationPop {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 0 !important;
    width: 514px !important;
    align-items: center;
    min-height: 270px !important;

    .modal-close {
        position: absolute;
        top: 5%;
        right: 0;
        left: 90%;
    }

    .iGor-cancellation_box {
        display: flex;
        flex-direction: column;
        align-items: center;

        .iGor-cancellation-policies {
            display: flex;
            flex-direction: column;
            justify-items: center;
            align-items: center;
        }

        .iGor_policy_title {
            padding: 16px;
            border-bottom: 0.5px solid #C0C0C0;
            font-size: 14px;
            width: 100%;
            text-align: center;
            font-weight: 600;
        }
    }

    .iGor-Cancelltrip_button {
        display: flex;
        height: 13%;
        align-items: center;
        padding: 0 0 10px 0;

        button {
            width: 474px;
            height: 45px;

        }
    }
}

.iGor_BookingCopyId {
    font-size: 10px !important;
    margin-left: 10px;
    cursor: pointer;
}

.iGor_bookingDetailsModal {
    .iGor_ContentBody {
        padding: 25px 21px;
    }

    .iGor_BookingID {
        margin-bottom: 0.84rem;
    }

    .iGor_bookingDetHeader {
        border-bottom: 1px solid #E5E5E5;
        padding: 0.625rem 1rem;
        align-items: center;
    }

    .iGor_BookingRoomInfo {
        border-bottom: 1px solid #E5E5E5;
        padding: 1rem 0;
    }

    .iGOr_bookingDetlsTotal {
        margin-top: 5px;
    }

    .iGor_bookingfooter {
        border-bottom: 1px solid #E5E5E5;
        margin-top: 16px;
        padding-bottom: 16px;
        margin-bottom: 16px;
    }

    .iGor_bookingDetPrice {
        padding: 1rem 0;

        .iGor_bookingDetTitle {
            color: #C4C4C4;
        }
    }

    .iGor_bookingDetTitle {
        margin-bottom: 5px;
    }

    .iGor__CancelledBtn,
    .iGor_PendingBtn,
    .iGor_ConfirmedBtn {
        color: #ffffff !important;
        text-transform: capitalize !important;
        border-radius: 100px;
        padding: 2px 10px;
        font-size: 0.75rem;

        .MuiButton-startIcon {
            margin-right: 3px;

            .MuiSvgIcon-root {
                font-size: 1rem;
            }
        }
    }

    .iGor__CancelledBtn {
        background: #FF3D00 !important;
    }

    .iGor_ConfirmedBtn {
        background: #00CEC9 !important;
    }

    .iGor_PendingBtn {
        background: #F5C16E !important;
    }
}

.iGorfavourites_registermsg {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}

@media screen and (max-width:600px) {
    .iGor-Trip_cancel_modal-mB {
        width: 100vw !important;
        height: 100vh;
        border-radius: 0px !important;
    }

    .iGor__mytripCanBtn {
        font-size: 8px !important;
        left: 13vw !important;
        color: #C4C4C4 !important;
        font-weight: 400 !important;
    }

}

@media screen and (max-width:1024px) {
    .iGor_Travel_fv {
        margin: 0 !important;

    }

    .iGor_travel-until-mb {
        margin-top: 0 !important;
    }

    .iGor_Location {
        margin-left: 3px !important;
    }

    .iGor-location_box {
        margin: 1px 0 15px 0px !important;
    }


    .iGor-backToFaviourate_list {
        width: 25px;
        height: 25px;
        position: absolute;
        margin-top: 8px;

        svg {
            font-size: 0.8rem;
            margin-right: -4px;
        }
    }

    .iGor-cancellation_box {
        height: 85%;

        .iGor_policy_title {
            padding: 8px !important;
        }
    }

    .modal-close {
        top: 0 !important;
    }

    .iGor-Cancelltrip_button {
        height: 5% !important;

        button {
            width: 360px !important;
        }
    }

    .iGor__MytripCards {
        margin: 14px 0;

        .IGor_mytripBtnWrapper {
            height: 44px;
            // max-width: 180px;
        }

        .iGor__mytripCanBtn {
            position: relative;
            top: -4px;
            margin-left: 13px;
            text-transform: capitalize;
            text-decoration: underline;
        }

        .iGor__MytripCardContent {
            padding-left: 12px !important;
        }

        .iGor__Cancelled {
            svg {
                font-size: 10px;
                margin-right: 4px;
            }
        }

        &::after {
            content: '';
            background: #f1f5f9;
            width: 50px;
            height: 25px;
            position: absolute;
            left: 50%;
            border-radius: 100%;
            bottom: -14px;
        }

        &::before {
            content: '';
            background: #f1f5f9;
            width: 50px;
            height: 25px;
            position: absolute;
            left: 50%;
            border-radius: 100%;
            top: -14px;
        }

        .iGor__BtnBox {
            button {
                margin-right: -10px;
                height: 22px;
                position: absolute;
                z-index: 8;
                top: 4px;
                text-transform: capitalize ;
            }
        }

        .iGor__subTilte,
        .iGor__ModelBtn {
            display: none;
        }

        .iGor__MytripImgBox {
            height: 145px;
            margin-top: -17px;

        }

        .iGor_mytripPopoList {
            display: flex;

            .iGor_mytripBtnMap,
            .iGor_mytripBtnFb {
                border-radius: 50px;
                max-width: 83px;
                padding: 8px;
                height: 29px;
            }

            .iGor__maps_pin {
                margin: 0;
            }

            .iGor_mytripBtnMap {
                background: #F1F5F9;

            }

            .iGor_mytripBtnFb {
                background: #1A73E8;
                color: #fff;
            }
        }

    }

    .iGor_bookingDetailsModal {
        background: #F1F5F9 !important;

        .iGor_bookingDetHeader {
            border-bottom: none;
        }

        .iGor_bookingDetBox {
            margin-bottom: 15px;
            background: #ffffff;
            border-radius: 10px;
            padding: 16px;
        }

        .iGor_BookingRoomInfo,
        .iGor_bookingfooter {
            border-bottom: none;
            padding: 0;
            margin: 0;
        }

        .iGor_BookingRoomInfo {
            margin-top: 1rem;
        }

        .iGOr_bookingDetlsTotal {
            padding: 0 1rem;
        }

        .iGor_bookingDetPrice {
            padding: 0 0 0.65rem 0;
            align-items: flex-end;

            .iGor_bookingDetTitle {
                color: #000000;
                font-weight: 600;
                font-size: 0.75rem;
                margin-bottom: 10px;
                margin-top: 0;
            }
        }

        .iGor_bookingDetTitle {
            margin-top: 8px;
        }


    }

    .iGorfavourites_registermsg {
        .iGorfavourites_title {
            text-align: center;
        }
    }
}