.igoRoomBtn {
    font-size: 0.75rem !important;
    font-weight: 600 !important;
    border-radius: 1.25rem !important;
    border: none !important;
    color: $whiteColor !important;
    background: linear-gradient(180deg, $primaryColor 0%, #EE5253 100%);
    width: 100%;
    text-transform: inherit !important;
}

.iGor_LoginAccount {
    visibility: hidden;
}

.iGor-action_loader {
    height: 80%;

    svg {
        font-size: 1.1rem;
    }
}

.iGor_IframeBtn {
    background: rgba(0, 0, 0, 0.5);
    border-radius: 3.125rem;
    color: #ffffff;
    width: 2.5rem !important;
    padding: 0.625rem 0.4375rem 0.625rem 0.8125rem;
    height: 2.5rem !important;
    text-align: center;
    cursor: pointer;
}

.InputField {
    background: $whiteColor;
    border-radius: 3.125rem;
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    height: 2.8125rem;
    padding-left: 1.25rem;

    .MuiFormLabel-root {
        font-size: 13px;
        color: #a2a2a2;

    }



    .MuiTextField-root {
        width: 100%;
    }

    input {
        padding: 0.9375rem 0;
        border: none;
        font-size: 0.875rem;
    }

    fieldset {
        border: none !important;
    }
}

.InputField:has(.iGor-ErrorMsg),
.iGor_CountryCodeinput:has(.iGor-ErrorMsg)>.iGor_codeField {
    margin-bottom: 27px;
    border: 1px solid #f55c5d;
}

.iGor_passwordInput:has(.iGor-ErrorMsg),
.iGoR_autoComBox:has(.iGor-ErrorMsg) {
    margin-bottom: 1rem;
    border-radius: 50px;
}

.iGor_passwordInput:has(.iGor-ErrorMsg)>.InputField,
.iGor_CountryCodeinput:has(.iGor-ErrorMsg)>.iGor_codeField,
.iGor_loginPass:has(.iGor-ErrorMsg)>.InputField {
    border: 1px solid #f55c5d;
}

.iGor_passwordInput>.iGor-ErrorMsg {
    bottom: -4px !important;
}

.iGoR_autoComBox>.iGor-ErrorMsg {
    bottom: -20px !important;

}


.iGr__RegAccount {
    .iGor__RegField {
        position: relative;
        top: 6px;
    }



    .InputField {
        [data-shrink="true"] {
            color: #a2a2a2 !important;
            -webkit-transform: translate(0px, -3px) scale(0.75) !important;
            -moz-transform: translate(0px, -3px) scale(0.75) !important;
            -ms-transform: translate(0px, -3px) scale(0.75) !important;
            transform: translate(0px, 3px) scale(0.75) !important;
        }

        [data-shrink="false"] {
            color: #a2a2a2 !important;
            -webkit-transform: translate(14px, 11px) scale(1) !important;
            -moz-transform: translate(14px, 11px) scale(1) !important;
            -ms-transform: translate(14px, 11px) scale(1) !important;
            transform: translate(14px, 11px) scale(1) !important;
        }
    }

    .iGor__RegDate,
    .iGor_regEmail {

        [data-shrink="false"] {
            color: #C4C4C4 !important;
            -webkit-transform: translate(0px, 10px) scale(1) !important;
            -moz-transform: translate(0px, 10px) scale(1) !important;
            -ms-transform: translate(0px, 10px) scale(1) !important;
            transform: translate(0px, 10px) scale(1) !important;
        }
    }
}

.iGoRoom_CountInput {
    background: #F1F5F9;
    border-radius: 3.125rem !important;
    width: 100%;
    height: 40px;
    justify-content: space-between;
    color: #000;

    .MuiButton-root {
        border: none !important;
        color: #000;
        border-radius: 3.125rem !important;
        min-width: 1.875rem !important;
    }
}

.iGor__ChildBox {
    display: flex;
    align-items: center;
    margin-top: 10px;
    justify-content: center;

    .iGoRoom_CountInput {
        width: 180px;
        margin-left: 10px;
        align-items: center;

        .iGor__childAge {
            background: #FF6B6B;
            color: #ffffff;
            width: 40px;
            height: 40px;

            .MuiSvgIcon-root {
                width: 18px;
                height: auto;
            }
        }
    }

    .iGor__YearsOld {
        width: 80px;
        text-align: right;
    }
}

.iGor__VerificationBg {
    background-color: #ffffff !important;
}

.iGoRoom_login_pop {
    .inputIcons {
        width: 0.9375rem !important;
    }

    .iGor_passIcon {
        width: 1.125rem !important;
    }
}

.inputIcons {
    margin-right: 0.9375rem;
}


.modal-close {
    position: absolute;
    top: 0;
    right: 0;
}

.iGor__regiterClose {
    top: 5px !important;
    right: 15px !important;
}

.iGoroom_NhTitle {
    span {
        font-size: 0.75rem;
        font-weight: 600;
    }

    p {
        font-size: 0.625rem;
        color: #C4C4C4;
        font-weight: 400;
    }
}

.iGor_UserLoginPop {
    .MuiPaper-root {
        color: $blackColor;
    }

    .iGor_UserIcon {
        min-width: 2.1875rem;
        justify-content: center;
        margin-right: 0.625rem;

        .iGor_userOutline {
            width: 0.875rem;
        }

        svg,
        .ticketIcon {
            width: 1rem;
            color: $blackColor;
        }

        svg,
        .faviourateIcon {
            width: 1rem !important;
            color: $blackColor;
        }

    }

    .iGor_UserTitle {
        span {
            font-size: 0.75rem;
            font-weight: 500;
        }
    }
}

.iGoR_autoCom {
    background: $whiteColor;
    border-radius: 3.125rem;

    .MuiInputBase-root {
        padding: 0rem;
    }

    fieldset {
        border: none;

    }

    input {
        font-size: 0.875rem;
        padding-left: 1.25rem !important;
    }

    .MuiOutlinedInput-root {
        padding: 0.3125rem 1.875rem 0.3125rem 0.3125rem !important;
    }
}




// datepiker
.rdrSelected,
.rdrInRange,
.rdrStartEdge,
.rdrEndEdge {
    top: 1px !important;
    height: 33px !important;
}

.rdrStartEdge {
    border-top-left-radius: 50px !important;
    border-bottom-left-radius: 50px !important;
    left: 0px !important;
}

.rdrEndEdge {
    border-top-right-radius: 50px !important;
    border-bottom-right-radius: 50px !important;
    right: 0px !important;
}



.rdrNextButton,
.rdrPprevButton {
    i {
        border: solid #FF6B6B !important;
        border-width: 0 2px 2px 0 !important;
        display: inline-block !important;
        padding: 4px !important;

    }
}

.rdrNextButton {
    i {
        transform: rotate(-45deg) !important;
        -webkit-transform: rotate(-45deg) !important;
    }
}

.rdrPprevButton {
    i {

        transform: rotate(135deg) !important;
        -webkit-transform: rotate(135deg) !important;
    }
}


// .rdrStartEdge,
// .rdrEndEdge {
//     height: 1.625rem !important;
// }

.iGor_DestAutoMain {
    .rdrCalendarWrapper {
        font-size: 0.75rem !important;
        width: 100% !important;
        margin-bottom: 0.625rem;

        // .rdrDays {
        //     justify-content: space-between !important;
        // }


    }

    .rdrMonth {
        padding: 0 !important;
        width: 100% !important;
    }
}

.rdrDayDisabled {
    background: #ffffff !important;
}

.rdrMonths {
    text-transform: uppercase !important;
}

.rdrMonth {
    padding: 0 !important;
}

.rdrMonthAndYearWrapper {
    height: 2.5rem !important;
    padding-top: 0 !important;

}



.rdrNextPrevButton {
    order: 1;
    background: transparent !important;
    color: $primaryColor;
    width: 18px !important;
    height: 18px !important;
    margin-right: 0px !important;
    margin-left: 0 !important;
}

.rdrMonthAndYearPickers {
    padding-left: 0rem;
    font-size: 1.125rem !important;
    color: $primaryColor;
    justify-content: start !important;

}

.rdrDayToday {
    .rdrDayNumber span {
        position: relative;
        z-index: 1;

        &:after {
            background: $secondaryColor !important;
            border-radius: 100% !important;
            width: 35px !important;
            height: 35px !important;
            color: $whiteColor;
            bottom: 0px !important;
            z-index: -1;
        }
    }

    .rdrStartEdge~.rdrDayNumber span::after {
        height: 0 !important;
    }
}

//calendar


.iGor_calendar {
    // .rdrMonth {
    //     width: 18.75rem;
    // }
    padding: 7px;

    .rdrMonthAndYearPickers {
        padding: 0;
    }

    .rdrPprevButton i {
        border-color: transparent #EE5253 transparent transparent;
    }

    .rdrNextButton i {
        border-color: transparent transparent transparent #F44336;
    }

    .rdrDay {
        background-color: transparent;
    }

    .rdrSelected {
        color: #EE5253 !important;
        top: 2px !important;
        height: 2.2rem;
        width: 2.2rem;
        border-radius: 100%;
        left: 6px !important;
    }

    // .rdrDay {
    //     border-radius: 100%;
    //     height: 2.5rem;
    //     width: 2.5rem;
    // }

    .rdrDayToday .rdrDayNumber span {
        color: #fff;
    }

    .rdrMonthPicker,
    .rdrYearPicker {
        margin: 0;

        select {
            padding-left: 0.9375rem;
            text-align: left;
            font-size: 1rem;
            font-weight: 600;
            color: #FF6B6B;

            option {
                color: #000000;
                padding: 0.3125rem;

            }
        }
    }

    .rdrYearPicker {
        select {
            padding-right: 1.4375rem;
        }
    }

    .rdrDayHovered {
        span {
            border-color: #EE5253;
        }
    }
}

//End datepiker


//Custom Tabs 
.iGor_CustomTabs_Offers {
    background-color: $whiteColor;
    border-radius: 3.125rem;
    margin-bottom: 0.5rem;
    color: #EE5253;
    display: flex;
    width: 360px;
    margin-top: 20px;
    align-items: center;

    .iGor_btnTab {
        text-transform: none;
        font-size: 0.675rem;
        color: $blackColor;
        border-radius: 3.125rem;
        margin: 5px;

        &:hover {
            background: linear-gradient(180deg, $primaryColor 0%, #EE5253 100%);
            color: #ffffff;
        }
    }

    .iGor_activeTab {
        background: linear-gradient(180deg, $primaryColor 0%, #EE5253 100%);
        color: $whiteColor;
    }

    .btncenter {
        margin: 0 0.5rem;
    }
}

.iGor_CustomTabs {
    background: $bgGrayColor;
    border-radius: 3.125rem;
    margin-top: 27px;

    .iGor_btnTab {
        font-size: 0.875rem;
        color: $blackColor;
        border-radius: 3.125rem;
        padding: 0.3125rem 0.9375rem;
        text-transform: none;

        &:hover {
            background: linear-gradient(180deg, $primaryColor 0%, #EE5253 100%);
            color: #ffffff !important;
        }
    }

    .iGor_activeTab {
        background: linear-gradient(180deg, $primaryColor 0%, #EE5253 100%);
        color: $whiteColor !important;
    }

    .btncenter {
        margin: 0 0.5rem;
    }
}

//End Custom Tabs 



// MuiAutocomplete-popper
.MuiAutocomplete-listbox {
    font-size: 0.875rem !important;

    .MuiAutocomplete-option {
        font-size: 0.875rem !important;
    }
}


.firstNameError {
    left: 35px !important;
}

.lastNameError {
    left: 35px !important;
}

.phoneError {
    left: 40px !important;
}

// iGor-ErrorMsg
.iGor-ErrorMsg {
    font-size: 0.625rem;
    color: #ff1807;
    position: absolute;
    z-index: 1;
    bottom: 7px;
    left: 25px;

}

.iGorCurrError {
    bottom: -15px;
}

.iGoRoom_login_pop {
    .iGor-ErrorMsg {
        position: absolute;
        bottom: -20px;
        left: 50px;
    }

    .InputField {
        height: 40px !important;
    }

    .iGor_mailIcon {
        color: #EE5253;
    }
}


.iGor_TextFieldGrey {
    background: #F1F5F9;
    border-radius: 50px;
    padding: 10px 20px;

    fieldset {
        border: none;
    }
}

.iGor_codeField {
    background: #ffffff;
    border-radius: 50px;
    padding: 4px 0 4px 20px;
    height: 45px;

    .iGorTitle {
        font-size: 8px;
        color: #C4C4C4;
    }

    .iGorCodeName {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 13px;
        color: #C4C4C4;
    }

    .iGorCodeNameSelected {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 12px;
        color: #000;
    }
}

.iGoR_autoComSection_noCuntry {
    width: 190px;
    height: 35px;

    .iGor_Text_noCuntry {
        font-size: 0.7rem;
        font-weight: 500;
        height: 35px;
        @include flexbox(none, center, center);
    }
}

.iGoR_autoComSection {
    height: 245px;
    width: 235px;
    padding: 11px;
    padding-bottom: 0;

    .MuiSvgIcon-root {
        display: none;
    }

    .MuiInputBase-root {
        background: #F1F5F9;
        border-radius: 50px;

    }

    .MuiAutocomplete-listbox {
        height: 179px;
    }

    .MuiAutocomplete-popper {
        max-height: 200px !important;
        width: 99% !important;
        transform: translate3d(0, 65px, 0px) !important;
        border-radius: 0 !important;
    }
}

.iGor_inputFieldval {
    font-size: 14px;
    font-weight: 400 !important;
    color: #000000 !important;
}

.iGor-date_reset {
    svg {
        color: #FF6B6B;
    }
}

.iGor_forgetPass {
    .inputIcons {
        font-size: 17px;
    }

    .MuiButton-root,
    .InputField {
        max-width: 474px;

        p {
            position: absolute;
            bottom: -16px;
            left: -11px;
        }
    }


    .MuiTypography-caption {
        margin-bottom: 15px;
    }

}


.iGor__inbox {
    height: 300px;
    width: 300px;
    background: #F1F5F9;
    padding: 15px;

    .iGor__InboxTabs {
        background: #fff;
        display: flex;
        justify-content: space-around;
        padding: 5px;
        border-radius: 50px;
        margin: 10px 0;
        height: 40px;
    }

    .iGor__tabBox {
        background: #ffffff;
        padding: 15px;
        border-radius: 15px;
        cursor: pointer;
    }

    .iGor-messageUnread {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        svg {
            color: #EE5253;
            font-size: 0.5rem;
        }
    }

    .iGor__inboxBtn {
        font-size: 10px;
        font-weight: 600;
        cursor: pointer;
        padding: 5px;
        width: 100%;
        border-radius: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #C4C4C4;
        font-size: 10px;
        background: transparent;
    }

    .iGor__inboxActive {
        background: #EE5253 !important;
        color: #ffffff;

    }

    .iGor__inboxTitle {
        display: flex;
        cursor: pointer;

        svg {
            margin-right: 1rem;
        }
    }

    // .iGor-inbox_newsrooms {
    //     img {
    //         width: 100%;
    //         margin: 10px 0px;
    //         border: 0;
    //     }

    //     p {
    //         text-align : 'justify-content';
    //         font-size: 0.7rem;
    //     }
    // }

    .iGor_updatedCard {
        cursor: pointer;

        .iGor__updatedImgBox {
            background: #C4C4C4;
            border-radius: 15px;
            height: 125px;
            width: 100%;
            margin: 8px 0;

            .iGor__updatedImg {
                width: 100%;
                height: 100%;
                border-radius: 15px;
                object-fit: cover;
            }
        }
    }
}



.iGoR_autoComBox {

    .MuiAutocomplete-listbox {
        max-height: 145px !important;
    }
}

.iGor-action_loader {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        color: #ffffff;
        font-size: 1.3rem;
    }
}


@media screen and (max-width:1024px) {
    .iGor__RegisterAccount {
        max-width: 600px;
        margin: 0 auto;

        .phoneError {
            left: 30px !important;
            width: 100%;
        }
    }

    .iGor__ChildBox {
        justify-content: space-between;
        width: 100%;

        .iGoRoom_CountInput {
            margin-left: 0px !important;

            .iGor__childAge {
                background-color: #ffffff !important;
            }
        }
    }

    .iGor__updatedImgBox {
        height: 150px !important;
    }

    .iGr__RegAccount {
        .iGor__RegField {
            top: 4px;
        }

    }

    .iGor__inboxTitle {
        justify-content: center;
    }

    .iGor__InboxTabs {
        margin: 5px 0 25px 0px !important;
    }

    .iGor__inbox {
        width: 100%;
        height: 100% !important;
        max-width: 600px;
        margin: 0 auto;

    }

    .iGor__RegisterAccount {
        .iGor_passIcon {
            width: 15px;
        }

        .InputField {
            padding-left: 22px;
            height: 45px;

            .MuiFormLabel-root {
                font-size: 12px;
                color: #a2a2a2;
                -webkit-transform: translate(0px, 10px) scale(0.75) !important;
                -moz-transform: translate(0px, 10px) scale(0.75) !important;
                -ms-transform: translate(0px, 10px) scale(0.75) !important;
                transform: translate(0px, 10px) scale(0.75) !important;

            }

            [data-shrink="true"] {
                color: #a2a2a2 !important;
                -webkit-transform: translate(0px, -3px) scale(0.75) !important;
                -moz-transform: translate(0px, -3px) scale(0.75) !important;
                -ms-transform: translate(0px, -3px) scale(0.75) !important;
                transform: translate(0px, 3px) scale(0.75) !important;
            }


        }
    }

    .rdrDayDisabled {
        background: #F1F5F9 !important;
    }

    .iGor_calendar {
        width: 93vw;

        .rdrSelected {
            left: 12px !important;
        }

        .rdrMonth {
            width: 100%;
        }
    }

    #DatePiker-popover {
        .rdrSelected {
            left: 10px !important;
        }

        .rdrMonthAndYearWrapper {
            padding-right: 12px;
        }
    }

    .iGor_codeField {
        padding: 2px 0 2px 20px;
        height: 45px;

        .iGorCodeName {
            font-size: 11px;
        }
    }
}

@media screen and (max-width:767px) {
    #LoginAccount-popover {
        .MuiPaper-root {
            overflow: hidden;
        }
    }

    .iGor__inbox {
        .iGor__tabBox {
            background: transparent;
        }
    }

    .phoneError {
        left: 40px !important;
        white-space: nowrap;
    }

    .iGor-ErrorMsg {
        left: 24px !important;
        bottom: 10px;
    }

    .iGor_passwordInput>.iGor-ErrorMsg {
        bottom: 2px !important;
    }

    .iGoRoom_login_pop {
        .iGor-ErrorMsg {
            bottom: -14px;
            left: 48px !important;
        }

        .iGor_loginPass {
            .iGor-ErrorMsg {
                left: 48px !important;
            }
        }
    }

    .iGor__inboxTitle {
        justify-content: start;
        margin: 0 !important;
    }
}